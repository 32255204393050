export const RoleRanking = (role: string): number => {
    switch (role) {
        case "ceo":
            return 1;
        case "department_head":
            return 2;
        case "sub_department_head":
            return 3;
        case "division_head":
            return 4;
        case "unit_head":
            return 5;
        case "normal":
            return 6;
        default:
            return 7;
    }
}


export const DepartmentRanking = (department: number): number => {
    switch (department) {
        case 6:
            return 1;
        case 1:
            return 2;
        case 2:
            return 3;
        case 3:
            return 4;
        case 4:
            return 5;
        case 5:
            return 6;
        case 7:
            return 7;
        case 8:
            return 8;
        default:
            return 9;
    }
}

