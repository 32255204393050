import React, {useEffect} from 'react';
import {generateReport2Pdf, generateReportPdf, getReportByEmployeeID, ReportData} from "../../services/reportService";
import {useAuth} from "../../context/authContext";
import {useNavigate} from "react-router-dom";
import {Header, TableFooter} from "../../services/workPlanServiice";
import {reportPdfGenFormat, reportTaskReStructure} from "../../utils/reportTaskReStructure";
import {RiArrowGoBackLine} from "react-icons/ri";
import PdfRender from "../comps/pdfRender";
import {PDF_HOST} from "../../utils/api";
import {IoPersonCircle} from "react-icons/io5";
import {BsFileEarmarkPdf} from "react-icons/bs";
import {scoreFilter} from "../../utils/scoreUtil";
import {FcApproval, FcCancel} from "react-icons/fc";
import {PiDotsThree} from "react-icons/pi";
import {FaEye} from "react-icons/fa";
import {ButtonType} from "../../context/button/buttonTypes";
import {useReportEdit} from "../../utils/reportEditContext";
import {useButtonTypeContext} from "../../context/button/buttonContext";

const SubDepartmentReport = () => {
    const {setReportI} = useReportEdit();
    const {setButtonType} = useButtonTypeContext()
    const [reports, setReports] = React.useState<ReportData[]>([]);
    const [selectedReport, setSelectedReport] = React.useState<ReportData|undefined>(undefined);
    const [displayPdf, setDisplayPdf] = React.useState(false);
    const [displayPdfEvidence, setDisplayPdfEvidence] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);

    const {employeeI} = useAuth();

    const navigate = useNavigate();


    useEffect(() => {
        if (!employeeI) navigate("/login")
        fetchData().then();
    },[refresh]);


    const handleChangeReport = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        const report = reports.find((report) => report.id === value);
        if (!report) return;
        setSelectedReport(report);
    }

    const handleGenerateReport =async (e: React.MouseEvent<HTMLDivElement>) => {
        if (!selectedReport) return;
        const header: Header = {
            owner: `${selectedReport.Employee.fname.toUpperCase()} ${selectedReport.Employee.mname.Valid ? selectedReport.Employee.mname.String.toUpperCase() : ""} ${selectedReport.Employee.lname.toUpperCase()}`,
            department: selectedReport.Department.name.toUpperCase(),
            for: `${selectedReport.month.toUpperCase()} ${selectedReport.year}`.trim()
        }

        const data = reportPdfGenFormat(selectedReport.WorkPlan.Tasks)

        if (selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid) {
            const footer: TableFooter = {
                prepared: {
                    name: `${selectedReport.Employee.fname.toUpperCase()} ${selectedReport.Employee.mname.Valid ? selectedReport.Employee.mname.String.toUpperCase() : ""} ${selectedReport.Employee.lname.toUpperCase()}`,
                    position: selectedReport.Employee.position.toUpperCase(),
                    signature: selectedReport.Employee.signature,
                    date: `${new Date(selectedReport.created_at).toLocaleDateString("en-GB")}`
                },
                reviewed: {
                    name: `${selectedReport.UnitHead.fname.toUpperCase()} ${selectedReport.UnitHead.mname.Valid ? selectedReport.UnitHead.mname.String.toUpperCase() : ""} ${selectedReport.UnitHead.lname.toUpperCase()}`,
                    position: selectedReport.UnitHead.position.toUpperCase(),
                    signature: selectedReport.UnitHead.signature,
                    date: `${new Date(selectedReport.unit_head_approval_at.Time).toLocaleDateString("en-GB")}`
                },
                approved: {
                    name: `${selectedReport.Manager.fname.toUpperCase()} ${selectedReport.Manager.mname.Valid ? selectedReport.Manager.mname.String.toUpperCase() : ""} ${selectedReport.Manager.lname.toUpperCase()}`,
                    position: selectedReport.Manager.position.toUpperCase(),
                    signature: selectedReport.Manager.signature,
                    date: `${new Date(selectedReport.manager_approval_at.Time).toLocaleDateString("en-GB")}`
                }
            }

            const res = await generateReportPdf(selectedReport.id, {
                header: header,
                data: data,
                footer: footer,
            });
            // TODO: handle faults
            if (!res) return;
            const re = res.data
            console.log(re);
            setRefresh(!refresh);

        } else {
            const footer: TableFooter = {
                prepared: {
                    name: `${selectedReport.Employee.fname.toUpperCase()} ${selectedReport.Employee.mname.Valid ? selectedReport.Employee.mname.String.toUpperCase() : ""} ${selectedReport.Employee.lname.toUpperCase()}`,
                    position: selectedReport.Employee.position.toUpperCase(),
                    signature: selectedReport.Employee.signature,
                    date: `${new Date(selectedReport.created_at).toLocaleDateString("en-GB")}`
                },
                approved: {
                    name: `${selectedReport.UnitHead.fname.toUpperCase()} ${selectedReport.UnitHead.mname.Valid ? selectedReport.UnitHead.mname.String.toUpperCase() : ""} ${selectedReport.UnitHead.lname.toUpperCase()}`,
                    position: selectedReport.UnitHead.position.toUpperCase(),
                    signature: selectedReport.UnitHead.signature,
                    date: `${new Date(selectedReport.unit_head_approval_at.Time).toLocaleDateString("en-GB")}`
                },
            }

            const res = await generateReport2Pdf(selectedReport.id, {
                header: header,
                data: data,
                footer: footer,
            });
            console.log(selectedReport)
            // TODO: handle faults
            if (!res) return;
            const re = res.data
            console.log(re);
            setRefresh(!refresh);
        }


    }


    const fetchData = async () => {
        if (!employeeI) {
            navigate("/login");
            return;
        }
        await getReportByEmployeeID(employeeI.user_id).then(res => {
            if (res) {
                setReports(
                    res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                );
                setSelectedReport(
                    res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0]
                );
            }
        });
    }

    // console.log(selectedReport)
    return (
        <div className={`w-full h-full flex flex-col gap-4 px-4 pt-20 pb-32`}>
            {
                (displayPdf || displayPdfEvidence) && (
                    <div onClick={() => {
                        setDisplayPdf(false)
                        setDisplayPdfEvidence(false)
                    }} className={`absolute w-full h-full bg-black/40 z-20`}></div>
                )
            }
            {
                displayPdf && (
                    <div className={`absolute w-full h-full z-20`}>
                        <div className={`w-full h-full relative`}>
                            <div
                                onClick={() => setDisplayPdf(false)}
                                className={`absolute w-fit h-fit p-2 bg-black/40 left-5 top-16 z-20 rounded-full hover:-translate-x-0.5 cursor-pointer`}>
                                <RiArrowGoBackLine className="w-5 h-5  text-white" />
                            </div>
                            {selectedReport && <PdfRender pdf={PDF_HOST + selectedReport.file} />}

                        </div>
                        {/*Hello*/}
                        {/*{PDF_HOST + selectedWorkPlan?.file}*/}
                    </div>
                )
            }
            {
                displayPdfEvidence && (
                    <div className={`absolute w-full h-full z-20`}>
                        <div className={`w-full h-full relative`}>
                            <div
                                onClick={() => setDisplayPdfEvidence(false)}
                                className={`absolute w-fit h-fit p-2 bg-black/40 left-5 top-16 z-20 rounded-full hover:-translate-x-0.5 cursor-pointer`}>
                                <RiArrowGoBackLine className="w-5 h-5  text-white" />
                            </div>
                            {selectedReport && <PdfRender pdf={PDF_HOST + selectedReport.evidence_file} />}

                        </div>
                        {/*Hello*/}
                        {/*{PDF_HOST + selectedWorkPlan?.file}*/}
                    </div>
                )
            }
            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <div className={`w-fit h-fit flex flex-row gap-4 items-center`}>
                    {
                        !employeeI?.profile_photo || employeeI?.profile_photo?.length <= 0 ? (
                            <>
                                <IoPersonCircle className={`text-stone-400 w-32 h-32`}/>
                            </>
                        ) : (
                            <img className={`w-32 h-32 rounded-full`} src={employeeI?.profile_photo}
                                 alt={`${employeeI.fname[0]}${employeeI.mname && employeeI.mname?.length > 0 ? `${employeeI.mname[0]}${employeeI.lname[0]}` : employeeI.lname[0]}`}/>
                        )
                    }
                    <div className={`w-fit h-fit flex flex-col gap-2 text-lg text-[#1c1c1c] font-medium`}>
                        <p>{`${employeeI?.fname} ${employeeI?.mname} ${employeeI?.lname}`}</p>
                        <p>{employeeI?.position}</p>
                        <p>{employeeI?.tel}</p>
                        {
                            (employeeI !== undefined && employeeI?.ip_phone.length) > 0 && (

                                <p>IP-PHONE: {employeeI?.ip_phone}</p>
                            )
                        }
                    </div>
                </div>
                <div className={`w-fit h-fit flex flex-col gap-4 items-center justify-center`}>
                    {
                        reports?.length > 0 && (
                            <select
                                onChange={handleChangeReport}
                                className={`w-fit px-2 py-1.5 outline-0 focus:outline-0 bg-transparent border-0 text-sm xl:text-lg font-semibold text-[#1c1c1c]`}>
                                {
                                    reports.map((report, i) => (
                                        <option key={i}
                                                value={report.id}>{`${report.month.slice(0, 3)} - ${report.year} Report`}</option>
                                    ))
                                }
                            </select>
                        )
                    }

                </div>
            </div>

            {/*    Reports   */}
            {
                selectedReport && (
                    <>
                        <div className={`w-full h-fit flex flex-row justify-between items-center`}>
                            <div
                                className={`w-fit text-sm md:text-base lg:text-lg xl:text-xl flex flex-row gap-2 lg:gap-4 2xl:text-2xl text-gray-500 font-semibold relative`}>
                                {`Report: ${selectedReport.report_number}`}
                                {
                                    selectedReport.Employee.id === employeeI?.user_id && (

                                        <div
                                            onClick={() => {
                                                setButtonType(ButtonType.EditReport);
                                                setReportI(selectedReport);
                                            }}
                                            className={`absolute z-10 w-fit h-fit right-0 top-6 cursor-pointer`}>
                                            <p className={`text-xs text-orange-400 font-semibold`}>Edit</p>
                                        </div>
                                    )
                                }
                                {
                                    (
                                        selectedReport.evidence_file.length > 0
                                    )
                                        ?
                                        (
                                            <div
                                                onClick={() => setDisplayPdfEvidence(true)}
                                                className={`w-fit h-fit rounded-full bg-transparent cursor-pointer text-lg font-semibold text-orange-500 hover:text-white hover:bg-orange-500`}>
                                                <FaEye className={`w-6 h-6 `}/>
                                            </div>

                                        )
                                        : (
                                            <div></div>
                                        )
                                }


                            </div>
                            {
                                (((selectedReport.Employee.s_supervisor_id.Valid && selectedReport.manager_approval === "approved") && (selectedReport.Employee.supervisor_l_id.Valid && selectedReport.unit_head_approval === "approved")) || ((selectedReport.Employee.supervisor_l_id.Valid && selectedReport.unit_head_approval === "approved") && !selectedReport.Employee.s_supervisor_id.Valid)) && (selectedReport.file.length <= 0)

                                &&
                                (
                                    <div
                                        onClick={(e) => handleGenerateReport(e)}
                                        className={`w-fit h-fit py-1.5 px-3 bg-orange-500 rounded-md cursor-pointer text-lg font-semibold text-white shadow-sm hover:shadow hover:bg-orange-600 shadow-stone-500/60`}>
                                        {/*<p className={`text-xs text-orange-400 font-semibold`}>Edit</p>*/}
                                        {/*<TbFileIsr className={` hover:text-emerald-400 text-gray-400 w-5`}/>*/}
                                        <p>Generate Scored Report</p>
                                    </div>

                                )

                            }
                            {
                                (((selectedReport.Employee.s_supervisor_id.Valid && selectedReport.manager_approval === "approved") && (selectedReport.Employee.supervisor_l_id.Valid && selectedReport.unit_head_approval === "approved")) || ((selectedReport.Employee.supervisor_l_id.Valid && selectedReport.unit_head_approval === "approved") && !selectedReport.Employee.s_supervisor_id.Valid)) && (selectedReport.file.length > 0)
                                &&
                                (
                                    <div
                                        onClick={() => setDisplayPdf(true)}
                                        className={`w-fit h-fit cursor-pointer`}>
                                        <BsFileEarmarkPdf className={`hover:text-orange-400 text-emerald-400 w-8 h-10`}/>
                                    </div>

                                )

                            }
                        </div>

                        {
                            reportTaskReStructure(selectedReport.WorkPlan.Tasks).map((obj, index) => (
                                <div key={index} className={`w-full h-fit flex flex-col gap-4  mt-4`}>
                                    <div className={`w-full h-fit flex flex-row justify-between items-center`}>
                                        <p className={` text-2xl font-semibold text-[#1c1c1c]`}>{obj.objectiveName}</p>
                                    </div>
                                    <div className={`w-full h-fit flex flex-col justify-between gap-4`}>
                                        <div
                                            className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2 overflow-x-scroll scrollbar-thumb-rounded-full scrollbar-thin touch-auto scrollbar-thumb-stone-200 scrollbar-track-transparent scroll-pr-4`}>
                                            <div
                                                className={`w-[2300px] grid grid-cols-12 text-lg xl:text-xl 2xl:text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle gap-x-4`}>
                                                <div className={`w-full h-fit col-span-2`}>Activity</div>
                                                <div className={`w-full h-fit col-span-2`}>Target</div>
                                                <div className={`w-full h-fit col-span-2`}>Status</div>
                                                <div
                                                    className={`w-full h-fit col-span-2 text-center flex flex-col gap-y-1`}>
                                                    <p className={`w-full text-center`}>Completion</p>
                                                    <div className={`w-full text-sm grid grid-cols-2 gap-x-1`}>
                                                        <div className={`col-span-1`}>Actual (%)</div>
                                                        <div className={`col-span-1`}>Cumulative (%)</div>
                                                    </div>
                                                </div>
                                                <div className={`w-full h-fit col-span-1 text-center`}>Remarks
                                                </div>
                                                <div className={`w-full h-fit col-span-2 text-center`}>Supervisor's
                                                    Comments/Remarks
                                                </div>
                                                <div className={`w-full h-fit col-span-1 text-center`}>Score
                                                </div>
                                            </div>
                                            <div className={`w-[2300px] bg-[#999999] py-[1px]`}></div>
                                            {
                                                obj.activities.length >= 0 && (
                                                    obj.activities.map((activity, i) => (
                                                        <div key={i}
                                                             className={`w-[2300px] h-fit flex flex-col gap-4 justify-center relative`}>
                                                            <div
                                                                className={`w-full text-xs lg:text-sm 2xl:text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid grid-cols-12 gap-x-6`}>
                                                                <div
                                                                    className={`w-full col-span-2 h-fit flex flex-row gap-1 items-center`}>
                                                                    <p>{activity.activityName}</p>
                                                                </div>
                                                                {
                                                                    activity.tasks.length <= 0 ?
                                                                        (
                                                                            <div
                                                                                className={`w-full col-span-10 h-fit flex flex-row items-center justify-center`}>
                                                                                <div className={`w-fit h-fit`}>
                                                                                    <p className={`w-fit h-fit rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-gray-500`}>No
                                                                                        targets allocated for this
                                                                                        Activity.</p>
                                                                                </div>
                                                                            </div>
                                                                        ) :
                                                                        (
                                                                            <div
                                                                                className={`w-full col-span-10 h-fit flex flex-col gap-4`}>
                                                                                {
                                                                                    activity.tasks.map((task, i) => (

                                                                                        <div key={i}
                                                                                             className={`w-full h-fit justify-items-start text-wrap align-middle grid grid-cols-10 gap-x-6`}>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-row gap-1 items-center`}>
                                                                                                {task.taskName}
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                <p className={``}>{task.status}</p>
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                <div
                                                                                                    className={`w-full h-fit text-sm grid grid-cols-2 gap-x-1`}>
                                                                                                    <div
                                                                                                        className={`w-full col-span-1 text-center`}>
                                                                                                        <input
                                                                                                            required={true}
                                                                                                            max={100}
                                                                                                            disabled={true}
                                                                                                            type="text"
                                                                                                            value={`${task.achieved}`}
                                                                                                            className={`w-full py-1.5 px-2 text-center bg-stone-50 outline-0 focus:outline-none focus:ring-0`}/>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={`w-full col-span-1`}>
                                                                                                        <input
                                                                                                            disabled={true}
                                                                                                            required={true}
                                                                                                            max={100}
                                                                                                            type={"text"}
                                                                                                            value={`${task.cumulative}`}
                                                                                                            className={`w-full text-center py-1.5 px-2 bg-stone-50 outline-0 focus:outline-none focus:ring-0`}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-1 flex flex-col gap-2`}>
                                                                                                <p className={`w-full text-center`}>{task.remarks}</p>
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                <p className={`w-full text-center`}>{task.taskScore.comment}</p>
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-1 flex flex-col items-center justify-center gap-2`}>
                                                                                                <span
                                                                                                    className={`min-w-32 w-fit text-center rounded-full min-h-8 h-fit py-1.5 px-4 ${scoreFilter(task.taskScore.score)} font-semibold`}>
                                                                                                                {task.taskScore.id <= 0 && task.taskScore.status.length <= 0 ? "" : task.taskScore.score}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        )
                                                                }
                                                            </div>

                                                            <div
                                                                className={`w-full flex flex-row items-center`}>
                                                                <div
                                                                    className={`w-[2300px] h-0.5 bg-[#999999] py-[1px]`}></div>
                                                            </div>


                                                        </div>
                                                    ))
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                        <div className={`w-full h-fit flex flex-col justify-between gap-4 mt-16`}>
                            <div
                                className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>

                                <div
                                    className={`text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle grid ${selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>
                                    <div className={`w-full h-fit col-span-1 text-center`}>Prepared By</div>


                                    {
                                        selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid ? (
                                            <>
                                                <div className={`w-full h-fit col-span-1 text-center relative`}>
                                                    {
                                                        selectedReport.unit_head_approval === "rejected" ? (
                                                            <div className={`absolute bottom-3 right-20`}>
                                                                <FcCancel className={`w-5 h-5`}/>
                                                            </div>
                                                        ) : selectedReport.unit_head_approval === "approved" ? (
                                                            <div className={`absolute bottom-3 right-20`}>
                                                                <FcApproval className={`w-5 h-5`}/>
                                                            </div>
                                                        ) : (
                                                            <div className={`absolute bottom-3 right-20`}>
                                                                <PiDotsThree
                                                                    className={`w-5 h-5 text-gray-500`}/>
                                                            </div>
                                                        )
                                                    }

                                                    Checked By
                                                </div>
                                                <div className={`w-full h-fit col-span-1 text-center relative`}>
                                                    {
                                                        selectedReport.manager_approval === "rejected" ? (
                                                            <div className={`absolute bottom-3 right-16`}>
                                                                <FcCancel className={`w-5 h-5`}/>
                                                            </div>
                                                        ) : selectedReport.manager_approval === "approved" ? (
                                                            <div className={`absolute bottom-3 right-16`}>
                                                                <FcApproval className={`w-5 h-5`}/>
                                                            </div>
                                                        ) : (
                                                            <div className={`absolute bottom-3 right-16`}>
                                                                <PiDotsThree
                                                                    className={`w-5 h-5 text-gray-500`}/>
                                                            </div>
                                                        )
                                                    }Approved By
                                                </div>
                                            </>
                                        ) : (

                                            <>
                                                <div className={`w-full h-fit col-span-1 text-center relative`}>
                                                    {
                                                        selectedReport.unit_head_approval === "rejected" ? (
                                                            <div className={`absolute bottom-3 right-20`}>
                                                                <FcCancel className={`w-5 h-5`}/>
                                                            </div>
                                                        ) : selectedReport.unit_head_approval === "approved" ? (
                                                            <div className={`absolute bottom-3 right-20`}>
                                                                <FcApproval className={`w-5 h-5`}/>
                                                            </div>
                                                        ) : (
                                                            <div className={`absolute bottom-3 right-20`}>
                                                                <PiDotsThree
                                                                    className={`w-5 h-5 text-gray-500`}/>
                                                            </div>
                                                        )
                                                    }

                                                    Checked & Approved By
                                                </div>
                                            </>
                                        )
                                    }


                                </div>

                                <div className={`w-full bg-[#999999] py-[1px]`}></div>
                                <div
                                    className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid ${selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>

                                    <div
                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                        <p className={`w-full text-center`}>{`${selectedReport.Employee.fname} ${selectedReport.Employee.mname.String} ${selectedReport.Employee.lname}`}</p>
                                        <p className={`w-full text-center`}>{`${selectedReport.Employee.position}`}</p>
                                    </div>

                                    {
                                        selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid ? (
                                            <>
                                                <div
                                                    className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                    <p className={`w-full text-center`}>{`${selectedReport.UnitHead.fname} ${selectedReport.UnitHead.mname.String} ${selectedReport.UnitHead.lname}`}</p>
                                                    <p className={`w-full text-center`}>{`${selectedReport.UnitHead.position}`}</p>
                                                </div>
                                                <div
                                                    className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                    <p className={`w-full text-center`}>{`${selectedReport.Manager.fname} ${selectedReport.Manager.mname.String} ${selectedReport.Manager.lname}`}</p>
                                                    <p className={`w-full text-center`}>{`${selectedReport.Manager.position}`}</p>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                    <p className={`w-full text-center`}>{`${selectedReport.UnitHead.fname} ${selectedReport.UnitHead.mname.String} ${selectedReport.UnitHead.lname}`}</p>
                                                    <p className={`w-full text-center`}>{`${selectedReport.UnitHead.position}`}</p>
                                                </div>
                                            </>
                                        )
                                    }

                                </div>

                                <div
                                    className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid ${selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>
                                    <div
                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                        <p className={`w-full text-center`}>{new Date(selectedReport.created_at).toLocaleDateString("en-GB")}</p>
                                    </div>

                                    {
                                        selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid ? (
                                            <>
                                                <div
                                                    className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                    {
                                                        selectedReport.unit_head_approval_at.Valid && (

                                                            <p className={`w-full text-center`}>{new Date(selectedReport.unit_head_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                        )
                                                    }
                                                </div>
                                                <div
                                                    className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                    {
                                                        selectedReport.manager_approval_at.Valid && (
                                                            <p className={`w-full text-center`}>{new Date(selectedReport.manager_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                        )}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                    {
                                                        selectedReport.unit_head_approval_at.Valid && (

                                                            <p className={`w-full text-center`}>{new Date(selectedReport.unit_head_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                        )
                                                    }
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default SubDepartmentReport;