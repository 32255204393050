import React, {ReactElement, useEffect, useState} from 'react';
import {useAuth} from "../context/authContext";
import {Link, useNavigate} from "react-router-dom";
import SideBar from "../components/sideBar";
import {TabType} from "../utils/mainTab/tabType";
import { TabContext } from "../context/tabSwitch/tabContext";
import { TagContext } from "../context/tagContext";
import Main from "../components/main";
import {ButtonType} from "../context/button/buttonTypes";
import {ButtonContext} from "../context/button/buttonContext";
import TabEmployeeProvider from "../context/tabEmployeeContext";
import WorkPlanToReportGenProvider from "../context/workPlanToReport/workPlanToReportGenContext";
import {MdCloudDone} from "react-icons/md";
import {uploadSignature} from "../services/employee";
import {BsChevronDoubleLeft, BsChevronDoubleRight} from "react-icons/bs";
import ReportEditProvider from "../utils/reportEditContext";

export type Links = {
    title: string;
    link: string;
    icon: ReactElement
}


const Dashboard = () => {
    const {employeeI, addSignature} = useAuth()
    const navigate = useNavigate();

    const [tab, setTab] = useState<TabType>(TabType.SideDashboard);
    const [tag, setTag] = useState<TabType>(TabType.SideDashboard);
    const [file, setFile] = useState<File | null>(null);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [sideBarToggle, setSideBarToggle] = useState<boolean>(false);
    useEffect(() => {
        if (!employeeI) navigate("/login", { replace: true });
    })

    const [buttonType, setButtonType] = useState<ButtonType | undefined>(
        undefined,
    );
    const [kind, setKind] = useState<string>("")

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!employeeI) return;
        if (loading) return
        setLoading(true);
        setError("")
        const files = event.target.files;
        if (!files) {
            setFile(null);
            return;
        }
        if (files[0].type !== "image/jpeg" && files[0].type !== "image/png" && files[0].type !== "image/jpg") {
            setFile(null);
            setLoading(false);
            setError("Use an Image file of type jpeg or png or jpg instead")
            return;
        }
        const formData = new FormData();
        const now = new Date();
        formData.append("image", files[0], `${employeeI.fname.trim().toLowerCase()}${employeeI.lname.length <= 0 ? "": `_${employeeI.lname}`}_${now.getTime()}.${files[0].name.trim().split(".")[files[0].name.trim().split(".").length - 1]}`);
        const signature = await uploadSignature(formData, employeeI.user_id);
        if (!signature) {
            setError("Something wrong happened when uploading signature.");
            setLoading(false);
            return;
        }
        addSignature(signature)
        // console.log(files[0].name);
        setFile(files[0]);

    };

    return (
        <TabEmployeeProvider>
            <WorkPlanToReportGenProvider>
                <ReportEditProvider>

                    <TabContext.Provider value={{ tab, setTab }}>
                        <TagContext.Provider value={{ tag, setTag }}>
                            <ButtonContext.Provider
                                value={{
                                    kind: kind,
                                    tag: tag,
                                    buttonType,
                                    setButtonType,
                                    setKind
                                }}
                            >
                                <div className={`w-full h-full flex flex-row bg-stone-50 relative`}>
                                    { (!employeeI?.signature || employeeI.signature.length <= 0) && (
                                            <div className={`absolute w-full h-full bg-black/80 z-30`}>
                                            </div>

                                        )
                                    }

                                    {
                                        sideBarToggle ? (
                                            <div
                                                onClick={() => setSideBarToggle(false)}
                                                className={`absolute fixed top-5 left-5 xl:top-10 xl:left-10 w-fit z-20 h-fit text-gray-500 hover:text-orange-500 hover:font-bold ease-in-out hover:-translate-x-0.5 cursor-pointer`}>
                                                <BsChevronDoubleLeft className={`w-5 h-5 xl:w-8 xl:h-8`}/>
                                            </div>
                                        ) : (
                                            <div
                                                onClick={() => setSideBarToggle(true)}
                                                className={`absolute top-5 left-5 xl:top-10 xl:left-10 w-fit z-20 h-fit text-gray-500 hover:text-orange-500 hover:font-bold ease-in-out hover:translate-x-0.5 cursor-pointer`}>
                                                <BsChevronDoubleRight className={`w-5 h-5 xl:w-8 xl:h-8`}/>
                                            </div>
                                        )
                                    }

                                    {
                                        (!employeeI?.signature || employeeI.signature.length <= 0) && (

                                            <div className={`absolute w-full h-full z-30 pt-44 xl:pt-60`}>
                                                <div className="extraOutline p-4 bg-white w-max bg-whtie m-auto rounded-lg">
                                                    <div
                                                        className="file_upload p-5 relative border-4 border-dotted border-gray-300 rounded-lg"
                                                        style={{width: '450px'}}>
                                                        {
                                                            loading ?(
                                                                <>
                                                                    <svg className="animate-spin h-5 w-5 mr-3 ..."
                                                                         viewBox="0 0 24 24">
                                                                    </svg>
                                                                </>
                                                            ) : (

                                                                <>
                                                                    {
                                                                        file ? (
                                                                            <div
                                                                                className="w-full flex flex-row justify-center items-center">

                                                                                <MdCloudDone
                                                                                    className="w-44 h-32 text-emerald-500 drop-shadow-md shadow-emerald-500/80"/>
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                <svg className="text-orange-500 w-24 mx-auto mb-4"
                                                                                     xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                                     viewBox="0 0 24 24"
                                                                                     stroke="currentColor">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                                          strokeWidth="2"
                                                                                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"/>
                                                                                </svg>
                                                                                <div
                                                                                    className="input_field flex flex-col w-max mx-auto text-center">
                                                                                    <label>
                                                                                        <input onChange={handleFileChange}
                                                                                               className="text-sm cursor-pointer w-36 hidden"
                                                                                               type="file"
                                                                                        />
                                                                                        <div
                                                                                            className="text bg-orange-600 text-white border border-gray-300 rounded font-semibold cursor-pointer p-1 px-3 hover:bg-orange-500">Select
                                                                                        </div>
                                                                                    </label>

                                                                                    <div className="title text-orange-500 uppercase">your
                                                                                        Signature
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }


                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className={`${sideBarToggle ? "w-1/3": "w-0 "} h-full`}>
                                        <SideBar/>
                                    </div>
                                    <div className={`${sideBarToggle ? "w-2/3": "w-full "} h-full px-4`}>
                                        <Main/>
                                    </div>
                                </div>
                            </ButtonContext.Provider>
                        </TagContext.Provider>
                    </TabContext.Provider>
                </ReportEditProvider>
            </WorkPlanToReportGenProvider>
        </TabEmployeeProvider>
    );
};

export default Dashboard;