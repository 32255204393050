import React, {useEffect, useState} from 'react';
import logo from "../img/logo.png";
import {useTabContext} from "../context/tabSwitch/tabContext";
import {TabType} from "../utils/mainTab/tabType";
import {useTagContext} from "../context/tagContext";
import {TfiDashboard} from "react-icons/tfi";
import {useAuth} from "../context/authContext";
import {ImTree} from "react-icons/im";
import {MdDashboardCustomize} from "react-icons/md";
import {
    Division,
    getAllDivisionsByDepartmentID,
    getAllDivisionsBySubDepartmentID,
    getDivisions, getDivison
} from "../services/divisionService";
import {useNavigate} from "react-router-dom";
import {Department, getDepartments} from "../services/departmentService";
import {
    Employee,
    EmployeeDB,
    getAllUsersBYDepartmentID,
    getAllUsersBYDivisionID, getAllUsersBYSubDepartmentID, getAllUsersBYUnitID,
    getEmployees, getEmployeesWithSupervisorID
} from "../services/employee";
import {useButtonTypeContext} from "../context/button/buttonContext";
import {useTabEmployee} from "../context/tabEmployeeContext";
import {getAllUnitsByDivisionID, getUnits, Unit} from "../services/unitService";
import {getSubDepartmentByDepartmentID, getSubDepartments, SubDepartment} from "../services/subDepartment";
import {DepartmentRanking, RoleRanking} from "../utils/sortHelper";
import {IoIosSearch} from "react-icons/io";
import { IoPersonCircle } from 'react-icons/io5';

/**
 * SideBar component for displaying navigation options and employee information.
 *
 * This component renders a sidebar with various sections such as Dashboard, Departments,
 * Sub Departments, Divisions, Units, and Employees. The visibility and content of these
 * sections depend on the role of the logged-in employee.
 *
 * @returns {JSX.Element} The rendered SideBar component
 */


const SideBar = () => {
    const [units, setUnits] = useState<Unit[]>([]);
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [divisionIA, setDivisionIA] = useState<Division | undefined>(undefined);
    const [departments, setDepartments] = useState<Department[]>([]);
    const [subDepartments, setSubDepartments] = useState<SubDepartment[]>([]);
    const [employees, setEmployees] = useState<EmployeeDB[]>([]);
    const [employeesI, setEmployeesI] = useState<EmployeeDB[]>([]);
    const [employeesII, setEmployeesII] = useState<EmployeeDB[]>([]);
    const [departmentEmployees, setDepartmentEmployees] = useState<EmployeeDB[]>([]);
    const [departmentEmployeesSearched, setDepartmentEmployeesSearched] = useState<EmployeeDB[]>([]);
    const {setTabEmployee} = useTabEmployee();
    // const {setTabEmployee} = useTabEmployee();
    const [loading, setLoading] = useState<boolean>(false);

    const [selectedTab, setSelectedTab] = useState<string>("");
    const [selectedInternalTab, setSelectedInternalTab] = useState<string>("");

    const { setTab, tab } = useTabContext();
    const {setKind, setButtonType} = useButtonTypeContext();
    const { setTag } = useTagContext();
    const {employeeI} = useAuth()
    const navigate = useNavigate();

    useEffect(() => {
        fetchAll().then()
    },[])


    /**
     * Fetches all necessary data based on the logged-in employee's role.
     *
     * This function retrieves divisions, departments, units, employees, and sub-departments
     * based on the employee's role and permissions.
     *
     * @returns {Promise<void>}
     */

    const fetchAll = async () => {
        if (!employeeI) {
            if (!employeeI) navigate("/login", { replace: true });
            return
        }
        // if (employeeI.role === "ceo") {
        //     await getDivisions().then(res => {
        //         if (res) {
        //             setDivisions(res);
        //         }
        //     });
        //     await getDepartments().then(res => {
        //         if (res) {
        //             setDepartments(res);
        //         }
        //     })
        // } else {
        //   await getAllDivisionsByDepartmentID(employeeI.department).then(res => {
        //       if (res) {
        //           setDivisions(res);
        //       }
        //   })
        // }

        await getDepartments().then(res => {
            if (res) {
                setDepartments(res);
            }
        })

        await getUnits().then(res => {
            if (res) {
                setUnits(res);
            }
        })

        if (employeeI.role === "ceo") {
            await getEmployeesWithSupervisorID(employeeI.user_id).then(res => {
                if (res) {
                    setEmployees(res.filter(r => r.role !== employeeI.role));
                }
            })
            await getSubDepartments().then(res => {
                if (res) {
                    setSubDepartments(res);
                }
            })
        } else if (employeeI.role === "department_head") {
            await getEmployeesWithSupervisorID(employeeI.user_id).then(res => {
                if (res) {
                    setEmployees(res.filter(r => !(r.role === "department_head")));
                }
            })
            await getSubDepartmentByDepartmentID(employeeI.department).then(res => {
                if (res) {
                    setSubDepartments(res);
                }
            })
            await getAllDivisionsByDepartmentID(employeeI.department).then(res => {
                if (res) {
                    setDivisions(res);
                }
            })
        }else if (employeeI.role === "sub_department_head") {
            if (!employeeI.sub_department || employeeI.sub_department === 0) return
            // await getAllUsersBYSubDepartmentID(employeeI.sub_department).then(res => {
            //     if (res) {
            //         setEmployees(res.filter(r => !(r.role === "department_head" || r.role === "sub_department_head")));
            //     }
            // })
             await getEmployeesWithSupervisorID(employeeI.user_id).then(res => {
                 if (res) {
                     setEmployees(res);
                 }
             })

            await getAllDivisionsBySubDepartmentID(employeeI.sub_department).then(res => {
                if (res) {
                    setDivisions(res);
                }
            })

        } else if (employeeI.role === "division_head") {
            await getEmployeesWithSupervisorID(employeeI.user_id).then(res => {
                if (res) {
                    setEmployees(res);
                }
            })
        } else if(employeeI.role === "unit_head") {
            await getEmployeesWithSupervisorID(employeeI.user_id).then(res => {
                if (res) {
                    setEmployees(res);
                }
            })
        } else {
            setEmployees([])
        }

        await getDivison(29).then(res => {
            if (res) {
                setDivisionIA(res);
            }
        })
    }

    const handleChangeInternalEmployees = async (id: number) => {
        await getEmployeesWithSupervisorID(id).then(res => {
            if (res) {
                setEmployeesI(res);
            }
        })
    }
    const handleChangeInternal2Employees = async (id: number) => {
        await getEmployeesWithSupervisorID(id).then(res => {
            if (res) {
                setEmployeesII(res);
            }
        })
    }
    // const handleChangeDepartmentEmployees = async (id: number) => {
    //     await getAllUsersBYDepartmentID(id).then(res => {
    //         if (res) {
    //             setDepartmentEmployees(res.filter(emp => emp.id !== employeeI?.user_id));
    //             setDepartmentEmployeesSearched(res.filter(emp => emp.id !== employeeI?.user_id))
    //         }
    //     })
    // }
    const handleChangeUnitEmployees = async (id: number) => {
        setDepartmentEmployees([])
        await getAllUsersBYUnitID(id).then(res => {
            if (res) {
                setDepartmentEmployees(res.filter(emp => emp.id !== employeeI?.user_id));
                setDepartmentEmployeesSearched(res.filter(emp => emp.id !== employeeI?.user_id))
            }
        })
    }
    const handleChangeDepartmentDivisions = async (id: number) => {
        setDivisions([])
        await getAllDivisionsByDepartmentID(id).then(res => {
            if (res) {
                setDivisions(res);
            }
        })
    }
    const handleChangeDivisionUnits = async (id: number) => {
        setUnits([])
        await getAllUnitsByDivisionID(id).then(res => {
            if (res) {
                setUnits(res);
            }
        })
    }

    const handleSearchDepartmentEmployees = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDepartmentEmployeesSearched(departmentEmployees.filter(employee => employee.fname.toLowerCase().includes(e.target.value.toLowerCase()) ||
            employee.lname.toLowerCase().includes(e.target.value.toLowerCase()) ||
            employee.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
            employee.lname.toLowerCase().includes(e.target.value.toLowerCase()
            )))
    }

    // console.log(employees.filter(employee => employee.department_id === employeeI?.department || employee.rank === "Director"));

    return (
        <div className={`w-full min-h-screen h-full flex flex-col items-center bg-white py-10 gap-16 shadow-xs shadow-stone-800/60`}>
            <div className={`flex flex-row items-center justify-center`}>
                <img className={`w-28`} src={logo} alt="KMC" />
            </div>
            <div className={`w-full h-full max-h-[90vh] overflow-auto no-scrollbar`}>
                <ul className={`w-full h-fit flex flex-col gap-2 justify-evenly py-2`}>
                    <li className={`w-full h-fit py-2 ${tab === TabType.SideDashboard ? "bg-stone-100" : "bg-stone-50"} px-4 hover:font-bold ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}>
                        <p onClick={() => {
                            setTab(TabType.SideDashboard);
                            setTag(TabType.SideDashboard);
                            setSelectedTab(``)
                            setTabEmployee(undefined);
                            setKind("")
                            setButtonType(undefined)
                        }} className={`w-full h-fit hover:font-bold flex flex-row gap-2 items-center`}>
                            <TfiDashboard
                                className={`text-stone-950 font-semibold w-5 h-5`}
                            />
                            Dashboard
                        </p>
                    </li>
                    {
                        departments.length > 0 && (departments.sort((a, b) => DepartmentRanking(a.id) - DepartmentRanking(b.id)).map((department => (
                            <li key={department.id}
                                className={`w-full h-fit ${tab === TabType.SideDepartment && (selectedTab.split("/")[0].trim() === "department" && selectedTab.split("/")[1] === `${department.id}`) ? "bg-stone-100" : "bg-stone-50"} py-2 px-4  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100 rounded-md`}>
                                <p
                                    onClick={() => {
                                        setTab(TabType.SideDepartment);
                                        setTag(TabType.SideDepartment);
                                        setTabEmployee(undefined);
                                        setKind("")
                                        setButtonType(undefined)
                                        handleChangeDepartmentDivisions(department.id).then()
                                        setSelectedTab(`department/${department.id}`)
                                    }}
                                    className={`w-full h-fit hover:font-bold flex flex-row gap-1 items-center`}
                                >
                                    <MdDashboardCustomize className={`text-stone-400 w-5 h-5`}/>
                                    {department.name}
                                </p>
                                {
                                    divisions.length > 0 && (
                                        <ul className={`w-full ${selectedTab.split("/")[0].trim() === "department" && selectedTab.split("/")[1] === `${department.id}` ? "flex" : "sub_display"} flex-col gap-2 justify-evenly bg-stone-200 mt-2 mb-2`}>
                                            <div
                                                className={`w-full max-h-32 lg:max-h-56 xl:max-h-72 2xl:max-h-80 h-fit overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-thin touch-auto scrollbar-thumb-stone-300 scrollbar-track-transparent`}>
                                                {
                                                    divisions.filter(d => d.name !== "INTERNAL AUDIT").map((division, i) => (
                                                        <li
                                                            key={division.id}
                                                            className={`w-full h-fit ${selectedTab.split("/")[0].trim() === "department" && selectedTab.split("/")[1] === `${department.id}` && selectedTab.split("/")[2] === "division" && selectedTab.split("/")[3] === `${division.id}` ? "bg-stone-100 shadow-sm -translate-y-0.5" : ""}  py-2 px-4 ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                                                        >

                                                            <p
                                                                onClick={() => {
                                                                    setTag(TabType.SideDivision);
                                                                    setTab(TabType.SideDivision);
                                                                    setTabEmployee(undefined)
                                                                    setSelectedTab(`department/${department.id}/division/${division.id}`)
                                                                    handleChangeDivisionUnits(division.id).then()
                                                                    setKind("")
                                                                    setButtonType(undefined)

                                                                }}
                                                                className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                                            >
                                                                {division.name}
                                                            </p>

                                                            {
                                                                units.length > 0 && (
                                                                    <ul className={`w-full ${selectedTab.split("/")[0].trim() === "department" && selectedTab.split("/")[1] === `${department.id}` && selectedTab.split("/")[2] === "division" && selectedTab.split("/")[3] === `${division.id}` ? "flex" : "sub_display"} flex-col gap-2 justify-evenly bg-stone-200 mt-2 mb-2`}>
                                                                        <div
                                                                            className={`w-full max-h-32 lg:max-h-56 xl:max-h-72 2xl:max-h-80 h-fit overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-thin touch-auto scrollbar-thumb-stone-300 scrollbar-track-transparent`}>
                                                                            {
                                                                                units.map((unit, i) => (
                                                                                    <li
                                                                                        key={unit.id}
                                                                                        className={`w-full h-fit ${selectedTab.split("/")[0].trim() === "department" && selectedTab.split("/")[1] === `${department.id}` && selectedTab.split("/")[2] === "division" && selectedTab.split("/")[3] === `${division.id}` && selectedTab.split("/")[4] === "unit" && selectedTab.split("/")[5] === `${unit.id}` ? "bg-stone-100 shadow-sm -translate-y-0.5" : ""}  py-2 px-4 ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                                                                                    >

                                                                                        <p
                                                                                            onClick={() => {
                                                                                                setTag(TabType.SideUnit);
                                                                                                setTab(TabType.SideUnit);
                                                                                                setTabEmployee(undefined)
                                                                                                setSelectedTab(`department/${department.id}/division/${division.id}/unit/${unit.id}`)
                                                                                                // handleChangeInternal2Employees(empI.id)
                                                                                                handleChangeUnitEmployees(unit.id).then()
                                                                                                setKind("")
                                                                                                setButtonType(undefined)

                                                                                            }}
                                                                                            className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                                                                        >
                                                                                            {unit.name}
                                                                                        </p>


                                                                                        {
                                                                                            departmentEmployees.length > 0 && (
                                                                                                <ul className={`w-full ${selectedTab.split("/")[0].trim() === "department" && selectedTab.split("/")[1] === `${department.id}` && selectedTab.split("/")[2] === "division" && selectedTab.split("/")[3] === `${division.id}` && selectedTab.split("/")[4] === "unit" && selectedTab.split("/")[5] === `${unit.id}` ? "flex" : "sub_display"} flex-col gap-2 justify-evenly bg-stone-200 mt-2 mb-2`}>
                                                                                                    <div
                                                                                                        className={`w-full max-h-32 lg:max-h-56 xl:max-h-72 2xl:max-h-80 h-fit overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-thin touch-auto scrollbar-thumb-stone-300 scrollbar-track-transparent`}>
                                                                                                        {
                                                                                                            departmentEmployees.sort((a, b) => (
                                                                                                                RoleRanking(a.role) - RoleRanking(b.role)
                                                                                                            )).map((employee, i) => (
                                                                                                                <li
                                                                                                                    key={employee.id}
                                                                                                                    className={`w-full h-fit ${selectedTab.split("/")[0].trim() === "department" && selectedTab.split("/")[1] === `${department.id}` && selectedTab.split("/")[2] === "division" && selectedTab.split("/")[3] === `${division.id}` && selectedTab.split("/")[4] === "unit" && selectedTab.split("/")[5] === `${unit.id}` && selectedTab.split("/")[6] === "employee" && selectedTab.split("/")[7] === `${employee.id}` ? "font-bold bg-stone-100 shadow-sm -translate-y-0.5" : ""}  py-2 px-4 hover:font-bold  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                                                                                                                >

                                                                                                                    <p
                                                                                                                        onClick={() => {
                                                                                                                            setTag(TabType.SideEmployee);
                                                                                                                            setTab(TabType.SideEmployee);
                                                                                                                            setTabEmployee(employee)
                                                                                                                            setSelectedTab(`department/${department.id}/division/${division.id}/unit/${unit.id}/employee/${employee.id}`)
                                                                                                                            // handleChangeInternal2Employees(empI.id)
                                                                                                                            setKind("")
                                                                                                                            setButtonType(undefined)

                                                                                                                        }}
                                                                                                                        className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                                                                                                    >
                                                                                                                        {`${employee?.fname} ${employee?.mname.String} ${employee?.lname}`}
                                                                                                                    </p>

                                                                                                                </li>
                                                                                                            ))
                                                                                                        }
                                                                                                    </div>
                                                                                                </ul>
                                                                                            )
                                                                                        }


                                                                                    </li>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </ul>
                                                                )
                                                            }

                                                        </li>
                                                    ))
                                                }
                                            </div>
                                        </ul>
                                    )
                                }


                            </li>

                        ))))
                    }

                    {
                        divisionIA && (

                            <li
                                className={`w-full h-fit ${selectedTab.split("/")[0].trim() === "division" && selectedTab.split("/")[1] === `${divisionIA.id}` ? "bg-stone-100 shadow-sm -translate-y-0.5" : ""}  py-2 px-4 ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                            >

                                <p
                                    onClick={() => {
                                        setTag(TabType.SideDivision);
                                        setTab(TabType.SideDivision);
                                        setTabEmployee(undefined)
                                        setSelectedTab(`division/${divisionIA.id}`)
                                        handleChangeDivisionUnits(divisionIA.id).then()
                                        setKind("")
                                        setButtonType(undefined)

                                    }}
                                    className={`w-full h-fit hover:font-bold flex flex-row items-center`}
                                >
                                    <MdDashboardCustomize className={`text-stone-400 w-5 h-5`}/>
                                    {divisionIA.name}
                                </p>

                                {
                                    units.length > 0 && (
                                        <ul className={`w-full ${selectedTab.split("/")[0].trim() === "division" && selectedTab.split("/")[1] === `${divisionIA.id}` ? "flex" : "sub_display"} flex-col gap-2 justify-evenly bg-stone-200 mt-2 mb-2`}>
                                            <div
                                                className={`w-full max-h-32 lg:max-h-56 xl:max-h-72 2xl:max-h-80 h-fit overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-thin touch-auto scrollbar-thumb-stone-300 scrollbar-track-transparent`}>
                                                {
                                                    units.map((unit, i) => (
                                                        <li
                                                            key={unit.id}
                                                            className={`w-full h-fit ${selectedTab.split("/")[0].trim() === "division" && selectedTab.split("/")[1] === `${divisionIA.id}` && selectedTab.split("/")[2] === "unit" && selectedTab.split("/")[3] === `${unit.id}` ? "bg-stone-100 shadow-sm -translate-y-0.5" : ""}  py-2 px-4 ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                                                        >

                                                            <p
                                                                onClick={() => {
                                                                    setTag(TabType.SideUnit);
                                                                    setTab(TabType.SideUnit);
                                                                    setTabEmployee(undefined)
                                                                    setSelectedTab(`division/${divisionIA.id}/unit/${unit.id}`)
                                                                    // handleChangeInternal2Employees(empI.id)
                                                                    handleChangeUnitEmployees(unit.id).then()
                                                                    setKind("")
                                                                    setButtonType(undefined)

                                                                }}
                                                                className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                                            >
                                                                {unit.name}
                                                            </p>


                                                            {
                                                                departmentEmployees.length > 0 && (
                                                                    <ul className={`w-full ${selectedTab.split("/")[0].trim() === "division" && selectedTab.split("/")[1] === `${divisionIA.id}` && selectedTab.split("/")[2] === "unit" && selectedTab.split("/")[3] === `${unit.id}` ? "flex" : "sub_display"} flex-col gap-2 justify-evenly bg-stone-200 mt-2 mb-2`}>
                                                                        <div
                                                                            className={`w-full max-h-32 lg:max-h-56 xl:max-h-72 2xl:max-h-80 h-fit overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-thin touch-auto scrollbar-thumb-stone-300 scrollbar-track-transparent`}>
                                                                            {
                                                                                departmentEmployees.sort((a, b) => (
                                                                                    RoleRanking(a.role) - RoleRanking(b.role)
                                                                                )).map((employee, i) => (
                                                                                    <li
                                                                                        key={employee.id}
                                                                                        className={`w-full h-fit ${selectedTab.split("/")[0].trim() === "division" && selectedTab.split("/")[1] === `${divisionIA.id}` && selectedTab.split("/")[2] === "unit" && selectedTab.split("/")[3] === `${unit.id}` && selectedTab.split("/")[4] === "employee" && selectedTab.split("/")[5] === `${employee.id}` ? "font-bold bg-stone-100 shadow-sm -translate-y-0.5" : ""}  py-2 px-4 hover:font-bold  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                                                                                    >

                                                                                        <p
                                                                                            onClick={() => {
                                                                                                setTag(TabType.SideEmployee);
                                                                                                setTab(TabType.SideEmployee);
                                                                                                setTabEmployee(employee)
                                                                                                setSelectedTab(`division/${divisionIA.id}/unit/${unit.id}/employee/${employee.id}`)
                                                                                                // handleChangeInternal2Employees(empI.id)
                                                                                                setKind("")
                                                                                                setButtonType(undefined)

                                                                                            }}
                                                                                            className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                                                                        >
                                                                                            {`${employee?.fname} ${employee?.mname.String} ${employee?.lname}`}
                                                                                        </p>

                                                                                    </li>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </ul>
                                                                )
                                                            }


                                                        </li>
                                                    ))
                                                }
                                            </div>
                                        </ul>
                                    )
                                }

                            </li>
                        )
                    }



                    {
                        (employeeI?.user_id === 4 || employeeI?.user_id === 1) && (

                            <li
                                className={`w-full h-fit ${tab === TabType.SideEmployee && selectedTab.split("/")[0].trim() === "employees" ? "bg-stone-100" : "bg-stone-50"} py-2 px-4  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100 rounded-md`}
                            >
                                <p
                                    onClick={() => {
                                        setTab(TabType.SideEmployee);
                                        setTag(TabType.SideEmployee);
                                        setTabEmployee(undefined);
                                        setKind("")
                                        setSelectedTab(`employees/`)
                                        setButtonType(undefined)
                                    }}
                                    className={`w-full h-fit hover:font-bold flex flex-row gap-1 items-center`}
                                >
                                    <IoPersonCircle className={`text-stone-400 w-5 h-5`}/>
                                    Employees
                                </p>
                                <ul
                                    className={`w-full ${tab === TabType.SideEmployee && selectedTab.split("/")[0].trim() === "employees" ? "flex" : "sub_display"} flex-col gap-2 justify-evenly bg-stone-200 mt-2 mb-2`}
                                >
                                    {
                                        employeeI?.role === "ceo" ? (
                                            employees.map((employee, i) => (

                                                <li
                                                    key={i}
                                                    className={`w-full h-fit ${selectedTab.split("/")[0].trim() === "employees" && selectedTab.split("/")[1] === `${employee.id}` ? "font-bold bg-stone-100 shadow-sm -translate-y-0.5" : ""}  py-2 px-4 hover:font-bold  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                                                >
                                                    <p
                                                        onClick={() => {
                                                            setTag(TabType.SideEmployee);
                                                            setTab(TabType.SideEmployee);
                                                            setTabEmployee(employee)
                                                            setSelectedTab(`employees/${employee.id}`)
                                                            handleChangeInternalEmployees(employee.id)
                                                            setKind("")
                                                            setButtonType(undefined)

                                                        }}
                                                        className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                                    >
                                                        {`${employee?.fname} ${employee?.mname.String} ${employee?.lname}`}
                                                    </p>
                                                    {
                                                        employeesI.length > 0 && (

                                                            <ul className={`w-full ${selectedTab.split("/")[0].trim() === "employees" && selectedTab.split("/")[1] === `${employee.id}` ? "flex" : "sub_display"} flex-col gap-2 justify-evenly bg-stone-200 mt-2 mb-2`}>
                                                                {
                                                                    employeesI.map((empI, j) => (
                                                                        <li
                                                                            key={j}
                                                                            className={`w-full h-fit ${selectedTab.split("/")[0].trim() === "employees" && selectedTab.split("/")[2] === `${empI.id}` ? "font-bold bg-stone-100 shadow-sm -translate-y-0.5" : ""}  py-2 px-4 hover:font-bold  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                                                                        >
                                                                            <p
                                                                                onClick={() => {
                                                                                    setTag(TabType.SideEmployee);
                                                                                    setTab(TabType.SideEmployee);
                                                                                    setTabEmployee(empI)
                                                                                    setSelectedTab(`employees/${employee.id}/${empI.id}`)
                                                                                    handleChangeInternal2Employees(empI.id)
                                                                                    setKind("")
                                                                                    setButtonType(undefined)

                                                                                }}
                                                                                className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                                                            >
                                                                                {`${empI?.fname} ${empI?.mname.String} ${empI?.lname}`}
                                                                            </p>

                                                                            {
                                                                                employeesII.length > 0 && (

                                                                                    <ul className={`w-full ${selectedTab.split("/")[0].trim() === "employees" && selectedTab.split("/")[1] === `${employee.id}` && selectedTab.split("/")[2] === `${empI.id}` ? "flex" : "sub_display"} flex-col gap-2 justify-evenly bg-stone-200 mt-2 mb-2`}>
                                                                                        {
                                                                                            employeesII.map((empI1, j) => (
                                                                                                <li
                                                                                                    key={j}
                                                                                                    className={`w-full h-fit ${selectedTab.split("/")[0].trim() === "employees" && selectedTab.split("/")[2] === `${empI.id}` && selectedTab.split("/")[3] === `${empI1.id}` ? "font-bold bg-stone-100 shadow-sm -translate-y-0.5" : ""}  py-2 px-4 hover:font-bold  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                                                                                                >
                                                                                                    <p
                                                                                                        onClick={() => {
                                                                                                            setTag(TabType.SideEmployee);
                                                                                                            setTab(TabType.SideEmployee);
                                                                                                            setTabEmployee(empI1)
                                                                                                            setSelectedTab(`employees/${employee.id}/${empI.id}/${empI1.id}`)
                                                                                                            // handleChangeInternalEmployees(employee.id)
                                                                                                            setKind("")
                                                                                                            setButtonType(undefined)

                                                                                                        }}
                                                                                                        className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                                                                                    >
                                                                                                        {`${empI1?.fname} ${empI1?.mname.String} ${empI1?.lname}`}
                                                                                                    </p>
                                                                                                </li>
                                                                                            ))
                                                                                        }
                                                                                    </ul>
                                                                                )
                                                                            }

                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        )
                                                    }
                                                </li>


                                            ))
                                        ) : employeeI?.role === "department_head" || employeeI?.role === "sub_department_head" || employeeI?.role === "division_head" || employeeI?.role === "unit_head" ? (
                                            employees.map((employee, i) => (

                                                <li
                                                    key={i}
                                                    className={`w-full h-fit ${selectedTab.split("/")[0].trim() === "employees" && selectedTab.split("/")[1] === `${employee.id}` ? "font-bold bg-stone-100 shadow-sm -translate-y-0.5" : ""}  py-2 px-4 hover:font-bold  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                                                >
                                                    <p
                                                        onClick={() => {
                                                            setTag(TabType.SideEmployee);
                                                            setTab(TabType.SideEmployee);
                                                            setTabEmployee(employee)
                                                            setSelectedTab(`employees/${employee.id}`)
                                                            handleChangeInternalEmployees(employee.id)
                                                            setKind("")
                                                            setButtonType(undefined)

                                                        }}
                                                        className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                                    >
                                                        {`${employee?.fname} ${employee?.mname.String} ${employee?.lname}`}
                                                    </p>
                                                    {
                                                        employeesI.length > 0 && (

                                                            <ul className={`w-full ${selectedTab.split("/")[0].trim() === "employees" && selectedTab.split("/")[1] === `${employee.id}` ? "flex" : "sub_display"} flex-col gap-2 justify-evenly bg-stone-200 mt-2 mb-2`}>
                                                                {
                                                                    employeesI.map((empI, j) => (
                                                                        <li
                                                                            key={j}
                                                                            className={`w-full h-fit ${selectedTab.split("/")[0].trim() === "employees" && selectedTab.split("/")[2] === `${empI.id}` ? "font-bold bg-stone-100 shadow-sm -translate-y-0.5" : ""}  py-2 px-4 hover:font-bold  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                                                                        >
                                                                            <p
                                                                                onClick={() => {
                                                                                    setTag(TabType.SideEmployee);
                                                                                    setTab(TabType.SideEmployee);
                                                                                    setTabEmployee(empI)
                                                                                    setSelectedTab(`employees/${employee.id}/${empI.id}`)
                                                                                    handleChangeInternal2Employees(empI.id)
                                                                                    setKind("")
                                                                                    setButtonType(undefined)

                                                                                }}
                                                                                className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                                                            >
                                                                                {`${empI?.fname} ${empI?.mname.String} ${empI?.lname}`}
                                                                            </p>

                                                                            {
                                                                                employeesII.length > 0 && (

                                                                                    <ul className={`w-full ${selectedTab.split("/")[0].trim() === "employees" && selectedTab.split("/")[1] === `${employee.id}` && selectedTab.split("/")[2] === `${empI.id}` ? "flex" : "sub_display"} flex-col gap-2 justify-evenly bg-stone-200 mt-2 mb-2`}>
                                                                                        {
                                                                                            employeesII.map((empI1, j) => (
                                                                                                <li
                                                                                                    key={j}
                                                                                                    className={`w-full h-fit ${selectedTab.split("/")[0].trim() === "employees" && selectedTab.split("/")[2] === `${empI.id}` && selectedTab.split("/")[3] === `${empI1.id}` ? "font-bold bg-stone-100 shadow-sm -translate-y-0.5" : ""}  py-2 px-4 hover:font-bold  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                                                                                                >
                                                                                                    <p
                                                                                                        onClick={() => {
                                                                                                            setTag(TabType.SideEmployee);
                                                                                                            setTab(TabType.SideEmployee);
                                                                                                            setTabEmployee(empI1)
                                                                                                            setSelectedTab(`employees/${employee.id}/${empI.id}/${empI1.id}`)
                                                                                                            // handleChangeInternalEmployees(employee.id)
                                                                                                            setKind("")
                                                                                                            setButtonType(undefined)

                                                                                                        }}
                                                                                                        className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                                                                                    >
                                                                                                        {`${empI1?.fname} ${empI1?.mname.String} ${empI1?.lname}`}
                                                                                                    </p>
                                                                                                </li>
                                                                                            ))
                                                                                        }
                                                                                    </ul>
                                                                                )
                                                                            }

                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        )
                                                    }
                                                </li>
                                            ))
                                        ) : (
                                            <li
                                                className={`w-full h-fit  py-2 px-4 hover:font-bold  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                                            >
                                                <p
                                                    onClick={() => {
                                                        setTag(TabType.SideEmployee);
                                                        setSelectedTab(`employees/${employeeI?.user_id}`)
                                                        setTabEmployee(undefined)
                                                        setKind("")
                                                        setButtonType(undefined)
                                                    }}
                                                    className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                                >
                                                    {`${employeeI?.fname} ${employeeI?.mname} ${employeeI?.lname}`}

                                                </p>
                                            </li>
                                        )
                                    }
                                </ul>
                            </li>
                        )
                    }

                </ul>
            </div>
        </div>
    )
};

export default SideBar;