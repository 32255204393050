import React, {useEffect} from 'react';
import {IoPersonCircle} from "react-icons/io5";

import {
    months,
    MonthStructure,
    ScoresBarGraphStructure,
    scoresGraphReStructure,
    yearsExtractor
} from "../../utils/scoresGraphReStructure";
import {scoreFilter} from "../../utils/scoreUtil";
import {
    getAllWorkPlansByDepartment,
    getAllWorkPlansBySubDepartment,
    getWorkPlanByEmployeeID,
    WorkPlanData
} from "../../services/workPlanServiice";
import {getReportByEmployeeID, ReportData} from "../../services/reportService";
import {ObjectiveRTR, reportTaskReStructure} from "../../utils/reportTaskReStructure";
import {useAuth} from "../../context/authContext";
import {useNavigate} from "react-router-dom";
import {Department, getDepartment} from "../../services/departmentService";
import {ButtonType} from "../../context/button/buttonTypes";
import {useButtonTypeContext} from "../../context/button/buttonContext";
import {workPlanReStruct} from "../../utils/workPlanRestruct";
import {getSubDepartment, SubDepartment} from "../../services/subDepartment";
import {FcApproval, FcCancel} from "react-icons/fc";
import {PiDotsThree} from "react-icons/pi";
import {EmployeeDB, getEmployee} from "../../services/employee";


const ll = [1,2,3,4,5,6,7,8,9,10,11,12]

const SubDepartmentDashboard = () => {
    const [workPlans, setWorkPlans] = React.useState<WorkPlanData[]>([]);
    const [selectedWorkPlan, setSelectedWorkPlan] = React.useState<WorkPlanData|undefined>(undefined);

    const [currentSubDepartment, setCurrentSubDepartment] = React.useState<SubDepartment | undefined>(undefined);

    const [reports, setReports] = React.useState<ReportData[]>([]);
    const [selectedReport, setSelectedReport] = React.useState<ReportData|undefined>(undefined);
    const [reportRTR, setReportRTR] = React.useState<ObjectiveRTR[]>([]);

    const [graphYears, setGraphYears] = React.useState<number[]>([]);
    const [graphReportYearData, setGraphReportYearData] = React.useState<ScoresBarGraphStructure[]>([]);
    const [selectedGraphYears, setSelectedGraphYears] = React.useState<number|undefined>(undefined);
    const [selectedGraphYearData, setSelectedGraphYearData] = React.useState<MonthStructure[]>([]);

    const [currentSup, setCurrentSup] = React.useState<EmployeeDB | undefined>(undefined);
    const [currentSSup, setCurrentSSup] = React.useState<EmployeeDB | undefined>(undefined);

    const {employeeI} = useAuth();

    const navigate = useNavigate();


    useEffect(() => {
        if (!employeeI) navigate("/login")
        fetchData().then();
    },[]);

    const handleChangeWorkPlan = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        const workPlan = workPlans.find((workPlan) => workPlan.id === value);
        if (!workPlan) return;
        setSelectedWorkPlan(workPlan);
    }

    const handleChangeReport = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        const report = reports.find((report) => report.id === value);
        if (!report) return;
        setSelectedReport(report);
    }

    const handleChangeGraphYear = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        // console.log(value)
        setSelectedGraphYears(value);
        // console.log(graphReportYearData)
        let dd = graphReportYearData.find((year) => year.year === value);
        if (!dd) return;
        // console.log(dd)
        setSelectedGraphYearData(dd.monthlyData.sort((a,b) => a.monthIndex - b.monthIndex))
    }

    const fetchData = async () => {
        if (!employeeI) {
            navigate("/login");
            return;
        }
        if(!employeeI.sub_department || employeeI.sub_department <= 0) return;

        await getAllWorkPlansBySubDepartment(employeeI.sub_department, 'sub_department').then(res => {
            if (res) {
                // console.log(res)
                setWorkPlans(
                    res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                );
                setSelectedWorkPlan(
                    res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0]
                );
            }
        });

        await getReportByEmployeeID(employeeI.user_id).then(res => {
            if (res) {
                setReports(
                    res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                );
                setSelectedReport(
                    res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0]
                );

                if (res.length > 0) {
                    setReportRTR(reportTaskReStructure(res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0].WorkPlan.Tasks));
                    setGraphReportYearData(scoresGraphReStructure(res));
                    setGraphYears(yearsExtractor(res));
                    let yy = yearsExtractor(res);
                    let mm = scoresGraphReStructure(res);
                    let dd = mm.find(m => m.year === yy[0]);
                    if (!dd) {
                        setSelectedGraphYearData([]);
                        return;
                    }
                    setSelectedGraphYearData(dd.monthlyData.sort((a,b) => a.monthIndex - b.monthIndex));

                } else {
                    setReportRTR([]);
                    setGraphReportYearData([]);
                    setGraphYears([]);
                    setSelectedGraphYearData([]);
                }

                // setReportRTR(reportTaskReStructure(res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0].WorkPlan.Tasks))
                // setGraphReportYearData(scoresGraphReStructure(res));
                // setGraphYears(yearsExtractor(res));
                // let yy = yearsExtractor(res);
                // let mm = scoresGraphReStructure(res);
                // let dd = mm.find(m => m.year === yy[0]);
                // if (!dd) {
                //     setSelectedGraphYearData([]);
                //     return;
                // }
                // setSelectedGraphYearData(dd.monthlyData.sort((a,b) => a.monthIndex - b.monthIndex))
            }



        });


        if (employeeI.supervisor && employeeI.supervisor > 0) {
            await getEmployee(employeeI.supervisor).then(res => {
                if (res) {
                    setCurrentSup(res);
                    // console.log(res)
                }
            })
        }

        if (employeeI.sSupervisor && employeeI.sSupervisor > 0) {
            await getEmployee(employeeI.sSupervisor).then(res => {
                if (res) {
                    setCurrentSSup(res)
                }
            })
        }


        // Always last
        // if(!employeeI.sub_department || employeeI.sub_department <= 0) return;
        await getSubDepartment(employeeI.sub_department).then(res => {
            if (res) {
                setCurrentSubDepartment(res);
            }
        });
    }



    const {setButtonType, setKind} = useButtonTypeContext()
    return (
        <div className={`w-full h-full flex flex-col gap-4 px-4 py-32`}>
            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <div className={`w-fit h-fit flex flex-row gap-4 items-center`}>
                    {
                        !employeeI?.profile_photo || employeeI?.profile_photo?.length <= 0 ? (
                            <>
                                <IoPersonCircle className={`text-stone-400 w-32 h-32`}/>
                            </>
                        ) : (
                            <img className={`w-32 h-32 rounded-full`} src={employeeI?.profile_photo}
                                 alt={`${employeeI.fname[0]}${employeeI.mname && employeeI.mname?.length > 0 ? `${employeeI.mname[0]}${employeeI.lname[0]}` : employeeI.lname[0]}}`}/>
                        )
                    }
                    <div className={`w-fit h-fit flex flex-col gap-2 text-lg text-[#1c1c1c] font-medium`}>
                        <p>{`${employeeI?.fname.toUpperCase()} ${employeeI?.mname?.toUpperCase()} ${employeeI?.lname.toUpperCase()}`}</p>
                        <p>{employeeI?.position}</p>
                        <p>{employeeI?.tel}</p>
                        {
                            (employeeI !== undefined && employeeI?.ip_phone.length) > 0 && (

                                <p>IP-PHONE: {employeeI?.ip_phone}</p>
                            )
                        }
                        {/*<p>Performance Avg: 4.5</p>*/}
                    </div>
                </div>
                <div className={`w-fit h-fit flex flex-col gap-4`}>
                    {/*<p className={`text-[#1c1c1c] text-xl font-semibold`}>{currentUnit && currentUnit.name}*/}
                    {/*        <br/>PERFORMANCE SUMMARY</p>*/}


                    {
                        employeeI?.supervisor && employeeI.supervisor > 0 ? (
                            <div className={`w-fit h-fit flex flex-row gap-4`}>
                                <p className={`text-gray-500 text-sm font-semibold`}>Supervisor:</p>
                                <p className={`text-gray-800 text-sm font-semibold`}>{`${currentSup?.fname} ${currentSup?.mname.String} ${currentSup?.lname}`}</p>
                            </div>
                        ) : <div></div>
                    }


                    {
                        employeeI?.sSupervisor && employeeI.sSupervisor > 0 ? (
                            <div className={`w-fit h-fit flex flex-row gap-4`}>
                                <p className={`text-gray-500 text-sm font-semibold`}>Supervisor's Supervisor:</p>
                                <p className={`text-gray-800 text-sm font-semibold`}>{`${currentSSup?.fname} ${currentSSup?.mname.String} ${currentSSup?.lname}`}</p>
                            </div>
                        ) : <div></div>
                    }
                </div>
            </div>

            <div
                className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col justify-between gap-6`}>
                <div className={`w-full h-fit flex flex-row items-center justify-between`}>
                    <p className={`font-medium text-lg text-[#1c1c1c]`}>Overview</p>
                    {
                        graphYears.length > 0 && (
                            <select
                                onChange={handleChangeGraphYear}
                                className={`w-fit px-2 py-1.5 outline-0 focus:outline-0 border-0 text-sm font-semibold text-[#1c1c1c]`}>
                                {
                                    graphYears.map(year => (
                                        <option value={year} key={year}>{year}</option>
                                    ))
                                }
                            </select>

                        )
                    }

                </div>
                <div className={`w-full h-64 flex flex-row justify-start items-end`}>
                    {
                        selectedGraphYearData.length > 0 ? ll.map((n, i) => {
                            let kk = selectedGraphYearData.find(gf => gf.monthIndex === n)

                            return (
                                <div key={i} className={`w-1/2 h-fit flex flex-col gap-1 items-center`}>
                                    {

                                        kk ? (<div
                                                className={`w-full pt-5 ${kk.value === null ? "h-16 bg-[#E6E6E6]" : kk.value < 0.5 ? "h-5 bg-[#ef5350]" : kk.value >= 0.5 && kk.value < 1.5 ? "h-20 bg-[#fcf3cf]" : kk.value >= 1.5 && kk.value < 2.5 ? "h-32 bg-[#F8E7A5]" : kk.value >= 2.5 && kk.value < 3.5 ? "h-40 bg-[#8DEB8D]" : "h-56 bg-[#65C365]"}`}>
                                                <p className={`text-center text-sm lg:text-lg font-semibold text-[#1c1c1c]`}>{kk.value === null? "-" : kk.value < 0.5 ? "UN" : kk.value >= 0.5 && kk.value < 1.5 ? "AC" : kk.value >= 1.5 && kk.value < 2.5 ? "GO" : kk.value >= 2.5 && kk.value < 3.5 ? "EX" : "OU"}</p>
                                            </div>
                                        ) : ""
                                    }

                                    <p>{months[i].slice(0, 3)}</p>
                                </div>
                            )
                        }) : (
                            <div className={`w-full h-full flex flex-row items-center justify-center mb-8`}>
                                <p className={`text-center text-wrap text-sm font-semibold text-gray-500`}>No scores to
                                    report on yet!</p>
                            </div>
                        )
                    }
                </div>
            </div>

            {/*  Report  */}

            <div className={`w-full h-fit flex flex-col gap-4  mt-4`}>
                <div className={`w-full h-fit flex flex-row justify-between items-center`}>
                    <select
                        onChange={handleChangeReport}
                        value={selectedReport?.id}
                        className={`w-fit px-2 py-1.5 outline-0 focus:outline-0 bg-transparent border-0 text-sm font-semibold text-[#1c1c1c]`}>
                        {
                            reports.map((reports, i) => (
                                <option key={i}
                                        value={reports.id}>{`${reports.month.slice(0, 3)} - ${reports.year} Report`}</option>
                            ))
                        }
                    </select>
                    <div className={`w-fit h-fit flex flex-row justify-evenly items-center gap-2`}>

                        <p onClick={() => {
                            setKind("sub_department")
                            setButtonType(ButtonType.Report)
                        }}
                           className={`w-fit h-fit px-2 py-1.5 bg-transparent cursor-pointer text-sm font-semibold text-[#1c1c1c] rounded-md hover:bg-white`}>View
                            Reports</p>
                        <p onClick={() => {
                            setKind("department")
                            setButtonType(ButtonType.Report)
                        }}
                           className={`w-fit h-fit px-2 py-1.5 bg-transparent cursor-pointer text-sm font-semibold text-[#1c1c1c] rounded-md hover:bg-white`}>View
                            Dep Reports</p>
                    </div>
                </div>
                <div className={`w-full h-fit flex flex-col justify-between gap-4`}>

                    {
                        selectedReport && (
                            <>
                                {
                                    reportTaskReStructure(selectedReport.WorkPlan.Tasks).map((obj, index) => (

                                        obj.activities.length > 0 ? (
                                            <div key={index} className={`w-full h-fit flex flex-col gap-4  mt-4`}>
                                                {/*<div className={`w-full h-fit flex flex-row justify-between items-center`}>*/}
                                                {/*    <p className={` text-2xl font-semibold text-[#1c1c1c]`}>{obj.objectiveName}</p>*/}
                                                {/*</div>*/}
                                                <div className={`w-full h-fit flex flex-col justify-between gap-4`}>
                                                    <div
                                                        className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>
                                                        <div
                                                            className={`text-lg xl:text-xl 2xl:text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle flex flex-row gap-x-4`}>
                                                            <div className={`w-1/4 h-fit`}>{obj.objectiveName}</div>
                                                            <div
                                                                className={`w-3/4 h-fit justify-items-start text-wrap align-middle grid grid-cols-9 gap-x-6`}>
                                                                <div className={`w-full h-fit col-span-4`}>Target</div>
                                                                <div className={`w-full h-fit col-span-3`}>Status</div>
                                                                <div className={`w-full h-fit col-span-2`}>Score
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`w-full bg-[#999999] py-[1px]`}></div>
                                                        {
                                                            obj.activities.length >= 0 && (
                                                                obj.activities.map((activity, i) => (
                                                                    <div key={i}
                                                                         className={`w-full h-fit flex flex-col gap-4 justify-center relative`}>
                                                                        <div
                                                                            className={`text-xs lg:text-sm 2xl:text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle flex flex-row gap-x-6`}>
                                                                            <div
                                                                                className={`w-1/4 h-fit flex flex-row gap-1 items-center`}>
                                                                                <p>{activity.activityName}</p>
                                                                            </div>
                                                                            {
                                                                                activity.tasks.length <= 0 ?
                                                                                    (
                                                                                        <div
                                                                                            className={`w-3/4 h-fit flex flex-row items-center justify-center`}>
                                                                                            <div className={`w-fit h-fit`}>
                                                                                                <p className={`w-fit h-fit rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-gray-500`}>No
                                                                                                    targets allocated for this
                                                                                                    Activity.</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) :
                                                                                    (
                                                                                        <div
                                                                                            className={`w-3/4 h-fit flex flex-col gap-4`}>
                                                                                            {
                                                                                                activity.tasks.map((task, i) => (
                                                                                                    task.onReport && (

                                                                                                        <div key={i}
                                                                                                             className={`w-full h-fit justify-items-start text-wrap align-middle grid grid-cols-9 gap-x-6`}>
                                                                                                            <div
                                                                                                                className={`w-full h-fit col-span-4 flex flex-row gap-1 items-center`}>
                                                                                                                {task.taskName}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className={`w-full h-fit col-span-3 flex flex-col gap-2`}>
                                                                                                                <p className={``}>{task.status}</p>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                            <span
                                                                                                                className={`min-w-32 w-fit text-center rounded-full min-h-8 h-fit py-1.5 px-4 ${scoreFilter(task.taskScore.score)} font-semibold`}>
                                                                                                                {task.taskScore.id <= 0 ? "" : task.taskScore.score}
                                                                                                            </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )

                                                                                                ))
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                            }
                                                                        </div>

                                                                        <div
                                                                            className={`w-full flex flex-row items-center`}>
                                                                            <div
                                                                                className={`w-full h-0.5 bg-[#999999] py-[1px]`}></div>
                                                                        </div>


                                                                    </div>
                                                                ))
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ("")


                                    ))
                                }
                                <div className={`w-full h-fit flex flex-col justify-between gap-4 mt-16`}>
                                    <div
                                        className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>

                                        <div
                                            className={`text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle grid ${selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>
                                            <div className={`w-full h-fit col-span-1 text-center`}>Prepared By</div>


                                            {
                                                selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid ? (
                                                    <>
                                                        <div className={`w-full h-fit col-span-1 text-center relative`}>
                                                            {
                                                                selectedReport.unit_head_approval === "rejected" ? (
                                                                    <div className={`absolute bottom-3 right-20`}>
                                                                        <FcCancel className={`w-5 h-5`}/>
                                                                    </div>
                                                                ) : selectedReport.unit_head_approval === "approved" ? (
                                                                    <div className={`absolute bottom-3 right-20`}>
                                                                        <FcApproval className={`w-5 h-5`}/>
                                                                    </div>
                                                                ) : (
                                                                    <div className={`absolute bottom-3 right-20`}>
                                                                        <PiDotsThree
                                                                            className={`w-5 h-5 text-gray-500`}/>
                                                                    </div>
                                                                )
                                                            }

                                                            Checked By
                                                        </div>
                                                        <div className={`w-full h-fit col-span-1 text-center relative`}>
                                                            {
                                                                selectedReport.manager_approval === "rejected" ? (
                                                                    <div className={`absolute bottom-3 right-16`}>
                                                                        <FcCancel className={`w-5 h-5`}/>
                                                                    </div>
                                                                ) : selectedReport.manager_approval === "approved" ? (
                                                                    <div className={`absolute bottom-3 right-16`}>
                                                                        <FcApproval className={`w-5 h-5`}/>
                                                                    </div>
                                                                ) : (
                                                                    <div className={`absolute bottom-3 right-16`}>
                                                                        <PiDotsThree
                                                                            className={`w-5 h-5 text-gray-500`}/>
                                                                    </div>
                                                                )
                                                            }Approved By
                                                        </div>
                                                    </>
                                                ) : (

                                                    <>
                                                        <div className={`w-full h-fit col-span-1 text-center relative`}>
                                                            {
                                                                selectedReport.unit_head_approval === "rejected" ? (
                                                                    <div className={`absolute bottom-3 right-20`}>
                                                                        <FcCancel className={`w-5 h-5`}/>
                                                                    </div>
                                                                ) : selectedReport.unit_head_approval === "approved" ? (
                                                                    <div className={`absolute bottom-3 right-20`}>
                                                                        <FcApproval className={`w-5 h-5`}/>
                                                                    </div>
                                                                ) : (
                                                                    <div className={`absolute bottom-3 right-20`}>
                                                                        <PiDotsThree
                                                                            className={`w-5 h-5 text-gray-500`}/>
                                                                    </div>
                                                                )
                                                            }

                                                            Checked & Approved By
                                                        </div>
                                                    </>
                                                )
                                            }


                                        </div>

                                        <div className={`w-full bg-[#999999] py-[1px]`}></div>
                                        <div
                                            className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid ${selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>

                                            <div
                                                className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                <p className={`w-full text-center`}>{`${selectedReport.Employee.fname} ${selectedReport.Employee.mname.String} ${selectedReport.Employee.lname}`}</p>
                                                <p className={`w-full text-center`}>{`${selectedReport.Employee.position}`}</p>
                                            </div>

                                            {
                                                selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid ? (
                                                    <>
                                                        <div
                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                            <p className={`w-full text-center`}>{`${selectedReport.UnitHead.fname} ${selectedReport.UnitHead.mname.String} ${selectedReport.UnitHead.lname}`}</p>
                                                            <p className={`w-full text-center`}>{`${selectedReport.UnitHead.position}`}</p>
                                                        </div>
                                                        <div
                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                            <p className={`w-full text-center`}>{`${selectedReport.Manager.fname} ${selectedReport.Manager.mname.String} ${selectedReport.Manager.lname}`}</p>
                                                            <p className={`w-full text-center`}>{`${selectedReport.Manager.position}`}</p>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                            <p className={`w-full text-center`}>{`${selectedReport.UnitHead.fname} ${selectedReport.UnitHead.mname.String} ${selectedReport.UnitHead.lname}`}</p>
                                                            <p className={`w-full text-center`}>{`${selectedReport.UnitHead.position}`}</p>
                                                        </div>
                                                    </>
                                                )
                                            }

                                        </div>

                                        <div
                                            className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid ${selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>
                                            <div
                                                className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                <p className={`w-full text-center`}>{new Date(selectedReport.created_at).toLocaleDateString("en-GB")}</p>
                                            </div>

                                            {
                                                selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid ? (
                                                    <>
                                                        <div
                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                            {
                                                                selectedReport.unit_head_approval_at.Valid && (

                                                                    <p className={`w-full text-center`}>{new Date(selectedReport.unit_head_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                                )
                                                            }
                                                        </div>
                                                        <div
                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                            {
                                                                selectedReport.manager_approval_at.Valid && (
                                                                    <p className={`w-full text-center`}>{new Date(selectedReport.manager_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                                )}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                            {
                                                                selectedReport.unit_head_approval_at.Valid && (

                                                                    <p className={`w-full text-center`}>{new Date(selectedReport.unit_head_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                                )
                                                            }
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>

                        )
                    }
                </div>
            </div>


            {/*  WorkPlan  */}
            <div className={`w-full h-fit flex flex-col gap-4  mt-10`}>
                <div className={`w-full h-fit flex flex-row justify-between items-center`}>
                    <select
                        onChange={handleChangeWorkPlan}
                        className={`w-fit px-2 py-1.5 outline-0 focus:outline-0 bg-transparent border-0 text-sm font-semibold text-[#1c1c1c]`}>
                        {
                            workPlans.map((workPlan: WorkPlanData, i) => (
                                <option key={i}
                                        value={workPlan.id}>{`${workPlan.month.slice(0, 3)} - ${workPlan.year} Work Plan`}</option>
                            ))
                        }
                    </select>
                    <div className={`w-fit h-fit flex flex-row justify-evenly items-center gap-2`}>
                        <p onClick={() => {
                            setKind("sub_department")
                            setButtonType(ButtonType.WorkPlan)
                        }}
                           className={`w-fit h-fit px-2 py-1.5 bg-transparent cursor-pointer text-sm font-semibold text-[#1c1c1c] rounded-md hover:bg-white`}>View WorkPlans</p>
                        <p onClick={() => {
                            setKind("department")
                            setButtonType(ButtonType.WorkPlan)
                        }}
                           className={`w-fit h-fit px-2 py-1.5 bg-transparent cursor-pointer text-sm font-semibold text-[#1c1c1c] rounded-md hover:bg-white`}>View
                            Dep WorkPlans</p>
                    </div>
                    {/*<div className={`w-fit h-fit flex flex-row justify-evenly items-center gap-2`}>*/}

                    {/*</div>*/}
                </div>

                <div className={`w-full h-fit flex flex-col justify-between gap-4`}>

                    {
                        selectedWorkPlan && (
                            <>
                                {
                                    workPlanReStruct(selectedWorkPlan.Tasks).map((obj, index) => (

                                        <div key={index} className={`w-full h-fit flex flex-col gap-4  mt-4`}>
                                            {/*<div className={`w-full h-fit flex flex-row justify-between items-center`}>*/}
                                            {/*    <p className={` text-2xl font-semibold text-[#1c1c1c]`}>{obj.objectiveName}</p>*/}
                                            {/*</div>*/}
                                            <div className={`w-full h-fit flex flex-col justify-between gap-4`}>
                                                <div
                                                    className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>
                                                    <div
                                                        className={`text-lg xl:text-xl 2xl:text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle flex flex-row gap-x-4`}>
                                                        <div className={`w-1/4 h-fit`}>{obj.objectiveName}</div>
                                                        <div
                                                            className={`w-3/4 h-fit justify-items-start text-wrap align-middle grid grid-cols-9 gap-x-4`}>
                                                            <div className={`w-full h-fit col-span-3`}>Target</div>
                                                            <div className={`w-full h-fit col-span-2`}>Unit of Measure</div>
                                                            <div className={`w-full h-fit col-span-2 text-center`}>Resource
                                                            </div>
                                                            <div className={`w-full h-fit col-span-2 text-center`}>Timeline
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`w-full bg-[#999999] py-[1px]`}></div>
                                                    {
                                                        obj.activities.length >= 0 && (
                                                            obj.activities.map((activity, i) => (
                                                                <div key={i}
                                                                     className={`w-full h-fit flex flex-col gap-4 justify-center relative`}>
                                                                    <div
                                                                        className={`text-xs lg:text-sm 2xl:text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle flex flex-row gap-x-6`}>
                                                                        <div
                                                                            className={`w-1/4 h-fit flex flex-row gap-1 items-center`}>
                                                                            <p>{activity.activityName}</p>
                                                                        </div>
                                                                        {
                                                                            activity.tasks.length <= 0 ?
                                                                                (
                                                                                    <div
                                                                                        className={`w-3/4 h-fit flex flex-row items-center justify-center`}>
                                                                                        <div className={`w-fit h-fit`}>
                                                                                            <p className={`w-fit h-fit rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-gray-500`}>No
                                                                                                targets a located for this
                                                                                                Activity.</p>
                                                                                        </div>
                                                                                    </div>
                                                                                ) :
                                                                                (
                                                                                    <div
                                                                                        className={`w-3/4 h-fit flex flex-col gap-4`}>
                                                                                        {
                                                                                            activity.tasks.map((task, i) => (

                                                                                                <div key={i}
                                                                                                     className={`w-full h-fit justify-items-start text-wrap align-middle grid grid-cols-9 gap-x-4`}>
                                                                                                    <div
                                                                                                        className={`w-full h-fit col-span-3 flex flex-row gap-1 items-center`}>
                                                                                                        {task.name}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                        <p className={`w-full text-center`}>{task.unit_of_measure}</p>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                        <p className={`w-full text-center`}>{task.resource}</p>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={`w-full h-fit col-span-2 flex flex-col gap-2 relative`}>
                                                                                                        <p className={`w-full text-center`}>{new Date(task.dead_line).toLocaleDateString("en-GB")}</p>
                                                                                                        <div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                        }
                                                                    </div>

                                                                    <div
                                                                        className={`w-full flex flex-row items-center`}>
                                                                        <div
                                                                            className={`w-full h-0.5 bg-[#999999] py-[1px]`}></div>
                                                                    </div>


                                                                </div>
                                                            ))
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className={`w-full h-fit flex flex-col justify-between gap-4 mt-32`}>
                                    <div
                                        className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>
                                        <div
                                            className={`text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle grid ${selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>
                                            <div className={`w-full h-fit col-span-1 text-center`}>Prepared By</div>


                                            {
                                                selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid ? (
                                                    <>
                                                        <div className={`w-full h-fit col-span-1 text-center relative`}>
                                                            {
                                                                selectedWorkPlan.unit_head_approval === "rejected" ? (
                                                                    <div className={`absolute bottom-3 right-20`}>
                                                                        <FcCancel className={`w-5 h-5`}/>
                                                                    </div>
                                                                ) : selectedWorkPlan.unit_head_approval === "approved" ? (
                                                                    <div className={`absolute bottom-3 right-20`}>
                                                                        <FcApproval className={`w-5 h-5`}/>
                                                                    </div>
                                                                ) : (
                                                                    <div className={`absolute bottom-3 right-20`}>
                                                                        <PiDotsThree className={`w-5 h-5 text-gray-500`}/>
                                                                    </div>
                                                                )
                                                            }

                                                            Checked By
                                                        </div>
                                                        <div className={`w-full h-fit col-span-1 text-center relative`}>
                                                            {
                                                                selectedWorkPlan.manager_approval === "rejected" ? (
                                                                    <div className={`absolute bottom-3 right-16`}>
                                                                        <FcCancel className={`w-5 h-5`}/>
                                                                    </div>
                                                                ) : selectedWorkPlan.manager_approval === "approved" ? (
                                                                    <div className={`absolute bottom-3 right-16`}>
                                                                        <FcApproval className={`w-5 h-5`}/>
                                                                    </div>
                                                                ) : (
                                                                    <div className={`absolute bottom-3 right-16`}>
                                                                        <PiDotsThree className={`w-5 h-5 text-gray-500`}/>
                                                                    </div>
                                                                )
                                                            }Approved By
                                                        </div>
                                                    </>
                                                ) : (

                                                    <>
                                                        <div className={`w-full h-fit col-span-1 text-center relative`}>
                                                            {
                                                                selectedWorkPlan.unit_head_approval === "rejected" ? (
                                                                    <div className={`absolute bottom-3 right-20`}>
                                                                        <FcCancel className={`w-5 h-5`}/>
                                                                    </div>
                                                                ) : selectedWorkPlan.unit_head_approval === "approved" ? (
                                                                    <div className={`absolute bottom-3 right-20`}>
                                                                        <FcApproval className={`w-5 h-5`}/>
                                                                    </div>
                                                                ) : (
                                                                    <div className={`absolute bottom-3 right-20`}>
                                                                        <PiDotsThree className={`w-5 h-5 text-gray-500`}/>
                                                                    </div>
                                                                )
                                                            }

                                                            Checked & Approved By
                                                        </div>
                                                    </>
                                                )
                                            }


                                        </div>
                                        <div className={`w-full bg-[#999999] py-[1px]`}></div>
                                        <div
                                            className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid ${selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>

                                            <div
                                                className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                <p className={`w-full text-center`}>{`${selectedWorkPlan.Employee.fname} ${selectedWorkPlan.Employee.mname.String} ${selectedWorkPlan.Employee.lname}`}</p>
                                                <p className={`w-full text-center`}>{`${selectedWorkPlan.Employee.position}`}</p>
                                            </div>

                                            {
                                                selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid ? (
                                                    <>
                                                        <div
                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                            <p className={`w-full text-center`}>{`${selectedWorkPlan.UnitHead.fname} ${selectedWorkPlan.UnitHead.mname.String} ${selectedWorkPlan.UnitHead.lname}`}</p>
                                                            <p className={`w-full text-center`}>{`${selectedWorkPlan.UnitHead.position}`}</p>
                                                        </div>
                                                        <div
                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                            <p className={`w-full text-center`}>{`${selectedWorkPlan.Manager.fname} ${selectedWorkPlan.Manager.mname.String} ${selectedWorkPlan.Manager.lname}`}</p>
                                                            <p className={`w-full text-center`}>{`${selectedWorkPlan.Manager.position}`}</p>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                            <p className={`w-full text-center`}>{`${selectedWorkPlan.UnitHead.fname} ${selectedWorkPlan.UnitHead.mname.String} ${selectedWorkPlan.UnitHead.lname}`}</p>
                                                            <p className={`w-full text-center`}>{`${selectedWorkPlan.UnitHead.position}`}</p>
                                                        </div>
                                                    </>
                                                )
                                            }

                                        </div>

                                        <div
                                            className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid ${selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>
                                            <div
                                                className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                <p className={`w-full text-center`}>{new Date(selectedWorkPlan.created_at).toLocaleDateString("en-GB")}</p>
                                            </div>

                                            {
                                                selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid ? (
                                                    <>
                                                        <div
                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                            {
                                                                selectedWorkPlan.unit_head_approval_at.Valid && (

                                                                    <p className={`w-full text-center`}>{new Date(selectedWorkPlan.unit_head_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                                )
                                                            }
                                                        </div>
                                                        <div
                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                            {
                                                                selectedWorkPlan.manager_approval_at.Valid && (
                                                                    <p className={`w-full text-center`}>{new Date(selectedWorkPlan.manager_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                                )}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                            {
                                                                selectedWorkPlan.unit_head_approval_at.Valid && (

                                                                    <p className={`w-full text-center`}>{new Date(selectedWorkPlan.unit_head_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                                )
                                                            }
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>

                        )
                    }
                </div>
            </div>

        </div>
    );
};
export default SubDepartmentDashboard;