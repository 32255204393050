import axios from "axios";
import {
    createContext,
    FC,
    ReactNode,
    useContext,
    useState,
} from "react";
// import {Employee} from "../services/employee";
import {ReportData} from "../services/reportService";

// Define types for the authentication context
interface ReportEditContextType {
    reportI: ReportData|undefined;
    setReportI: (report: ReportData|undefined) => void;
}

const ReportEditContext = createContext<ReportEditContextType | undefined>(undefined);
const ReportEditProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [report, setReport] = useState<ReportData|undefined>(
        undefined
    );

    const setReportI = (report:ReportData|undefined) => {
        setReport(report)
    }


    // Provide the authentication context to the children components
    return (
        <ReportEditContext.Provider
            value={{
                reportI: report,
                setReportI
            }}
        >
            {children}
        </ReportEditContext.Provider>
    );
};

export const useReportEdit = (): ReportEditContextType => {
    const context = useContext(ReportEditContext);
    if (!context) {
        throw new Error("useReportEdit must be used within an ReportEdit");
    }
    return context;
};

export default ReportEditProvider;
