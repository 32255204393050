import {TaskData} from "../services/taskService";
import {ActivityWRG, ObjectiveWRG} from "../components/employee/employeeGenerateReport";
import {Activity, Objective, Task} from "../components/unit/createUnitWorkPlan";

export type ActivityI = {
    objectiveId: number;
    activityId: number;
    activityName: string;
    tasks: Task[];
}

export const copyWorkPlanRestructure = (data: TaskData[]):Objective[] => {
    // console.log(data)
    let obj = data.map((d) => d.Objective);
    obj = obj.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.id === item.id
            ))
    );


    let act = data.map((d) => d.Activity);

    act = act.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.id === item.id
            ))
    );

    let activities = act.map((a) => {
        let activity:ActivityI = {
            objectiveId: a.objective_id,
            activityId: a.id,
            activityName: a.name,
            tasks: [],

        };
        data.forEach(d => {
            if (a.id === d.activity_id) {
                activity.tasks.push({
                    achieved: d.achieved_percentage,
                    cumulative: d.cumulative_percentage,
                    resource: d.resource,
                    start: d.dead_line,
                    taskName: d.name,
                    unit: d.unit_of_measure,

                });
            }
        });
        return activity;
    });
    // console.log(activities);

    // console.log(objectives)
    return obj.map((ob) => {
        let objective: Objective = {
            objectiveId: ob.id,
            objectiveName: ob.name,
            activities: [],
        };
        activities.forEach(activity => {
            if (ob.id === activity.objectiveId) {
                objective.activities.push(activity);
            }
        });
        return objective;
    });
}