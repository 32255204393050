import {ReportData} from "../services/reportService";
import {ScoreValueMatcher} from "./scoreUtil";

export type ScoresBarGraphStructure = {
    year: number;
    monthlyData: MonthStructure[];
}

export type MonthStructure = {
    monthIndex: number;
    monthName: string;
    value: number|null;
}

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const MothnsIndexer = (month: string): number => {
    switch (month) {
        case 'January':
            return 1;
        case 'February':
            return 2;
        case 'March':
            return 3;
        case 'April':
            return 4;
        case 'May':
            return 5;
        case 'June':
            return 6;
        case 'July':
            return 7;
        case 'August':
            return 8;
        case 'September':
            return 9;
        case 'October':
            return 10;
        case 'November':
            return 11;
        case 'December':
            return 12;
        default:
            return 0;
    }
}

export const yearsExtractor = (reports: ReportData[]): number[] => {
    let years = reports.map((report: ReportData) => {
        return report.year;
    });
    years = years.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t === item
            ))
    );
    return years;
}


export const scoresGraphReStructure = (reports: ReportData[]): ScoresBarGraphStructure[]  => {
    let years = reports.map((report: ReportData) => {
        return report.year;
    });
    years = years.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t === item
            ))
    );

    let results: ScoresBarGraphStructure[] = [];
    years.forEach((year) => {
        let result: ScoresBarGraphStructure = {
            year: year,
            monthlyData: []
        }
        reports.forEach((report: ReportData) => {
            if (report.year === year) {
                let count = 0;
                let sum = report.WorkPlan.Tasks.reduce((acc, cur, index) => {
                    let c = cur.TaskScore.score
                    let conv = ScoreValueMatcher(c);
                    if (!conv) {
                        count += 0;
                        return acc
                    }
                    count ++;
                    return acc+conv;
                }, 0)

                result.monthlyData.push({
                    monthIndex: MothnsIndexer(report.month),
                    monthName: report.month,
                    value: count > 0 ? sum/count : null
                })
            }
        })
        // result = result.monthlyData.sort((a,b) => a.monthIndex-b.monthIndex);
        results.push(result);
    })
    // console.log(results);
    return results;

};