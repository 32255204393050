import axios from "axios";
import {API_URL, X_API_KEY} from "../utils/api";
import {TaskData, TaskReportEditInputs} from "./taskService";
import {EmployeeDB} from "./employee";
import {Header, TableDataFormat, TableFooter, WorkPlanComment, WorkPlanData, WorkPlanPdfGen} from "./workPlanServiice";
import {Department} from "./departmentService";
import {TaskScoreInput} from "./taskScoreService";
import {Unit} from "./unitService";
import {Division} from "./divisionService";
import {SubDepartment} from "./subDepartment";

export type ReportData = {
    id: number;
    report_number: string;
    month: string;
    type: string;
    file: string;
    evidence_file: string;
    year: number;
    work_plan_id: number;
    division_id: {
        Int64: number;
        Valid: boolean
    };
    unit_id: {
        Int64: number;
        Valid: boolean
    };
    sub_department_id: {
        Int64: number;
        Valid: boolean
    };
    department_id: number;
    employee_id: number;
    manager_approval: string;
    unit_head_approval: string;
    sm_approval: string;
    director_approval: string;
    ceo_approval: string;
    is_report_generated: boolean;
    Employee: EmployeeDB;
    Director: EmployeeDB;
    Manager: EmployeeDB;
    UnitHead: EmployeeDB;
    SM: EmployeeDB;
    Ceo: EmployeeDB;
    unit_head_approval_at: {
        Time: string;
        Valid: boolean;
    };
    manager_approval_at: {
        Time: string;
        Valid: boolean;
    };
    sm_approved_at: {
        Time: string;
        Valid: boolean;
    };
    director_approved_at: {
        Time: string;
        Valid: boolean;
    };
    ceo_approved_at: {
        Time: string;
        Valid: boolean;
    };
    WorkPlan: WorkPlanData
    Comments : ReportComment[];
    Department: Department;
    Unit: Unit;
    Division: Division;
    SubDepartment: SubDepartment;
    dead_line: string;
    created_at: string;
};

export type ReportInput = {
    month: string;
    year: number;
    type: string;
    division_id?: number;
    department_id: number;
    user_id: number;
    work_plan_id: number;
    evidence: string;
    tasks: TaskReportEditInputs[];
}

export type ReportEditInputs = {
    id: number;
    month: string;
    year: number;
    type: string;
    division_id?: number;
    evidence?: string;
    unit_id?: number;
    department_id: number;
    sub_department_id?: number;
    unit_head_id?: number;
    user_id: number;
    manager_id?: number;
    tasks: TaskReportEditInputs[];
}

export type ReportApprovalInput = {
    status: string;
    approving_officer_id: number;
    task_scores: TaskScoreInput[];
    comment: string;
}

export type ReportComment = {
    id: number;
    comment: string;
    work_plan_id: number;
    supervisor_id: number;
    is_cleared: boolean;
    created_at: Date;
    Supervisor: EmployeeDB;
};

export const createReport = async (reportInput: ReportInput) => {
    try {
        return await axios
            .post<number>(API_URL + `report`, reportInput, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {
        console.log(err)
    }
}
export const editReport = async (reportEditInput: ReportEditInputs) => {
    try {
        return await axios
            .put<number>(API_URL + `report`, reportEditInput, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {
        console.log(err)
    }
}

export const getReports = async () => {
    try {
        return await axios
            .get<ReportData[]>(API_URL + "report", {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};


export const getReportByEmployeeID = async (param: number) => {
    try {
        return await axios
            .get<ReportData[]>(API_URL + `employee/${param}/report`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {
        console.log(err)
    }
};


export const getReport = async (param: number) => {
    try {
        return await axios
            .get<ReportData>(API_URL + `report/${param}`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {
        console.log(err);
    }
};

export const unitReportApproval = async (param: number, inputs: ReportApprovalInput) => {
    try {
        return await axios.put(API_URL + `report/unitApproval/${param}`, inputs, {
            headers: {
                "X-API-KEY": X_API_KEY
            }
        });
    } catch (err) {
        console.log(err);
    }
}

export const managerReportApproval = async (param: number, inputs: ReportApprovalInput) => {
    try {
        return await axios.put(API_URL + `report/maApproval/${param}`, inputs, {
            headers: {
                "X-API-KEY": X_API_KEY
            }
        });
    } catch (err) {
        console.log(err);
    }
}

export const smReportApproval = async (param: number, inputs: ReportApprovalInput) => {
    try {
        return await axios.put(API_URL + `report/smApproval/${param}`, inputs, {
            headers: {
                "X-API-KEY": X_API_KEY
            }
        });
    } catch (err) {
        console.log(err);
    }
}


export const directorReportApproval = async (param: number, inputs: ReportApprovalInput) => {
    try {
        return await axios.put(API_URL + `report/directorApproval/${param}`, inputs, {
            headers: {
                "X-API-KEY": X_API_KEY
            }
        });
    } catch (err) {
        console.log(err);
    }
}


export const ceoReportApproval = async (param: number, inputs: ReportApprovalInput) => {
    try {
        return await axios.put(API_URL + `report/ceoApproval/${param}`, inputs, {
            headers: {
                "X-API-KEY": X_API_KEY
            }
        });
    } catch (err) {
        console.log(err);
    }
}


export type TableDataReportFormat = {
    index: string;
    objective_name: string;
    activity_name:  string;
    target:        string;
    unit_of_measure: string;
    resource:      string;
    status:      string;
    achieved:      number;
    cumulative:      number;
    remarks:      string;
    supervisor_remarks:      string;
    score:      string;
    score_status:      string;
    bg_color:      string;
    dead_line:      string;
}

export type ReportPdfGen = {
    header: Header;
    data:   TableDataReportFormat[];
    footer: TableFooter;
}

export const uploadEvidence = async (file: FormData) => {
    try {
        return await axios.post<string>(API_URL + `upload/evidence`, file, {
            headers: {
                "X-API-KEY": X_API_KEY
            },
            // withCredentials: true
        });
    } catch (err) {
        console.log(err);
    }
}

export const generateReportPdf = async (param: number, inputs: ReportPdfGen) => {
    try {
        return await axios.put<string>(API_URL + `generate/report/${param}`, inputs, {
            headers: {
                "X-API-KEY": X_API_KEY
            }
        });
    } catch (err) {
        console.log(err);
    }
}

export const generateReport2Pdf = async (param: number, inputs: ReportPdfGen) => {
    try {
        return await axios.put<string>(API_URL + `generate2/report/${param}`, inputs, {
            headers: {
                "X-API-KEY": X_API_KEY
            }
        });
    } catch (err) {
        console.log(err);
    }
}