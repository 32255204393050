import React, {FC, useEffect} from 'react';
import {
    ceoWorkPlanApproval,
    directorWorkPlanApproval,
    getWorkPlanByEmployeeID,
    managerWorkPlanApproval,
    smWorkPlanApproval, unitWorkPlanApproval,
    WorkPlanData
} from "../../services/workPlanServiice";
import {useAuth} from "../../context/authContext";
import {useNavigate} from "react-router-dom";
import {useButtonTypeContext} from "../../context/button/buttonContext";
import {IoPersonCircle} from "react-icons/io5";
import {ButtonType} from "../../context/button/buttonTypes";
import {workPlanReStruct} from "../../utils/workPlanRestruct";
import {EmployeeDB, getEmployee} from "../../services/employee";
import {
    ceoReportApproval,
    directorReportApproval,
    getReportByEmployeeID, managerReportApproval,
    ReportData,
    smReportApproval, unitReportApproval
} from "../../services/reportService";
import {ObjectiveRTR, reportTaskReStructure} from "../../utils/reportTaskReStructure";
import {scoreFilter, statusScoreMatch} from "../../utils/scoreUtil";
import {createTaskScore, TaskScoreInput} from "../../services/taskScoreService";
import {
    months,
    MonthStructure,
    ScoresBarGraphStructure,
    scoresGraphReStructure,
    yearsExtractor
} from "../../utils/scoresGraphReStructure";
import {getUnit, Unit} from "../../services/unitService";
import {PiDotsThree} from "react-icons/pi";
import {FcApproval, FcCancel} from "react-icons/fc";
import {Department, getDepartment} from "../../services/departmentService";
import {Division, getDivison} from "../../services/divisionService";
import {getSubDepartment, SubDepartment} from "../../services/subDepartment";
import {FaEnvelope, FaEye} from "react-icons/fa";
import {RiArrowGoBackLine} from "react-icons/ri";
import PdfRender from "../comps/pdfRender";
import {PDF_HOST} from "../../utils/api";


const ll = [1,2,3,4,5,6,7,8,9,10,11,12]

const UnitSummary:FC<{employee: EmployeeDB}> = ({employee}) => {
    const [refresh, setRefresh] = React.useState(false);
    const [displayPdf, setDisplayPdf] = React.useState(false);
    const [displayPdfJD, setDisplayPdfJD] = React.useState(false);
    const [workPlans, setWorkPlans] = React.useState<WorkPlanData[]>([]);
    const [selectedWorkPlan, setSelectedWorkPlan] = React.useState<WorkPlanData|undefined>(undefined);
    const [currentUnit, setCurrentUnit] = React.useState<Unit|undefined>(undefined);

    const [reports, setReports] = React.useState<ReportData[]>([]);
    const [selectedReport, setSelectedReport] = React.useState<ReportData|undefined>(undefined);
    const [reportRTR, setReportRTR] = React.useState<ObjectiveRTR[]>([]);

    const [graphYears, setGraphYears] = React.useState<number[]>([]);
    const [graphReportYearData, setGraphReportYearData] = React.useState<ScoresBarGraphStructure[]>([]);
    const [selectedGraphYears, setSelectedGraphYears] = React.useState<number|undefined>(undefined);
    const [seelectedGraphYearData, setSeelectedGraphYearData] = React.useState<MonthStructure[]>([]);

    const [approvalComment, setApprovalComment] = React.useState<string>("");
    const [approvalCommentError, setApprovalCommentError] = React.useState<string|undefined>(undefined);
    const [loading, setLoading] = React.useState(false);

   const [currentSup, setCurrentSup] = React.useState<EmployeeDB | undefined>(undefined);
   const [currentSSup, setCurrentSSup] = React.useState<EmployeeDB | undefined>(undefined);
    // const [currentDivision, setCurrentDivision] = React.useState<Division| undefined>(undefined);

    // const [isDivisionHead, setIsDivisionHead] = React.useState<boolean>(false);
    // const [isSubDepartmentHead, setIsSubDepartmentHead] = React.useState<boolean>(false);
    // const [isDepartmentHead, setIsDepartmentHead] = React.useState<boolean>(false);



    const [approvalReportComment, setApprovalReportComment] = React.useState<string>("");
    const [approvalReportCommentError, setApprovalReportCommentError] = React.useState<string|undefined>(undefined);
    const [loadingReport, setReportLoading] = React.useState(false);



    const {employeeI} = useAuth();

    const navigate = useNavigate();


    useEffect(() => {
        if (!employeeI) navigate("/login")
        fetchData().then();
    },[employee, refresh]);

    const handleChangeWorkPlan = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        const workPlan = workPlans.find((workPlan) => workPlan.id === value);
        if (!workPlan) return;
        setSelectedWorkPlan(workPlan);
    }

    const handleChangeGraphYear = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        // console.log(value)
        setSelectedGraphYears(value);
        // console.log(graphReportYearData)
        let dd = graphReportYearData.find((year) => year.year === value);
        if (!dd) return;
        // console.log(dd)
        setSeelectedGraphYearData(dd.monthlyData.sort((a,b) => a.monthIndex - b.monthIndex))
    }

    const handleChangeReport = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        const report = reports.find((report) => report.id === value);
        if (!report) return;
        setSelectedReport(report);
        setReportRTR(reportTaskReStructure(report.WorkPlan.Tasks));
    }

    const handleWorkPlanApproval = async (e: React.MouseEvent<HTMLButtonElement>, status: string) => {
        e.preventDefault();
        if (loading) return;
        setLoading(true);
        if (!employeeI) {
            navigate("/login");
            return;
        }
        if (!selectedWorkPlan) {
            setLoading(false);
            return
        };
        if (approvalComment.length <= 0) {
            setApprovalCommentError("Please comment on your decision!");
            setLoading(false);
            return;
        }
        const input = {
            approving_officer_id: employeeI.user_id,
            comment: approvalComment,
            status: status,
        }
        try {

            if (selectedWorkPlan.Employee.supervisor_l_id.Int64 === employeeI.user_id) {
                const ff = await unitWorkPlanApproval(selectedWorkPlan?.id, input);
                console.log(ff);
                setLoading(false);
                setRefresh(!refresh);
                return;
            } else if (selectedWorkPlan.Employee.s_supervisor_id.Int64 === employeeI.user_id) {
                const ff = await managerWorkPlanApproval(selectedWorkPlan?.id, input);
                console.log(ff);
                setLoading(false);
                setRefresh(!refresh);
                return;
            } else {
                return;
            }

        } catch (e) {
            console.error(e);
        }
    }


    const handleReportApproval = async (e: React.MouseEvent<HTMLButtonElement>, status: string) => {
        e.preventDefault();
        if (!employeeI) {
            navigate("/login");
            return;
        }
        if (!selectedReport) return;
        if (loadingReport) return;
        setReportLoading(true)
        if (approvalReportCommentError && approvalReportComment.length > 0) {
            return;
        }
        if (approvalReportComment.length <= 0) {
            setApprovalReportCommentError("Comment on the Report!");
            setReportLoading(false);
            return;
        }


        try {

            if (employeeI.user_id === employee.supervisor_l_id.Int64) {
                let taskScores: TaskScoreInput[] = [];

                reportRTR.forEach((obj) => {
                    obj.activities.forEach((act) => {
                        act.tasks.forEach( async (task) => {
                            taskScores.push({
                                name: task.taskName,
                                comment: task.taskScore.comment,
                                report_id: selectedReport.id,
                                score: task.taskScore.score,
                                status: task.taskScore.status,
                                task_id: task.taskId,
                                supervisor_id: employeeI.user_id,
                            });
                        })
                    })
                })



                const input = {
                    approving_officer_id: employeeI.user_id,
                    status: status,
                    comment: approvalReportComment,
                    task_scores: taskScores
                }

                const ff = await unitReportApproval(selectedReport.id, input);
                console.log(ff);
                setRefresh(!refresh);
                setReportLoading(false);
                setApprovalReportCommentError(undefined)
                return;
            }else if (employeeI.user_id === employee.s_supervisor_id.Int64) {
                const input = {
                    approving_officer_id: employeeI.user_id,
                    status: status,
                    comment: approvalReportComment,
                    task_scores: []
                }
                const ff = await managerReportApproval(selectedReport.id, input);
                console.log(ff);
                setRefresh(!refresh);
                setReportLoading(false);
                setApprovalReportCommentError(undefined)
                return;
            } else {
                return;
            }

        } catch (e) {
            console.error(e);

        }
    }


    const fetchData = async () => {
        if (!employeeI) {
            navigate("/login");
            return;
        }
        await getWorkPlanByEmployeeID(employee.id).then(res => {
            if (res) {
                setWorkPlans(
                    res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                );
                setSelectedWorkPlan(
                    res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0]
                );
            }
        });
        await getReportByEmployeeID(employee.id).then(res => {
            if (res) {
                setReports(
                    res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                );
                setSelectedReport(
                    res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0]
                );

                if (res.length > 0) {
                    setReportRTR(reportTaskReStructure(res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0].WorkPlan.Tasks));
                    setGraphReportYearData(scoresGraphReStructure(res));
                    setGraphYears(yearsExtractor(res));
                    let yy = yearsExtractor(res);
                    let mm = scoresGraphReStructure(res);
                    let dd = mm.find(m => m.year === yy[0]);
                    if (!dd) {
                        setSeelectedGraphYearData([]);
                        return;
                    }
                    setSeelectedGraphYearData(dd.monthlyData.sort((a,b) => a.monthIndex - b.monthIndex));

                } else {
                    setReportRTR([]);
                    setGraphReportYearData([]);
                    setGraphYears([]);
                    setSeelectedGraphYearData([]);
                }

            }
        });


        if (employee.supervisor_l_id.Valid && employee.supervisor_l_id.Int64 > 0) {
            await getEmployee(employee.supervisor_l_id.Int64).then(res => {
                if (res) {
                    setCurrentSup(res);
                    // console.log(res)
                }
            })
        }

        if (employee.s_supervisor_id.Valid && employee.s_supervisor_id.Int64 > 0) {
            await getEmployee(employee.s_supervisor_id.Int64).then(res => {
                if (res) {
                    setCurrentSSup(res)
                }
            })
        }



        // Always last
        if(!employee.unit_id.Valid) return;
        await getUnit(employee.unit_id.Int64).then(res => {
            if (res) {
                setCurrentUnit(res);
            }
        });
    }


    const handleChangeTaskStatus = (e: React.ChangeEvent<HTMLSelectElement>, objIndex: number, actIndex: number, taskIndex: number) => {
        let objectives = [...reportRTR];
        let objective = objectives[objIndex];
        let activities = [...objective.activities];
        let activity = activities[actIndex];
        let tasks = [...activity.tasks];
        let task = tasks[taskIndex];
        task.taskScore.status = e.target.value;
        task.taskScore.score = statusScoreMatch(e.target.value);
        tasks.splice(taskIndex, 1);
        tasks.splice(taskIndex, 0, task);
        activity.tasks = tasks;
        activities.splice(actIndex, 1);
        activities.splice(actIndex, 0, activity);
        objective.activities=activities;
        // objectives.splice(objIndex, 1);
        // objectives.splice(actIndex, 0, objective);
        setReportRTR(objectives);
    }

    const handleChangeTaskComment = (e: React.ChangeEvent<HTMLInputElement>, objIndex: number, actIndex: number, taskIndex: number) => {
        let objectives = [...reportRTR];
        let objective = objectives[objIndex];
        let activities = [...objective.activities];
        let activity = activities[actIndex];
        let tasks = [...activity.tasks];
        let task = tasks[taskIndex];
        task.taskScore.comment = e.target.value;
        tasks.splice(taskIndex, 1);
        tasks.splice(taskIndex, 0, task);
        activity.tasks = tasks;
        activities.splice(actIndex, 1);
        activities.splice(actIndex, 0, activity);
        objective.activities=activities;
        // objectives.splice(objIndex, 1);
        // objectives.splice(actIndex, 0, objective);
        setReportRTR(objectives);
    }


    // console.log(seelectedGraphYearData)
    // console.log(employee)

    const {setButtonType, setKind} = useButtonTypeContext()
    return (
        <div className={`w-full h-full flex flex-col gap-4 px-4 py-32 relative`}>
            {
                (displayPdf || displayPdfJD) && (
                    <div onClick={() => {
                        setDisplayPdf(false)
                        setDisplayPdfJD(false)
                    }} className={`absolute w-full h-full bg-black/40 z-20`}></div>
                )
            }
            {
                displayPdf && (
                    <div className={`absolute w-full h-full z-20`}>
                        <div className={`w-full h-full relative`}>
                            <div
                                onClick={() => setDisplayPdf(false)}
                                className={`absolute w-fit h-fit p-2 bg-black/40 left-5 top-16 z-20 rounded-full hover:-translate-x-0.5 cursor-pointer`}>
                                <RiArrowGoBackLine className="w-5 h-5  text-white" />
                            </div>
                            {selectedReport && <PdfRender pdf={PDF_HOST + selectedReport.evidence_file} />}

                        </div>
                        {/*Hello*/}
                        {/*{PDF_HOST + selectedWorkPlan?.file}*/}
                    </div>
                )
            }

            {
                displayPdfJD && (
                    <div className={`absolute w-full h-full z-20`}>
                        <div className={`w-full h-full relative`}>
                            <div
                                onClick={() => setDisplayPdfJD(false)}
                                className={`absolute w-fit h-fit p-2 bg-black/40 left-5 top-16 z-20 rounded-full hover:-translate-x-0.5 cursor-pointer`}>
                                <RiArrowGoBackLine className="w-5 h-5  text-white" />
                            </div>
                            {(employee.jd !== undefined && employee.jd.length > 0) && <PdfRender pdf={employee.jd} />}

                        </div>
                        {/*Hello*/}
                        {/*{PDF_HOST + selectedWorkPlan?.file}*/}
                    </div>
                )
            }
            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <div className={`w-fit h-fit flex flex-row gap-4 items-center`}>
                    {
                        !employee?.profile_photo.Valid || employee?.profile_photo.String?.length <= 0 ? (
                            <>
                                <IoPersonCircle className={`text-stone-400 w-32 h-32`}/>
                            </>
                        ) : (
                            <img className={`w-32 h-32 rounded-full`} src={employee?.profile_photo.String}
                                 alt={`${employee.fname[0]}${employee.mname.Valid && employee.mname.String?.length > 0 ? `${employee.mname.String[0]}${employee.lname[0]}` : employee.lname[0]}}`}/>
                        )
                    }

                    <div className={`w-fit h-fit flex flex-col gap-2 text-lg text-[#1c1c1c] font-medium`}>
                        <div className={`w-fit h-fit flex flex-row gap-2 items-center`}>
                            <p>{`${employee.fname} ${employee.mname.String} ${employee.lname}`}</p>

                            {
                                (employee.jd !== undefined && employee.jd.length > 0) && (
                                    <div
                                        onClick={() => setDisplayPdfJD(true)}
                                        className={`w-fit h-fit `}>
                                        <FaEnvelope className={`w-5 h-8 bg-transparent cursor-pointer text-lg font-semibold text-orange-500 hover:text-emerald-500`}/>
                                    </div>

                                )
                            }

                        </div>
                        <p>{employee.position}</p>
                        <p>{employee?.tel}</p>
                        {
                            (employee.iphone !== undefined && employee.iphone.length > 0) && (

                                <p>IP-PHONE: {employee?.iphone}</p>
                            )
                        }
                        {/*<p>Performance Avg: 4.5</p>*/}
                    </div>
                </div>
                <div className={`w-fit h-fit flex flex-col gap-4`}>
                    {/*<p className={`text-[#1c1c1c] text-xl font-semibold`}>{currentUnit && currentUnit.name}*/}
                    {/*        <br/>PERFORMANCE SUMMARY</p>*/}


                    {
                        employee?.supervisor_l_id.Valid && employee.supervisor_l_id.Int64 > 0 ? (
                            <div className={`w-fit h-fit flex flex-row gap-4`}>
                                <p className={`text-gray-500 text-sm font-semibold`}>Supervisor:</p>
                                <p className={`text-gray-800 text-sm font-semibold`}>{`${currentSup?.fname} ${currentSup?.mname.String} ${currentSup?.lname}`}</p>
                            </div>
                        ) : <div></div>
                    }


                    {
                        employee?.s_supervisor_id.Valid && employee.s_supervisor_id.Int64 > 0 ? (
                            <div className={`w-fit h-fit flex flex-row gap-4`}>
                                <p className={`text-gray-500 text-sm font-semibold`}>Supervisor's Supervisor:</p>
                                <p className={`text-gray-800 text-sm font-semibold`}>{`${currentSSup?.fname} ${currentSSup?.mname.String} ${currentSSup?.lname}`}</p>
                            </div>
                        ) : <div></div>
                    }
                </div>
            </div>
            <div
                className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 ${employeeI?.user_id === employee.supervisor_l_id.Int64 || employeeI?.user_id === employee.s_supervisor_id.Int64 ? "flex flex-col justify-between" : "sub_display"} gap-6`}>
                <div className={`w-full h-fit flex flex-row items-center justify-between`}>
                    <p className={`font-medium text-lg text-[#1c1c1c]`}>Overview</p>
                    <select
                        onChange={handleChangeGraphYear}
                        className={`w-fit px-2 py-1.5 outline-0 focus:outline-0 border-0 text-sm font-semibold text-[#1c1c1c]`}>
                        {
                            graphYears.map(year => (
                                <option value={year} key={year}>{year}</option>
                            ))
                        }
                    </select>

                </div>
                <div className={`w-full h-64 flex flex-row justify-start items-end`}>
                    {
                        seelectedGraphYearData.length > 0 && ll.map((n, i) => {
                            let kk = seelectedGraphYearData.find(gf => gf.monthIndex === n)

                            return (
                                <div key={i} className={`w-1/2 h-fit flex flex-col gap-1 items-center`}>
                                    {

                                        kk ? (<div
                                                className={`w-full pt-5 ${kk.value === null ? "h-16 bg-[#E6E6E6]" : kk.value < 0.5 ? "h-5 bg-[#ef5350]" : kk.value >= 0.5 && kk.value < 1.5 ? "h-20 bg-[#fcf3cf]" : kk.value >= 1.5 && kk.value < 2.5 ? "h-32 bg-[#F8E7A5]" : kk.value >= 2.5 && kk.value < 3.5 ? "h-40 bg-[#8DEB8D]" : "h-56 bg-[#65C365]"}`}>
                                                <p className={`text-center text-sm lg:text-lg font-semibold text-[#1c1c1c]`}>{kk.value === null? "-" : kk.value < 0.5 ? "UN" : kk.value >= 0.5 && kk.value < 1.5 ? "AC" : kk.value >= 1.5 && kk.value < 2.5 ? "GO" : kk.value >= 2.5 && kk.value < 3.5 ? "EX" : "OU"}</p>
                                            </div>
                                        ) : ""
                                    }

                                    <p>{months[i].slice(0, 3)}</p>
                                </div>
                            )
                        })

                    }

                </div>
            </div>

            {/*Report*/}
            <div className={`w-full h-fit ${employeeI?.user_id === employee.supervisor_l_id.Int64 || employeeI?.user_id === employee.s_supervisor_id.Int64 ? "flex flex-col" : "sub_display"} gap-4  mt-4`}>
                <div className={`w-full h-fit flex flex-row justify-between items-center`}>
                    {
                        reports.length > 0 ? (
                            <div className={`w-fit h-fit flex flex-row items-center gap-2 lg:gap-4`}>
                                <select
                                    onChange={handleChangeReport}
                                    value={selectedReport?.id}
                                    className={`w-fit px-2 py-1.5 outline-0 focus:outline-0 bg-transparent border-0 text-sm font-semibold text-[#1c1c1c]`}>
                                    {
                                        reports.map((reports, i) => (
                                            <option key={i}
                                                    value={reports.id}>{`${reports.month.slice(0, 3)} - ${reports.year} Report`}</option>
                                        ))
                                    }
                                </select>
                                {
                                    (selectedReport && selectedReport?.evidence_file.length > 0) && (
                                        <div
                                            onClick={() => setDisplayPdf(true)}
                                            className={`w-fit h-fit rounded-full bg-transparent cursor-pointer text-lg font-semibold text-orange-500 hover:text-white hover:bg-orange-500`}>
                                            <FaEye className={`w-6 h-6 `}/>
                                        </div>
                                    )
                                }
                            </div>
                            ) : (
                            <div></div>
                        )
                    }

                    <div className={`w-fit h-fit flex flex-row justify-evenly items-center gap-2`}>
                        <p onClick={() => {
                            setKind("individual")
                            setButtonType(ButtonType.Report)
                        }}
                           className={`w-fit h-fit px-2 py-1.5 bg-transparent cursor-pointer text-sm font-semibold text-[#1c1c1c] rounded-md hover:bg-white`}>View
                            Reports</p>
                        <p onClick={() => {
                            setKind("division")
                            setButtonType(ButtonType.Report)
                        }}
                           className={`w-fit h-fit px-2 py-1.5 bg-transparent cursor-pointer text-sm font-semibold text-[#1c1c1c] rounded-md hover:bg-white`}>View
                            Div Reports</p>
                        {/*<p onClick={() => {*/}
                        {/*    setKind("department")*/}
                        {/*    setButtonType(ButtonType.Report)*/}
                        {/*}}*/}
                        {/*   className={`w-fit h-fit px-2 py-1.5 bg-transparent cursor-pointer text-sm font-semibold text-[#1c1c1c] rounded-md hover:bg-white`}>View*/}
                        {/*    Dep Reports</p>*/}
                    </div>
                </div>

                <div className={`w-full h-fit flex flex-col justify-between gap-4`}>

                    {
                        (selectedReport && reportRTR.length > 0) ? (
                            <>
                                {
                                    reportRTR.map((obj, index) => (

                                        obj.activities.length > 0 ? (
                                            <div key={index} className={`w-full h-fit flex flex-col gap-4  mt-4`}>
                                                <div className={`w-full h-fit flex flex-row justify-between items-center`}>
                                                    <p className={` text-2xl font-semibold text-[#1c1c1c]`}>{obj.objectiveName}</p>
                                                </div>
                                                <div className={`w-full h-fit flex flex-col justify-between gap-4`}>
                                                    <div
                                                        className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2 overflow-x-scroll scrollbar-thumb-rounded-full scrollbar-thin touch-auto scrollbar-thumb-stone-200 scrollbar-track-transparent scroll-pr-4`}>
                                                        <div
                                                            className={`w-[2300px] grid grid-cols-12 text-lg xl:text-xl 2xl:text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle gap-x-4`}>
                                                            <div className={`w-full h-fit col-span-2`}>Activity</div>
                                                            <div className={`w-full h-fit col-span-2`}>Target</div>
                                                            <div className={`w-full h-fit col-span-2`}>Status</div>
                                                            <div className={`w-full h-fit col-span-1`}>%Age Progress</div>
                                                            <div className={`w-full h-fit col-span-2`}>Supervisor's Comments/Remarks</div>
                                                            <div className={`w-full h-fit col-span-2`}>Status</div>
                                                            <div className={`w-full h-fit col-span-1`}>Score</div>
                                                        </div>
                                                        <div className={`w-[2300px] bg-[#999999] py-[1px]`}></div>
                                                        {
                                                            obj.activities.length >= 0 && (
                                                                obj.activities.map((activity, i) => (
                                                                    <div key={i}
                                                                         className={`w-[2300px] h-fit flex flex-col gap-4 justify-center relative`}>
                                                                        <div
                                                                            className={`w-full text-xs lg:text-sm 2xl:text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid grid-cols-12 gap-x-6`}>
                                                                            <div
                                                                                className={`w-full col-span-2 h-fit flex flex-row gap-1 items-center`}>
                                                                                <p>{activity.activityName}</p>
                                                                            </div>
                                                                            {
                                                                                activity.tasks.length <= 0 ?
                                                                                    (
                                                                                        <div
                                                                                            className={`w-full col-span-10 h-fit flex flex-row items-center justify-center`}>
                                                                                            <div className={`w-fit h-fit`}>
                                                                                                <p className={`w-fit h-fit rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-gray-500`}>No
                                                                                                    targets allocated for this
                                                                                                    Activity.</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) :
                                                                                    (
                                                                                        <div
                                                                                            className={`w-full col-span-10 h-fit flex flex-col gap-4`}>
                                                                                            {
                                                                                                activity.tasks.map((task, j) => (
                                                                                                    task.onReport && (

                                                                                                        <div key={j}
                                                                                                             className={`w-full h-fit justify-items-start text-wrap align-middle grid grid-cols-10 gap-x-6`}>
                                                                                                            <div
                                                                                                                className={`w-full h-fit col-span-2 flex flex-row gap-1 items-center`}>
                                                                                                                {task.taskName}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                                <p className={``}>{task.status}</p>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className={`w-full h-fit col-span-1 flex flex-col gap-2`}>
                                                                                                                <p className={``}>{task.cumulative}</p>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className={`w-full text-wrap h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                                <input
                                                                                                                    disabled={
                                                                                                                        (employeeI?.user_id === employee.id) ||
                                                                                                                        (employee.supervisor_l_id.Int64 !== employeeI?.user_id)
                                                                                                                    }
                                                                                                                    required={true}
                                                                                                                    type={"text"}
                                                                                                                    onChange={(e) => handleChangeTaskComment(e, index, i, j)}
                                                                                                                    value={task.taskScore.comment}
                                                                                                                    className={`w-full text-wrap text-center py-1.5 px-2 bg-stone-50 outline-0 focus:outline-none focus:ring-0`}/>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                                <select
                                                                                                                    disabled={
                                                                                                                        (employeeI?.user_id === employee.id) ||
                                                                                                                        (employee.supervisor_l_id.Int64 !== employeeI?.user_id)
                                                                                                                    }
                                                                                                                    onChange={(e) => handleChangeTaskStatus(e, index, i, j)}
                                                                                                                    value={task.taskScore.status}
                                                                                                                    className={`w-full px-2 py-1.5 outline-0 focus:outline-0 bg-transparent border-0 text-sm font-semibold text-[#1c1c1c]`}>
                                                                                                                    <option value={"not_applicable"}>Not Applicable</option>
                                                                                                                    <option value={"committee"}>Presented to and approved by the relevant committees [100% Complete]</option>
                                                                                                                    <option value={"head"}>Presented to and approved by the Head of Department [75% Complete]</option>
                                                                                                                    <option value={"draft"}>Presented in draft format, no review has been undertaken [50% Complete]</option>
                                                                                                                    <option value={"infancy"}>Key deliverables still in infancy stage [Outline Format- 25% Complete]</option>
                                                                                                                    <option value={"no_evidence"}>No evidence of Work on the Key Deliverables [0%]</option>
                                                                                                                    <option value={"delayed"}>Activity Delayed</option>
                                                                                                                </select>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className={`w-full h-fit col-span-1 flex flex-col gap-2`}>
                                                                                                            <span
                                                                                                                className={`min-w-32 w-fit text-center rounded-full min-h-8 h-fit py-1.5 px-4 ${scoreFilter(task.taskScore.score)} font-semibold`}>
                                                                                                                {task.taskScore.id <= 0 && task.taskScore.status.length <= 0 ? "" : task.taskScore.score}
                                                                                                            </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )

                                                                                                ))
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                            }
                                                                        </div>

                                                                        <div
                                                                            className={`w-full flex flex-row items-center`}>
                                                                            <div
                                                                                className={`w-[2300px] h-0.5 bg-[#999999] py-[1px]`}></div>
                                                                        </div>


                                                                    </div>
                                                                ))
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ("")


                                    ))
                                }


                                {
                                    employee.id === employeeI?.user_id ? (
                                        <div>
                                            Hi manager
                                        </div>
                                    ) : selectedReport.unit_head_approval === "pending" && employeeI?.user_id === selectedReport.Employee.supervisor_l_id.Int64  ?
                                        (
                                            <div
                                                className={`w-full h-fit flex flex-col gap-4 items-end justify-center`}>
                                                <div className={`w-72 h-fit flex flex-col gap-2`}>
                                                    <label htmlFor="commentReport"
                                                           className="block text-sm/6 font-medium text-gray-900">
                                                        Comment
                                                    </label>
                                                    <div className="flex flex-col gap-0.5">
                                                        {approvalReportCommentError && (
                                                            <small
                                                                className={`text-red-500 font-semibold`}>{approvalReportCommentError}</small>
                                                        )}
                                                        <textarea
                                                            id="commentReport"
                                                            name="comment"
                                                            rows={3}
                                                            value={approvalReportComment}
                                                            required={true}
                                                            onChange={(e) => {
                                                                setApprovalReportComment(e.target.value);
                                                                if (e.target.value === "") {
                                                                    setApprovalReportCommentError("Please comment on your decision!");
                                                                    return;
                                                                }
                                                                setApprovalReportCommentError("");
                                                            }}
                                                            className={`block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6`}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className={`w-full h-fit flex flex-row gap-4 justify-end items-center pr-4`}>
                                                    <button disabled={loadingReport}
                                                            onClick={(e) => handleReportApproval(e, "approved")}
                                                            className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>
                                                        {
                                                            loadingReport ? "Submitting..." : "Approve"
                                                        }

                                                    </button>
                                                    <button disabled={loadingReport}
                                                            onClick={(e) => handleReportApproval(e, "rejected")}
                                                            className={`flex w-fit  justify-center rounded-md bg-transparent px-3 py-1.5 text-sm font-semibold hover:text-red-500 hover:ring-2 hover:ring-red-500 leading-6 text-black hover:shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>
                                                        {
                                                            loadingReport ? "Submitting..." : "Reject"
                                                        }

                                                    </button>
                                                </div>

                                            </div>
                                        ) : selectedReport.manager_approval === "pending" && employeeI?.user_id === selectedReport.Employee.s_supervisor_id.Int64 && selectedReport.unit_head_approval === "approved" ?
                                            (
                                                <div
                                                    className={`w-full h-fit flex flex-col gap-4 items-end justify-center`}>
                                                    <div className={`w-72 h-fit flex flex-col gap-2`}>
                                                        <label htmlFor="commentReport"
                                                               className="block text-sm/6 font-medium text-gray-900">
                                                            Comment
                                                        </label>
                                                        <div className="flex flex-col gap-0.5">
                                                            {approvalReportCommentError && (
                                                                <small
                                                                    className={`text-red-500 font-semibold`}>{approvalReportCommentError}</small>
                                                            )}
                                                            <textarea
                                                                id="commentReport"
                                                                name="comment"
                                                                rows={3}
                                                                value={approvalReportComment}
                                                                required={true}
                                                                onChange={(e) => {
                                                                    setApprovalReportComment(e.target.value);
                                                                    if (e.target.value === "") {
                                                                        setApprovalReportCommentError("Please comment on your decision!");
                                                                        return;
                                                                    }
                                                                    setApprovalReportCommentError("");
                                                                }}
                                                                className={`block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`w-full h-fit flex flex-row gap-4 justify-end items-center pr-4`}>
                                                        <button disabled={loadingReport}
                                                                onClick={(e) => handleReportApproval(e, "approved")}
                                                                className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>
                                                            {
                                                                loadingReport ? "Submitting..." : "Approve"
                                                            }

                                                        </button>
                                                        <button disabled={loadingReport}
                                                                onClick={(e) => handleReportApproval(e, "rejected")}
                                                                className={`flex w-fit  justify-center rounded-md bg-transparent px-3 py-1.5 text-sm font-semibold hover:text-red-500 hover:ring-2 hover:ring-red-500 leading-6 text-black hover:shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>
                                                            {
                                                                loadingReport ? "Submitting..." : "Reject"
                                                            }

                                                        </button>
                                                    </div>
                                                </div>
                                            ) :
                                            (

                                                <div
                                                    className={`w-full h-fit flex flex-col justify-between gap-4 mt-16`}>
                                                    <div
                                                        className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>
                                                        <div
                                                            className={`text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle grid ${selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>
                                                            <div
                                                                className={`w-full h-fit col-span-1 text-center`}>Prepared
                                                                By
                                                            </div>


                                                            {
                                                                selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid ? (
                                                                    <>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 text-center relative`}>
                                                                            {
                                                                                selectedReport.unit_head_approval === "rejected" ? (
                                                                                    <div
                                                                                        className={`absolute bottom-3 right-20`}>
                                                                                        <FcCancel
                                                                                            className={`w-5 h-5`}/>
                                                                                    </div>
                                                                                ) : selectedReport.unit_head_approval === "approved" ? (
                                                                                    <div
                                                                                        className={`absolute bottom-3 right-20`}>
                                                                                        <FcApproval
                                                                                            className={`w-5 h-5`}/>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        className={`absolute bottom-3 right-20`}>
                                                                                        <PiDotsThree
                                                                                            className={`w-5 h-5 text-gray-500`}/>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            Checked By
                                                                        </div>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 text-center relative`}>
                                                                            {
                                                                                selectedReport.manager_approval === "rejected" ? (
                                                                                    <div
                                                                                        className={`absolute bottom-3 right-16`}>
                                                                                        <FcCancel
                                                                                            className={`w-5 h-5`}/>
                                                                                    </div>
                                                                                ) : selectedReport.manager_approval === "approved" ? (
                                                                                    <div
                                                                                        className={`absolute bottom-3 right-16`}>
                                                                                        <FcApproval
                                                                                            className={`w-5 h-5`}/>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        className={`absolute bottom-3 right-16`}>
                                                                                        <PiDotsThree
                                                                                            className={`w-5 h-5 text-gray-500`}/>
                                                                                    </div>
                                                                                )
                                                                            }Approved By
                                                                        </div>
                                                                    </>
                                                                ) : (

                                                                    <>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 text-center relative`}>
                                                                            {
                                                                                selectedReport.unit_head_approval === "rejected" ? (
                                                                                    <div
                                                                                        className={`absolute bottom-3 right-20`}>
                                                                                        <FcCancel
                                                                                            className={`w-5 h-5`}/>
                                                                                    </div>
                                                                                ) : selectedReport.unit_head_approval === "approved" ? (
                                                                                    <div
                                                                                        className={`absolute bottom-3 right-20`}>
                                                                                        <FcApproval
                                                                                            className={`w-5 h-5`}/>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        className={`absolute bottom-3 right-20`}>
                                                                                        <PiDotsThree
                                                                                            className={`w-5 h-5 text-gray-500`}/>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            Checked & Approved By
                                                                        </div>
                                                                    </>
                                                                )
                                                            }


                                                        </div>
                                                        <div
                                                            className={`w-full bg-[#999999] py-[1px]`}></div>
                                                        <div
                                                            className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid ${selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>

                                                            <div
                                                                className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                <p className={`w-full text-center`}>{`${selectedReport.Employee.fname} ${selectedReport.Employee.mname.String} ${selectedReport.Employee.lname}`}</p>
                                                                <p className={`w-full text-center`}>{`${selectedReport.Employee.position}`}</p>
                                                            </div>

                                                            {
                                                                selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid ? (
                                                                    <>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                            <p className={`w-full text-center`}>{`${selectedReport.UnitHead.fname} ${selectedReport.UnitHead.mname.String} ${selectedReport.UnitHead.lname}`}</p>
                                                                            <p className={`w-full text-center`}>{`${selectedReport.UnitHead.position}`}</p>
                                                                        </div>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                            <p className={`w-full text-center`}>{`${selectedReport.Manager.fname} ${selectedReport.Manager.mname.String} ${selectedReport.Manager.lname}`}</p>
                                                                            <p className={`w-full text-center`}>{`${selectedReport.Manager.position}`}</p>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                            <p className={`w-full text-center`}>{`${selectedReport.UnitHead.fname} ${selectedReport.UnitHead.mname.String} ${selectedReport.UnitHead.lname}`}</p>
                                                                            <p className={`w-full text-center`}>{`${selectedReport.UnitHead.position}`}</p>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }

                                                        </div>

                                                        <div
                                                            className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid ${selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>
                                                            <div
                                                                className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                <p className={`w-full text-center`}>{new Date(selectedReport.created_at).toLocaleDateString("en-GB")}</p>
                                                            </div>

                                                            {
                                                                selectedReport.Employee.supervisor_l_id.Valid && selectedReport.Employee.s_supervisor_id.Valid ? (
                                                                    <>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                            {
                                                                                selectedReport.unit_head_approval_at.Valid && (

                                                                                    <p className={`w-full text-center`}>{new Date(selectedReport.unit_head_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                            {
                                                                                selectedReport.manager_approval_at.Valid && (
                                                                                    <p className={`w-full text-center`}>{new Date(selectedReport.manager_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                                                )}
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                            {
                                                                                selectedReport.unit_head_approval_at.Valid && (

                                                                                    <p className={`w-full text-center`}>{new Date(selectedReport.unit_head_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                }


                            </>

                        ) : (
                            <div className={`w-full h-full flex flex-row items-center justify-center mb-8`}>
                                <p className={`text-center text-wrap text-sm font-semibold text-gray-500`}>No reports
                                    generated on yet! Or still loading...</p>
                            </div>
                        )
                    }
                </div>

            </div>

            {/*Work Plan*/}
            <div className={`w-full h-fit ${employeeI?.user_id === employee.supervisor_l_id.Int64 || employeeI?.user_id === employee.s_supervisor_id.Int64 ? "flex flex-col" : "sub_display"} gap-4  mt-10`}>
                <div className={`w-full h-fit flex flex-row justify-between items-center`}>
                    {
                        workPlans.length > 0 ? (

                            <select
                                onChange={handleChangeWorkPlan}
                                className={`w-fit px-2 py-1.5 outline-0 focus:outline-0 bg-transparent border-0 text-sm font-semibold text-[#1c1c1c]`}>
                                {
                                    workPlans.map((workPlan: WorkPlanData, i) => (
                                        <option key={i}
                                                value={workPlan.id}>{`${workPlan.month.slice(0, 3)} - ${workPlan.year} Work Plan`}</option>
                                    ))
                                }
                            </select>
                        ) : <div></div>
                    }
                    <div className={`w-fit h-fit flex flex-row  justify-evenly items-center gap-2`}>
                        <p onClick={() => {
                            setKind("individual")
                            setButtonType(ButtonType.WorkPlan)
                        }}
                           className={`w-fit h-fit px-2 py-1.5 bg-transparent cursor-pointer text-sm font-semibold text-[#1c1c1c] rounded-md hover:bg-white`}>View
                            WorkPlans</p>
                        <p onClick={() => {
                            setKind("division")
                            setButtonType(ButtonType.WorkPlan)
                        }}
                           className={`w-fit h-fit px-2 py-1.5 bg-transparent cursor-pointer text-sm font-semibold text-[#1c1c1c] rounded-md hover:bg-white`}>View
                            Div WorkPlans</p>
                        <p onClick={() => {
                            setKind("department")
                            setButtonType(ButtonType.WorkPlan)
                        }}
                           className={`w-fit h-fit px-2 py-1.5 bg-transparent cursor-pointer text-sm font-semibold text-[#1c1c1c] rounded-md hover:bg-white`}>View
                            Dep WorkPlans</p>
                    </div>
                </div>
                <div className={`w-full h-fit flex flex-col justify-between gap-4`}>

                    {
                        selectedWorkPlan ? (
                            <>
                                {
                                    workPlanReStruct(selectedWorkPlan.Tasks).map((obj, index) => (

                                        <div key={index} className={`w-full h-fit flex flex-col gap-4  mt-4`}>
                                            {/*<div className={`w-full h-fit flex flex-row justify-between items-center`}>*/}
                                            {/*    <p className={` text-2xl font-semibold text-[#1c1c1c]`}>{obj.objectiveName}</p>*/}
                                            {/*</div>*/}
                                            <div className={`w-full h-fit flex flex-col justify-between gap-4`}>
                                                <div
                                                    className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>
                                                    <div
                                                        className={`text-lg xl:text-xl 2xl:text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle flex flex-row gap-x-4`}>
                                                        <div className={`w-1/4 h-fit`}>{obj.objectiveName}</div>
                                                        <div
                                                            className={`w-3/4 h-fit justify-items-start text-wrap align-middle grid grid-cols-9 gap-x-4`}>
                                                            <div className={`w-full h-fit col-span-3`}>Target</div>
                                                            <div className={`w-full h-fit col-span-2`}>Unit of Measure
                                                            </div>
                                                            <div
                                                                className={`w-full h-fit col-span-2 text-center`}>Resource
                                                            </div>
                                                            <div
                                                                className={`w-full h-fit col-span-2 text-center`}>Timeline
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`w-full bg-[#999999] py-[1px]`}></div>
                                                    {
                                                        obj.activities.length >= 0 && (
                                                            obj.activities.map((activity, i) => (
                                                                <div key={i}
                                                                     className={`w-full h-fit flex flex-col gap-4 justify-center relative`}>
                                                                    <div
                                                                        className={`text-xs lg:text-sm 2xl:text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle flex flex-row gap-x-6`}>
                                                                        <div
                                                                            className={`w-1/4 h-fit flex flex-row gap-1 items-center`}>
                                                                            <p>{activity.activityName}</p>
                                                                        </div>
                                                                        {
                                                                            activity.tasks.length <= 0 ?
                                                                                (
                                                                                    <div
                                                                                        className={`w-3/4 h-fit flex flex-row items-center justify-center`}>
                                                                                        <div className={`w-fit h-fit`}>
                                                                                            <p className={`w-fit h-fit rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-gray-500`}>No
                                                                                                targets a located for this
                                                                                                Activity.</p>
                                                                                        </div>
                                                                                    </div>
                                                                                ) :
                                                                                (
                                                                                    <div
                                                                                        className={`w-3/4 h-fit flex flex-col gap-4`}>
                                                                                        {
                                                                                            activity.tasks.map((task, i) => (

                                                                                                <div key={i}
                                                                                                     className={`w-full h-fit justify-items-start text-wrap align-middle grid grid-cols-9 gap-x-4`}>
                                                                                                    <div
                                                                                                        className={`w-full h-fit col-span-3 flex flex-row gap-1 items-center`}>
                                                                                                        {task.name}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                        <p className={`w-full text-center`}>{task.unit_of_measure}</p>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                        <p className={`w-full text-center`}>{task.resource}</p>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={`w-full h-fit col-span-2 flex flex-col gap-2 relative`}>
                                                                                                        <p className={`w-full text-center`}>{new Date(task.dead_line).toLocaleDateString("en-GB")}</p>
                                                                                                        <div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                        }
                                                                    </div>

                                                                    <div
                                                                        className={`w-full flex flex-row items-center`}>
                                                                        <div
                                                                            className={`w-full h-0.5 bg-[#999999] py-[1px]`}></div>
                                                                    </div>


                                                                </div>
                                                            ))
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                                    {
                                        employee.id === employeeI?.user_id ? (
                                            <div>
                                                Hi manager
                                            </div>
                                        ) : selectedWorkPlan.unit_head_approval === "pending" && employeeI?.user_id === selectedWorkPlan.Employee.supervisor_l_id.Int64 ?
                                            (
                                                <div
                                                    className={`w-full h-fit flex flex-col gap-4 items-end justify-center`}>
                                                    <div className={`w-72 h-fit flex flex-col gap-2`}>
                                                        <label htmlFor="comment"
                                                               className="block text-sm/6 font-medium text-gray-900">
                                                            Comment
                                                        </label>
                                                        <div className="flex flex-col gap-0.5">
                                                            {approvalCommentError && (
                                                                <small
                                                                    className={`text-red-500 font-semibold`}>{approvalCommentError}</small>
                                                            )}
                                                            <textarea
                                                                id="comment"
                                                                name="comment"
                                                                rows={3}
                                                                value={approvalComment}
                                                                required={true}
                                                                onChange={(e) => {
                                                                    setApprovalComment(e.target.value);
                                                                    if (e.target.value === "") {
                                                                        setApprovalCommentError("Please comment on your decision!");
                                                                        return;
                                                                    }
                                                                    setApprovalCommentError("");
                                                                }}
                                                                className={`block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`w-full h-fit flex flex-row gap-4 justify-end items-center pr-4`}>
                                                        <button disabled={loading}
                                                                onClick={(e) => handleWorkPlanApproval(e, "approved")}
                                                                className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>
                                                            {
                                                                loading ? "Submitting..." : "Approve"
                                                            }

                                                        </button>
                                                        <button disabled={loading}
                                                                onClick={(e) => handleWorkPlanApproval(e, "rejected")}
                                                                className={`flex w-fit  justify-center rounded-md bg-transparent px-3 py-1.5 text-sm font-semibold hover:text-red-500 hover:ring-2 hover:ring-red-500 leading-6 text-black hover:shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>
                                                            {
                                                                loading ? "Submitting..." : "Reject"
                                                            }

                                                        </button>
                                                    </div>

                                                </div>
                                            ) : selectedWorkPlan.unit_head_approval === "approved" && selectedWorkPlan.manager_approval === "pending" && employeeI?.user_id === selectedWorkPlan.Employee.s_supervisor_id.Int64 ?
                                                (
                                                    <div
                                                        className={`w-full h-fit flex flex-col gap-4 items-end justify-center`}>
                                                        <div className={`w-72 h-fit flex flex-col gap-2`}>
                                                            <label htmlFor="comment"
                                                                   className="block text-sm/6 font-medium text-gray-900">
                                                            Comment
                                                        </label>
                                                        <div className="flex flex-col gap-0.5">
                                                            {approvalCommentError && (
                                                                <small
                                                                    className={`text-red-500 font-semibold`}>{approvalCommentError}</small>
                                                            )}
                                                            <textarea
                                                                id="comment"
                                                                name="comment"
                                                                rows={3}
                                                                value={approvalComment}
                                                                required={true}
                                                                onChange={(e) => {
                                                                    setApprovalComment(e.target.value);
                                                                    if (e.target.value === "") {
                                                                        setApprovalCommentError("Please comment on your decision!");
                                                                        return;
                                                                    }
                                                                    setApprovalCommentError("");
                                                                }}
                                                                className={`block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`w-full h-fit flex flex-row gap-4 justify-end items-center pr-4`}>
                                                        <button disabled={loading}
                                                                onClick={(e) => handleWorkPlanApproval(e, "approved")}
                                                                className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>
                                                            {
                                                                loading ? "Submitting..." : "Approve"
                                                            }

                                                        </button>
                                                        <button disabled={loading}
                                                                onClick={(e) => handleWorkPlanApproval(e, "rejected")}
                                                                className={`flex w-fit  justify-center rounded-md bg-transparent px-3 py-1.5 text-sm font-semibold hover:text-red-500 hover:ring-2 hover:ring-red-500 leading-6 text-black hover:shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>
                                                            {
                                                                loading ? "Submitting..." : "Reject"
                                                            }

                                                        </button>
                                                    </div>

                                                </div>
                                            ) :
                                            (

                                                <div
                                                    className={`w-full h-fit flex flex-col justify-between gap-4 mt-16`}>
                                                    <div
                                                        className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>
                                                        <div
                                                            className={`text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle grid ${selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>
                                                            <div
                                                                className={`w-full h-fit col-span-1 text-center`}>Prepared
                                                                By
                                                            </div>


                                                            {
                                                                selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid ? (
                                                                    <>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 text-center relative`}>
                                                                            {
                                                                                selectedWorkPlan.unit_head_approval === "rejected" ? (
                                                                                    <div
                                                                                        className={`absolute bottom-3 right-20`}>
                                                                                        <FcCancel
                                                                                            className={`w-5 h-5`}/>
                                                                                    </div>
                                                                                ) : selectedWorkPlan.unit_head_approval === "approved" ? (
                                                                                    <div
                                                                                        className={`absolute bottom-3 right-20`}>
                                                                                        <FcApproval
                                                                                            className={`w-5 h-5`}/>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        className={`absolute bottom-3 right-20`}>
                                                                                        <PiDotsThree
                                                                                            className={`w-5 h-5 text-gray-500`}/>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            Checked By
                                                                        </div>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 text-center relative`}>
                                                                            {
                                                                                selectedWorkPlan.manager_approval === "rejected" ? (
                                                                                    <div
                                                                                        className={`absolute bottom-3 right-16`}>
                                                                                        <FcCancel
                                                                                            className={`w-5 h-5`}/>
                                                                                    </div>
                                                                                ) : selectedWorkPlan.manager_approval === "approved" ? (
                                                                                    <div
                                                                                        className={`absolute bottom-3 right-16`}>
                                                                                        <FcApproval
                                                                                            className={`w-5 h-5`}/>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        className={`absolute bottom-3 right-16`}>
                                                                                        <PiDotsThree
                                                                                            className={`w-5 h-5 text-gray-500`}/>
                                                                                    </div>
                                                                                )
                                                                            }Approved By
                                                                        </div>
                                                                    </>
                                                                ) : (

                                                                    <>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 text-center relative`}>
                                                                            {
                                                                                selectedWorkPlan.unit_head_approval === "rejected" ? (
                                                                                    <div
                                                                                        className={`absolute bottom-3 right-20`}>
                                                                                        <FcCancel
                                                                                            className={`w-5 h-5`}/>
                                                                                    </div>
                                                                                ) : selectedWorkPlan.unit_head_approval === "approved" ? (
                                                                                    <div
                                                                                        className={`absolute bottom-3 right-20`}>
                                                                                        <FcApproval
                                                                                            className={`w-5 h-5`}/>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        className={`absolute bottom-3 right-20`}>
                                                                                        <PiDotsThree
                                                                                            className={`w-5 h-5 text-gray-500`}/>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            Checked & Approved By
                                                                        </div>
                                                                    </>
                                                                )
                                                            }


                                                        </div>
                                                        <div
                                                            className={`w-full bg-[#999999] py-[1px]`}></div>
                                                        <div
                                                            className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid ${selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>

                                                            <div
                                                                className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                <p className={`w-full text-center`}>{`${selectedWorkPlan.Employee.fname} ${selectedWorkPlan.Employee.mname.String} ${selectedWorkPlan.Employee.lname}`}</p>
                                                                <p className={`w-full text-center`}>{`${selectedWorkPlan.Employee.position}`}</p>
                                                            </div>

                                                            {
                                                                selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid ? (
                                                                    <>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                            <p className={`w-full text-center`}>{`${selectedWorkPlan.UnitHead.fname} ${selectedWorkPlan.UnitHead.mname.String} ${selectedWorkPlan.UnitHead.lname}`}</p>
                                                                            <p className={`w-full text-center`}>{`${selectedWorkPlan.UnitHead.position}`}</p>
                                                                        </div>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                            <p className={`w-full text-center`}>{`${selectedWorkPlan.Manager.fname} ${selectedWorkPlan.Manager.mname.String} ${selectedWorkPlan.Manager.lname}`}</p>
                                                                            <p className={`w-full text-center`}>{`${selectedWorkPlan.Manager.position}`}</p>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                            <p className={`w-full text-center`}>{`${selectedWorkPlan.UnitHead.fname} ${selectedWorkPlan.UnitHead.mname.String} ${selectedWorkPlan.UnitHead.lname}`}</p>
                                                                            <p className={`w-full text-center`}>{`${selectedWorkPlan.UnitHead.position}`}</p>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }

                                                        </div>

                                                        <div
                                                            className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid ${selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>
                                                            <div
                                                                className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                <p className={`w-full text-center`}>{new Date(selectedWorkPlan.created_at).toLocaleDateString("en-GB")}</p>
                                                            </div>

                                                            {
                                                                selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid ? (
                                                                    <>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                            {
                                                                                selectedWorkPlan.unit_head_approval_at.Valid && (

                                                                                    <p className={`w-full text-center`}>{new Date(selectedWorkPlan.unit_head_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                            {
                                                                                selectedWorkPlan.manager_approval_at.Valid && (
                                                                                    <p className={`w-full text-center`}>{new Date(selectedWorkPlan.manager_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                                                )}
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                            {
                                                                                selectedWorkPlan.unit_head_approval_at.Valid && (

                                                                                    <p className={`w-full text-center`}>{new Date(selectedWorkPlan.unit_head_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                }
                            </>

                        ) : (
                            <div className={`w-full h-full flex flex-row items-center justify-center mb-8`}>
                                <p className={`text-center text-wrap text-sm font-semibold text-gray-500`}>No workplans
                                    made on yet! Or still loading...</p>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default UnitSummary;