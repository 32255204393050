import React, {useEffect, useState} from 'react';
import {useWorkPlanToReportGen} from "../../context/workPlanToReport/workPlanToReportGenContext";
import {ObjectiveWRG} from "../employee/employeeGenerateReport";
import {useAuth} from "../../context/authContext";
import {useButtonTypeContext} from "../../context/button/buttonContext";
import {getObjectives, ObjectiveData} from "../../services/objectiveService";
import {ActivityData, createActivityDB, getActivities} from "../../services/activityService";
import {workPlanReportGenRestructure} from "../../utils/workPlanReportGenRestructure";
import {createTask, deleteTask} from "../../services/taskService";
import {editWorkPlan, WorkPlanEditInputs} from "../../services/workPlanServiice";
import {ButtonType} from "../../context/button/buttonTypes";
import {FiPlusCircle} from "react-icons/fi";
import {CiSquareMinus} from "react-icons/ci";
import {BsClipboard2Plus} from "react-icons/bs";
import {MdOutlineCancel} from "react-icons/md";
import {IoPersonCircle} from "react-icons/io5";

const SubDepartmentWorkPlanEdit = () => {const {workPlanI} = useWorkPlanToReportGen();
    const [workPlan, setWorkPlan] = React.useState<ObjectiveWRG[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [deleteAction, setDeleteAction] = useState<boolean>(false);

    const {employeeI} = useAuth();
    const {setButtonType, setKind} = useButtonTypeContext()


    const [toggleObjective, setToggleObjective] = useState<boolean>(false);
    const [toggleActivity, setToggleActivity] = useState<boolean>(false);
    const [toggleTask, setToggleTask] = useState<boolean>(false);
    const [toggledActivityObjective, setToggledActivityObjective] = useState<number|null>(null);
    const [toggledTaskObjective, setToggledTaskObjective] = useState<number|null>(null);

    const [selectedObjective, setSelectedObjective] = useState<ObjectiveData | undefined>(undefined);
    const [selectedObjectiveId, setSelectedObjectiveId] = useState<number | undefined>(undefined);
    const [toggledTaskActivity, setToggledTaskActivity] = useState<number|null>(null);

    const [selectedActivity, setSelectedActivity] = useState<ActivityData | undefined>(undefined);
    const [selectedActivityId, setSelectedActivityId] = useState<number | undefined>(undefined);

    const [target, setTarget] = useState<string>("");
    const [targetError, setTargetError] = useState<string>("");
    const [unit, setUnit] = useState<string>("");
    const [unitError, setUnitError] = useState<string>("");
    const [resource, setResource] = useState<string>("");
    const [resourceError, setResourceError] = useState<string>("");
    const [start, setStart] = useState<string>("");
    const [startError, setStartError] = useState<string>("");
    const [cumulative, setCumulative] = useState<string>("");
    const [cumulativeError, setCumulativeError] = useState<string>("");
    const [achieved, setAchieved] = useState<string>("");
    const [achievedError, setAchievedError] = useState<string>("");

    const [objectives, setObjectives] = useState<ObjectiveData[]>([]);
    const [activities, setActivities] = useState<ActivityData[]>([]);

    useEffect(() => {
        if (!workPlanI){
            return;
        }
        setWorkPlan(workPlanReportGenRestructure(workPlanI.Tasks));
        fetchData().then();
    }, []);


    const fetchData = async () => {
        await getObjectives().then(res => {
            if (res) {
                // console.log(res)
                setObjectives(res.filter(objective => !objective.is_complete));
            }
        }).catch(e => console.log(e));
        await getActivities().then(res => {
            if (res) {
                setActivities(res.filter(res => {
                    if (!res.is_complete) {
                        return !(res.employee_id.Valid && res.employee_id.Int64 !== employeeI?.user_id);

                    }
                    return false
                }).sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()))
            }
        }).catch(e => console.log(e));
    }

    const handleAddTask = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!toggledTaskObjective) return;
        if (!toggledTaskActivity) return;

        if(target.length <= 0) {
            setTargetError("Enter target value");
            return;
        }
        setTargetError("");


        if ( /[a-zA-Z]/.test(cumulative) ) return;
        let cum = parseInt(cumulative)
        if (isNaN(cum)) {
            cum = 0;
        }
        if(cumulative.length > 0 && !cum) {
            setCumulativeError("Value has to be a number");
            return;
        }
        setCumulativeError("");

        if ( /[a-zA-Z]/.test(achieved) ) return;
        let ach = parseInt(achieved)
        if (isNaN(ach)) {
            ach = 0;
        }
        if(achieved.length > 0 && !ach) {
            setAchievedError("Value has to be a number");
            return;
        }
        setAchievedError("");

        if(unit.length <= 0) {
            setUnitError("Enter unit of measure (KPI) value");
            return;
        }
        setUnitError("");

        if(resource.length <= 0) {
            setResourceError("Enter resources value");
            return;
        }
        setResourceError("");

        if(start.length <= 0) {
            setStartError("Enter deadline value");
            return;
        }
        setStartError("");


        addTask(target, unit, resource, start, toggledTaskObjective, toggledTaskActivity, cum, ach).then();

        setToggledTaskActivity(null);
        setToggledTaskObjective(null);
        setToggleTask(false);
        setTargetError("");
        setTarget("");
        setCumulative("");
        setCumulativeError("");
        setAchieved("");
        setAchievedError("");
        setUnit("");
        setUnitError("");
        setResource("");
        setResourceError("");
        setStart("");
        setStartError("");
    }


    const handleObjectiveChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        setSelectedObjectiveId(value);
        const objective = objectives.find((objective) => objective.id === value);
        if (!objective) return;
        setSelectedObjective(objective);
    }

    const handleActivityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        setSelectedActivityId(value);
        const activity = activities.find((activity) => activity.id === value);
        if (!activity) return;
        setSelectedActivity(activity);
    }

    const [activityName, setActivityName] = useState("");
    const [activityDeadeLine, setActivityDeadeLine] = useState("");

    const [loadingAct, setLoadingAct] = useState(false);
    const createActivity = (e:  React.FormEvent<HTMLFormElement>, objId: number) => {
        e.preventDefault();
        if (activityName.length <= 0) return;
        if (activityDeadeLine.length <= 0) return;

        setLoadingAct(true);
        const ff = createActivityDB({
            name: activityName,
            dead_line: new Date(activityDeadeLine),
            employee_id: employeeI?.user_id,
            is_complete: false,
            objective_id: objId,
        }).then(res => {
            if (res) {
                // console.log(res)
                addActivity(res.id, res.name, res.objective_id, res.is_complete, res.dead_line, res.created_at);
            }
        });

        setToggleActivity(false);
        setActivityDeadeLine("");
        setActivityName("");
        setLoadingAct(false);
    };

    const addObjective = (objectiveId: number, objectiveName: string, is_complete: boolean, dead_line: string, created_at: string) => {
        let objective = workPlan.find((objective) => objective.objectiveId === objectiveId);
        if (objective) return;
        setWorkPlan((prevState) => {
            return [...prevState, {objectiveName, objectiveId, activities:[], is_complete: is_complete, dead_line: dead_line, created_at: created_at}];
        })
    }
    const removeObjective = (objectiveId: number) => {
        const ffg = window.confirm("Do you confirm deleting this objective permanently?")
        if (!ffg) return;
        const objectives = [...workPlan];
        const objective = objectives.find((objective) => objective.objectiveId === objectiveId);
        if (!objective) return;
        objective.activities.forEach((activity) => {
            activity.tasks.forEach(async (task) => {
                if (task.taskId) {
                    await deleteTask(task.taskId)
                }
            })
        })

        let j = objectives.findIndex((objective) => objective.objectiveId === objectiveId);
        objectives.splice(j, 1);
        setDeleteAction(false)
        setWorkPlan(objectives);
    }

    const addActivity = (activityId: number, activityName: string, objectiveId: number, is_complete: boolean, dead_line: string, created_at: string) => {
        let objectives = [...workPlan];
        let activity = objectives.find((objective) => objective.activities.find(a => a.activityId === activityId));
        if (activity) {
            return;
        }
        let objective = objectives.find((objective) => objectiveId === objective.objectiveId);
        if (!objective) return;
        objective.activities = [...objective.activities, {activityId: activityId, activityName: activityName, tasks: [], objectiveId, is_complete, dead_line, created_at}];
        let j = objectives.findIndex(v => v.objectiveId === objectiveId);
        objectives.splice(j, 1);
        objectives.splice(j, 0, objective);
        setWorkPlan(objectives);
    }

    const [removeActivityLoading, setRemoveActivityLoading] = useState(false);
    const removeActivity = (activityId: number, objectiveId: number) => {
        const ffg = window.confirm("Do you confirm deleting this activity permanently?")
        if (!ffg) return;
        // if (deleteAction) return;
        if (removeActivityLoading) return;
        setRemoveActivityLoading(true);
        const objectives = [...workPlan];
        let activity = objectives.find((objective) => objective.activities.find(a => a.activityId === activityId));
        if (!activity) {
            return;
        }

        let objective = objectives.find((objective) => objectiveId === objective.objectiveId);
        if (!objective) return;
        let activities = [...objective.activities];
        let jA = activities.findIndex(a => a.activityId === activityId);
        let jO = objectives.findIndex(v => v.objectiveId === objectiveId);
        activities[jA].tasks.forEach(async (task) => {
            if (task.taskId) {
                await deleteTask(task.taskId);
            }
        })
        activities.splice(jA, 1);
        objective.activities = activities;
        objectives.splice(jO, 1);
        objectives.splice(jO, 0, objective);
        setWorkPlan(objectives);
        setDeleteAction(false)
        setRemoveActivityLoading(false);
    }


    const submitEditedWorkPlan = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (loading) return;
        if (!employeeI) return;
        if (!workPlanI) return;
        setLoading(true);

        const res = await editWorkPlan(workPlanI.id, {
            month: workPlanI.month,
            year: workPlanI.year,
            type: "sub_department",
            unit_head_id: employeeI.supervisor,
            manager_id: employeeI.sSupervisor,
            department_id: employeeI.department,
            division_id: employeeI.division,
            sub_department_id: employeeI.sub_department,
            unit_id: employeeI.unit,
        });
        if (!res) {
            setLoading(false);
            return;
        }

        setLoading(false)
        setWorkPlan([])
        setKind("sub_department")
        setButtonType(ButtonType.WorkPlan)
    }

    const [addTaskLoading, setAddTaskLoading] = useState(false);
    const addTask = async (taskName: string, unit: string, resource: string, start: string, objectiveId: number, activityId: number, cumulative: number, achieved: number) => {
        console.log("clicked")
        if (addTaskLoading) return;
        setAddTaskLoading(true);
        let objectives = [...workPlan];
        let objective = objectives.find((objective) => objectiveId === objective.objectiveId);
        if (!objective) return;
        if (!workPlanI) return;
        const taskId = await createTask({
            name: taskName,
            work_plan_id: workPlanI.id,
            achieved_percentage: achieved,
            activity_id: activityId,
            cumulative_percentage: cumulative,
            dead_line: new Date(start),
            is_added_task: true,
            objective_id: objectiveId,
            resource: resource,
            remarks: "",
            status: "",
            unit_of_measure: unit

        })

        if (!taskId) return;
        let activities = [...objective.activities];
        let activity = activities.find((activity) => activity.activityId === activityId);
        if (!activity) return;
        let jO = objectives.findIndex(v => v.objectiveId === objectiveId);
        let jA = activities.findIndex((activity) => activity.activityId === activityId);
        activity.tasks = [...activity.tasks, {taskId: taskId,taskName: taskName.trim(), unit: unit.trim(), resource: resource.trim(), start: start.trim(), cumulative, achieved, hasBeenAdded: true, evidence: [], onReport: true}];
        activities.splice(jA, 1);
        activities.splice(jA,0,activity);
        objectives.splice(jO, 1);
        objectives.splice(jO, 0, objective);
        setWorkPlan(objectives);
        setAddTaskLoading(false);
    }

    const [deleteTaskLoading, setDeleteTaskLoading] = useState(false);

    const removeTask = async (taskID:number|undefined, taskIndex: number, activityId: number, objectiveId: number) => {
        const ffg = window.confirm("Do you confirm deleting this task permanently?")
        if (!ffg) return;
        if (!taskID) return;
        // if (deleteAction) return;
        if (deleteTaskLoading) return;
        setDeleteTaskLoading(true);
        const status = await deleteTask(taskID);
        // console.log(status);
        if (!status || status !== 200) {
            setDeleteTaskLoading(false);
            return
        };
        let objectives = [...workPlan];
        let activity = objectives.find((objective) => objective.activities.find(a => a.activityId === activityId));
        if (!activity) {
            return;
        }
        let objective = objectives.find((objective) => objectiveId === objective.objectiveId);
        if (!objective) return;
        let activities = [...objective.activities];
        let act = activities.find((activity) => activity.activityId === activityId);
        if (!act) return;
        let tasks = [...act.tasks];
        let jA = activities.findIndex(a => a.activityId === activityId);
        let jO = objectives.findIndex(v => v.objectiveId === objectiveId);
        tasks.splice(taskIndex,1);
        act.tasks = tasks;
        activities.splice(jA,0,act);
        objectives.splice(jO, 1);
        objectives.splice(jO, 0, objective);
        setWorkPlan(objectives);
        setDeleteAction(false);
        setDeleteTaskLoading(false);

    }

    const handleChangeTarget = (e: React.ChangeEvent<HTMLInputElement> ,taskIndex: number, activityId: number, objectiveId: number) => {
        let objectives = [...workPlan];
        let activity = objectives.find((objective) => objective.activities.find(a => a.activityId === activityId));
        if (!activity) {
            return;
        }
        let objective = objectives.find((objective) => objectiveId === objective.objectiveId);
        if (!objective) return;
        let activities = [...objective.activities];
        let act = activities.find((activity) => activity.activityId === activityId);
        if (!act) return;
        let tasks = [...act.tasks];
        let jA = activities.findIndex(a => a.activityId === activityId);
        let jO = objectives.findIndex(v => v.objectiveId === objectiveId);
        let task = tasks[taskIndex]
        task.taskName = e.target.value;
        tasks.splice(taskIndex,1);
        tasks.splice(taskIndex,0,task)
        act.tasks = tasks;
        activities.splice(jA,0,act);
        objectives.splice(jO, 1);
        objectives.splice(jO, 0, objective);
        setWorkPlan(objectives);

    }

    const handleChangeUnit = (e: React.ChangeEvent<HTMLInputElement> ,taskIndex: number, activityId: number, objectiveId: number) => {
        let objectives = [...workPlan];
        let activity = objectives.find((objective) => objective.activities.find(a => a.activityId === activityId));
        if (!activity) {
            return;
        }
        let objective = objectives.find((objective) => objectiveId === objective.objectiveId);
        if (!objective) return;
        let activities = [...objective.activities];
        let act = activities.find((activity) => activity.activityId === activityId);
        if (!act) return;
        let tasks = [...act.tasks];
        let jA = activities.findIndex(a => a.activityId === activityId);
        let jO = objectives.findIndex(v => v.objectiveId === objectiveId);
        let task = tasks[taskIndex]
        task.unit = e.target.value;
        tasks.splice(taskIndex,1);
        tasks.splice(taskIndex,0,task)
        act.tasks = tasks;
        activities.splice(jA,0,act);
        objectives.splice(jO, 1);
        objectives.splice(jO, 0, objective);
        setWorkPlan(objectives);

    }

    const handleChangeResource = (e: React.ChangeEvent<HTMLInputElement> ,taskIndex: number, activityId: number, objectiveId: number) => {
        let objectives = [...workPlan];
        let activity = objectives.find((objective) => objective.activities.find(a => a.activityId === activityId));
        if (!activity) {
            return;
        }
        let objective = objectives.find((objective) => objectiveId === objective.objectiveId);
        if (!objective) return;
        let activities = [...objective.activities];
        let act = activities.find((activity) => activity.activityId === activityId);
        if (!act) return;
        let tasks = [...act.tasks];
        let jA = activities.findIndex(a => a.activityId === activityId);
        let jO = objectives.findIndex(v => v.objectiveId === objectiveId);
        let task = tasks[taskIndex]
        task.resource = e.target.value;
        tasks.splice(taskIndex,1);
        tasks.splice(taskIndex,0,task)
        act.tasks = tasks;
        activities.splice(jA,0,act);
        objectives.splice(jO, 1);
        objectives.splice(jO, 0, objective);
        setWorkPlan(objectives);

    }

    const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement> ,taskIndex: number, activityId: number, objectiveId: number) => {
        let objectives = [...workPlan];
        let activity = objectives.find((objective) => objective.activities.find(a => a.activityId === activityId));
        if (!activity) {
            return;
        }
        let objective = objectives.find((objective) => objectiveId === objective.objectiveId);
        if (!objective) return;
        let activities = [...objective.activities];
        let act = activities.find((activity) => activity.activityId === activityId);
        if (!act) return;
        let tasks = [...act.tasks];
        let jA = activities.findIndex(a => a.activityId === activityId);
        let jO = objectives.findIndex(v => v.objectiveId === objectiveId);
        let task = tasks[taskIndex]
        task.start = e.target.value;
        tasks.splice(taskIndex,1);
        tasks.splice(taskIndex,0,task)
        act.tasks = tasks;
        activities.splice(jA,0,act);
        objectives.splice(jO, 1);
        objectives.splice(jO, 0, objective);
        setWorkPlan(objectives);

    }


    return (
        <div className={`w-full h-full flex flex-col gap-4 px-4 pt-20 pb-32 relative`}>
            {/*{*/}
            {/*    deleteAlert && (*/}
            {/*        <div className={`fixed w-full h-fit flex flex-col justify-center items-center z-20 top-20 right-4`}>*/}
            {/*            <DangerAlerts msg={msg} setDeleteAction={setDeleteAction} setAlert={setDeleteAlert} />*/}
            {/*        </div>*/}
            {/*    )*/}
            {/*}*/}
            {
                toggleTask && (
                    <div className={`absolute w-full h-fit flex flex-col justify-center items-center z-20 top-20`}>
                        <div
                            className={`w-1/2 h-fit  flex flex-col gap-4 px-4 pt-10 pb-10 mb-10 bg-white shadow-md shadow-stone-600/60 rounded-lg`}>
                            <form onSubmit={handleAddTask}>

                                <div className="space-y-8">
                                    <div className="border-b border-gray-900/10 pb-8">
                                        <h2 className="text-base/7 font-semibold text-gray-900">Task</h2>
                                        <p className="mt-1 text-sm/6 text-gray-600">
                                            This information gives a clear summary about the task you going to work on.
                                        </p>

                                        <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                            <div className="col-span-full">
                                                <label htmlFor="target"
                                                       className="block text-sm/6 font-medium text-gray-900">
                                                    Target
                                                </label>
                                                <div className="mt-2 flex flex-col gap-0.5">
                                                    {targetError.length > 0 && (
                                                        <small
                                                            className={`text-red-500 font-semibold`}>{targetError}</small>
                                                    )}
                                                    <textarea
                                                        id="target"
                                                        name="target"
                                                        rows={3}
                                                        value={target}
                                                        required={true}
                                                        onChange={(e) => {
                                                            setTarget(e.target.value);
                                                            if (e.target.value === "") {
                                                                setTargetError("Enter target value");
                                                                return;
                                                            }
                                                            setTargetError("");
                                                        }}
                                                        className={`block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="border-b border-gray-900/10 pb-12">
                                        <p className="mt-1 text-sm/6 text-gray-600">In case the task builds on a previous
                                            task.</p>
                                        <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                            <div className="sm:col-span-3">
                                                <label htmlFor="Cumulative"
                                                       className="block text-sm/6 font-medium text-gray-900">
                                                    Cumulative Score (%)
                                                </label>
                                                <div className="mt-2 flex flex-col gap-0.5">
                                                    {cumulativeError.length > 0 && (
                                                        <small
                                                            className={`text-red-500 font-semibold`}>{cumulativeError}</small>
                                                    )}
                                                    <input
                                                        id="cumulative"
                                                        name="cumulative"
                                                        value={cumulative}
                                                        onChange={(e) => {
                                                            setCumulative(e.target.value)
                                                        }}
                                                        type="text"
                                                        className="block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6"
                                                    />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-3">
                                                <label htmlFor="archieved"
                                                       className="block text-sm/6 font-medium text-gray-900">
                                                    Achieved Score (%)
                                                </label>
                                                <div className="mt-2 flex flex-col gap-0.5">
                                                    {achieved.length > 0 && (
                                                        <small
                                                            className={`text-red-500 font-semibold`}>{achievedError}</small>
                                                    )}
                                                    <input
                                                        id="archieved"
                                                        name="archieved"
                                                        value={achieved}
                                                        onChange={(e) => {
                                                            setAchieved(e.target.value)
                                                        }}
                                                        type="text"
                                                        className="block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6"
                                                    />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-4">
                                                <label htmlFor="unit"
                                                       className="block text-sm/6 font-medium text-gray-900">
                                                    Unit of Measure
                                                </label>
                                                <div className="mt-2 flex flex-col gap-0.5">
                                                    {unitError.length > 0 && (
                                                        <small
                                                            className={`text-red-500 font-semibold`}>{unitError}</small>
                                                    )}
                                                    <input
                                                        id="unit"
                                                        name="unit"
                                                        value={unit}
                                                        onChange={(e) => setUnit(e.target.value)}
                                                        type="text"
                                                        required={true}
                                                        className="block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6"
                                                    />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-4">
                                                <label htmlFor="resources"
                                                       className="block text-sm/6 font-medium text-gray-900">
                                                    Resources
                                                </label>
                                                <p className="mt-1 text-sm/6 text-gray-600">Separate your resources with
                                                    commas e.g (CEO,PDN,PD,DFA).</p>
                                                <div className="mt-2 flex flex-col gap-0.5">
                                                    {resourceError.length > 0 && (
                                                        <small
                                                            className={`text-red-500 font-semibold`}>{resourceError}</small>
                                                    )}
                                                    <input
                                                        id="resources"
                                                        name="resources"
                                                        value={resource}
                                                        onChange={(e) => setResource(e.target.value)}
                                                        type="text"
                                                        required={true}
                                                        className="block outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 px-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6"
                                                    />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-4 sm:col-start-1">
                                                <label htmlFor="deadLine"
                                                       className="block text-sm/6 font-medium text-gray-900">
                                                    Dead Line
                                                </label>
                                                <div className="mt-2 flex flex-col gap-0.5">
                                                    {startError.length > 0 && (
                                                        <small
                                                            className={`text-red-500 font-semibold`}>{startError}</small>
                                                    )}
                                                    <input
                                                        id="deadLine"
                                                        name="deadLine"
                                                        value={start}
                                                        onChange={(e) => setStart(e.target.value)}
                                                        type="date"
                                                        // min={new Date().toISOString().split("T")[0]}
                                                        required={true}
                                                        className="block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 flex items-center justify-end gap-x-6">
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setToggledTaskActivity(null);
                                            setToggledTaskObjective(null);
                                            setToggleTask(false);
                                            setTargetError("");
                                            setTarget("");
                                            setCumulative("");
                                            setCumulativeError("");
                                            setAchieved("");
                                            setAchievedError("");
                                            setUnit("");
                                            setUnitError("");
                                            setResource("");
                                            setResourceError("");
                                            setStart("");
                                            setStartError("");
                                        }}
                                        type="button"
                                        className="text-sm/6 font-semibold text-gray-900 border-0 outline-0 focus:outline-0 sm:text-sm">
                                        Cancel
                                    </button>
                                    <button
                                        disabled={addTaskLoading}
                                        type="submit"
                                        className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold border-0 text-white shadow-sm hover:bg-orange-500 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Add Task
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )
            }

            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <div className={`w-fit h-fit flex flex-row gap-4 items-center`}>
                    {
                        !employeeI?.profile_photo || employeeI?.profile_photo?.length <= 0 ? (
                            <>
                                <IoPersonCircle className={`text-stone-400 w-32 h-32`}/>
                            </>
                        ) : (
                            <img className={`w-32 h-32 rounded-full`} src={employeeI?.profile_photo} alt={`${employeeI.fname[0]}${employeeI.mname && employeeI.mname?.length >0 ? `${employeeI.mname[0]}${employeeI.lname[0]}`: employeeI.lname[0] }}`}/>
                        )
                    }
                    <div className={`w-fit h-fit flex flex-col gap-2 text-lg text-[#1c1c1c] font-medium`}>
                        <p>{`${employeeI?.fname} ${employeeI?.lname ? employeeI.lname : ""} ${employeeI?.mname}`}</p>
                        <p>{employeeI?.position}</p>
                        {/*<p>Performance Avg: 4.5</p>*/}
                    </div>
                </div>
                <div className={`w-fit h-fit flex flex-col gap-4 items-center justify-center`}>
                    <p className={`text-sm xl:text-lg font-semibold text-[#1c1c1c]`}>{`${workPlanI?.month.slice(0, 3)} - ${workPlanI?.year} Work Plan`}</p>
                </div>
            </div>

            <div className={`w-full flex flex-col gap-4 justify-between items-start`}>
                {
                    workPlan.length <= 0 ?
                        (<p>Add an Objective</p>) :
                        (
                            workPlan.map((obj) => (
                                <div
                                    key={obj.objectiveId}
                                    className={`w-full h-fit flex flex-col gap-4 justify-center`}>
                                    <div
                                        className={`w-fit h-fit flex flex-row justify-between items-center relative pr-8`}>
                                        <p className={` text-2xl font-semibold text-[#1c1c1c]`}>{obj.objectiveName}</p>
                                        <div onClick={
                                            () => {

                                                removeObjective(obj.objectiveId)
                                            }
                                        }
                                             className={`absolute w-fit h-fit bg-white/60 shadow-sm shadow-500/40 rounded-full p-0.5 right-0 top-0`}>
                                            <MdOutlineCancel
                                                className={`text-[#1c1c1c]/60 w-5 h-5 hover:text-[#1c1c1c]/80`}/>
                                        </div>
                                    </div>
                                    <div className={`w-full h-fit flex flex-col justify-between gap-4`}>
                                        <div
                                            className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>
                                            <div
                                                className={`text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle flex flex-row gap-x-4`}>
                                                <div className={`w-1/4 h-fit`}>Activity</div>
                                                <div
                                                    className={`w-3/4 h-fit justify-items-start text-wrap align-middle grid grid-cols-9 gap-x-4`}>
                                                    <div className={`w-full h-fit col-span-3`}>Target</div>
                                                    <div className={`w-full h-fit col-span-2`}>Unit of Measure</div>
                                                    <div className={`w-full h-fit col-span-2 text-center`}>Resource
                                                    </div>
                                                    <div className={`w-full h-fit col-span-2 text-center`}>Timeline
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                obj.activities.length === 0 ?
                                                    (
                                                        <div className={`w-full flex flex-row items-center`}>
                                                            <div
                                                                onClick={() => {
                                                                    setToggleActivity(true)
                                                                    setToggledActivityObjective(obj.objectiveId)
                                                                }}
                                                                className={`w-fit h-fit`}>
                                                                <FiPlusCircle
                                                                    className={`text-[#999999]/80 w-5 h-5 hover:text-[#999999]`}/>

                                                            </div>
                                                            <div className={`w-full h-0.5 bg-[#999999] py-[1px]`}></div>
                                                        </div>
                                                    ) :
                                                    (
                                                        <div className={`w-full flex flex-row items-center`}>
                                                            <div
                                                                className={`w-full h-0.5 bg-[#999999] py-[1px]`}></div>
                                                        </div>
                                                    )
                                            }

                                            {
                                                obj.activities.length >= 0 && (
                                                    obj.activities.map((activity, i) => (
                                                        <div key={i}
                                                             className={`w-full h-fit flex flex-col gap-4 justify-center relative`}>
                                                            {
                                                                activity.tasks.length > 0 && (
                                                                    <div onClick={() => {
                                                                        setToggleTask(true)
                                                                        // console.log("Clicked")
                                                                        setToggledTaskObjective(obj.objectiveId)
                                                                        setToggledTaskActivity(activity.activityId)
                                                                    }}
                                                                         className={`absolute w-fit h-full right-0 flex flex-col justify-center z-20`}>
                                                                        <BsClipboard2Plus
                                                                            className={`w-5 h-5 text-[#1c1c1c]/60 hover:text-[#1c1c1c]/80`}/>
                                                                    </div>
                                                                )
                                                            }

                                                            <div
                                                                className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle flex flex-row gap-x-6`}>
                                                                <div
                                                                    className={`w-1/4 h-fit flex flex-row gap-1 items-center`}>
                                                                    <div onClick={() => {
                                                                        removeActivity(activity.activityId, obj.objectiveId)
                                                                    }}
                                                                         className={`w-fit h-fit self-start`}>
                                                                        <CiSquareMinus
                                                                            className={`w-5 h-5 text-[#1c1c1c]/60 hover:text-[#1c1c1c]/80`}/>
                                                                    </div>
                                                                    <p>{activity.activityName}</p>
                                                                </div>
                                                                {
                                                                    activity.tasks.length <= 0 ?
                                                                        (
                                                                            <div
                                                                                className={`w-3/4 h-fit flex flex-row items-center justify-center`}>
                                                                                <div onClick={() => {
                                                                                    setToggleTask(true)
                                                                                    setToggledTaskObjective(obj.objectiveId)
                                                                                    setToggledTaskActivity(activity.activityId)
                                                                                }}
                                                                                     className={`w-fit h-fit`}>
                                                                                    <p className={`w-fit h-fit rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 cursor-pointer`}>Add
                                                                                        Target</p>
                                                                                </div>
                                                                            </div>
                                                                        ) :
                                                                        (
                                                                            <div
                                                                                className={`w-3/4 h-fit flex flex-col gap-4`}>
                                                                                {
                                                                                    activity.tasks.map((task, i) => (

                                                                                        <div key={i}
                                                                                             className={`w-full h-fit justify-items-start text-wrap align-middle grid grid-cols-9 gap-x-4`}>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-3 flex flex-row gap-1 items-center`}>
                                                                                                <div onClick={() => {
                                                                                                    removeTask(task.taskId, i, activity.activityId, obj.objectiveId).then()
                                                                                                }}
                                                                                                     className={`w-fit h-fit self-start`}>
                                                                                                    <CiSquareMinus
                                                                                                        className={`w-5 h-5 text-[#1c1c1c]/60 hover:text-[#1c1c1c]/80`}/>
                                                                                                </div>
                                                                                                {task.taskName}
                                                                                            </div>

                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                <p className={`w-full text-center`}>{task.unit}</p>
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                <p className={`w-full text-center`}>{task.resource}</p>
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2 relative`}>
                                                                                                <p className={`w-full text-center`}>{new Date(task.start).toLocaleDateString("en-GB")}</p>
                                                                                            </div>
                                                                                        </div>

                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        )
                                                                }
                                                            </div>
                                                            {
                                                                obj.activities.length - 1 === i ?
                                                                    (
                                                                        <div
                                                                            className={`w-full flex flex-row items-center`}>
                                                                            <div
                                                                                onClick={() => {
                                                                                    setToggleActivity(true)
                                                                                    setToggledActivityObjective(obj.objectiveId)
                                                                                }}
                                                                                className={`w-fit h-fit`}>
                                                                                <FiPlusCircle
                                                                                    className={`text-[#999999]/80 w-5 h-5 hover:text-[#999999]`}/>

                                                                            </div>
                                                                            <div
                                                                                className={`w-full h-0.5 bg-[#999999] py-[1px]`}></div>
                                                                        </div>
                                                                    ) :
                                                                    (
                                                                        <div
                                                                            className={`w-full flex flex-row items-center`}>
                                                                            <div
                                                                                className={`w-full h-0.5 bg-[#999999] py-[1px]`}></div>
                                                                        </div>
                                                                    )
                                                            }
                                                        </div>
                                                    ))
                                                )
                                            }

                                            {
                                                (toggleActivity && toggledActivityObjective === obj.objectiveId) && (
                                                    <div className={`w-fit h-fit flex flex-row items-center gap-8`}>
                                                        <div className={`w-fit h-fit flex flex-col gap-4 justify-center`}>
                                                            <select
                                                                onChange={handleActivityChange}
                                                                className={`w-fit max-w-72 xl:max-w-96 ring-1 ring-gray-500 rounded-2xl px-2 py-1.5 outline-0 focus:outline-0 bg-transparent border-0 text-sm xl:text-lg font-semibold text-[#1c1c1c]`}>
                                                                <option>Company Activity</option>
                                                                {
                                                                    activities.filter(act => act.objective_id === obj.objectiveId).map((activity) => (
                                                                        <option value={activity.id}
                                                                                key={activity.id}>{activity.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            <button onClick={() => {
                                                                if (!selectedActivity) return;
                                                                addActivity(selectedActivity.id, selectedActivity.name, obj.objectiveId, selectedActivity.is_complete, selectedActivity.dead_line, selectedActivity.dead_line);
                                                                setSelectedActivityId(undefined);
                                                                setSelectedActivity(undefined);
                                                                setToggleActivity(false);
                                                            }}
                                                                    className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>Add
                                                            </button>

                                                        </div>
                                                        <p className={`text-2xl font-bold text-gray-500`}>OR</p>
                                                        <div className={`w-fit h-fit`}>
                                                            <form onSubmit={(e) => {
                                                                createActivity(e, obj.objectiveId)
                                                            }}
                                                                  className={`w-60 h-fit p-4 ring-1 ring-stone-500 rounded-xl flex flex-col gap-4`}>
                                                                <p className={`text-sm/6 text-gray-600 text-wrap`}>Add
                                                                    your own Activity fitting of this Objective with the
                                                                    guidance of your supervisor.</p>
                                                                <div
                                                                    className="grid grid-cols-1 gap-y-4 sm:grid-cols-6">
                                                                    <div className="col-span-full">
                                                                        <label htmlFor="activityNamae"
                                                                               className="block text-sm/6 font-medium text-gray-900">
                                                                            Activity
                                                                        </label>
                                                                        <div className="mt-1 flex flex-col gap-0.5">
                                                                                        <textarea
                                                                                            id="activityNamae"
                                                                                            name="activityNamae"
                                                                                            rows={2}
                                                                                            value={activityName}
                                                                                            required={true}
                                                                                            onChange={(e) => {
                                                                                                setActivityName(e.target.value);
                                                                                            }}
                                                                                            className={`block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6`}
                                                                                        />
                                                                        </div>
                                                                    </div>
                                                                    <div className="sm:col-span-4 sm:col-start-1">
                                                                        <label htmlFor="deadLineActivity"
                                                                               className="block text-sm/6 font-medium text-gray-900">
                                                                            Dead Line
                                                                        </label>
                                                                        <div className="mt-1 flex flex-col gap-0.5">
                                                                            <input
                                                                                id="deadLineActivity"
                                                                                name="deadLineActivity"
                                                                                value={activityDeadeLine}
                                                                                onChange={(e) => setActivityDeadeLine(e.target.value)}
                                                                                type="date"
                                                                                min={new Date().toISOString().split("T")[0]}
                                                                                required={true}
                                                                                className="block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <button
                                                                    disabled={loadingAct}
                                                                    type="submit"
                                                                    className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold border-0 text-white shadow-sm hover:bg-orange-500 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                                >
                                                                    Add Activity
                                                                </button>

                                                            </form>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>
                            ))
                        )
                }

                {
                    !toggleObjective && (
                        <div
                            onClick={() => setToggleObjective(true)}
                            className={`w-fit h-fit flex flex-row gap-4 py-1.5 px-3 hover:shadow hover:bg-white/50 shadow-stone-500/60 cursor-pointer items-center justify-center`}>
                            <p className={`text-base 2xl:text-lg font-semibold text-[#1c1c1c]`}>Add Objective</p>
                            <FiPlusCircle className={`w-5 h-5 font-semibold text-[#1c1c1c]`}/>
                        </div>

                    )
                }
                {
                    toggleObjective && (
                        <div className={`w-fit h-fit flex flex-col gap-4 justify-center`}>
                            <select
                                onChange={handleObjectiveChange}
                                className={`w-fit px-2 py-1.5 outline-0 focus:outline-0 bg-transparent border-0 text-sm xl:text-lg font-semibold text-[#1c1c1c]`}>
                                <option>Company Objectives</option>
                                {
                                    objectives.map((objective) => (
                                        <option value={objective.id} key={objective.id}>{objective.name}</option>
                                    ))
                                }
                            </select>
                            <button onClick={() => {
                                if (!selectedObjective) return;
                                addObjective(selectedObjective.id, selectedObjective.name, selectedObjective.is_complete, selectedObjective.dead_line, selectedObjective.created_at);
                                setSelectedObjectiveId(undefined);
                                setSelectedObjective(undefined);
                                setToggleObjective(false);
                            }}
                                    className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>Add
                            </button>
                        </div>
                    )
                }

            </div>
            {
                workPlan.length > 0 && (

                    <div className={`w-full h-fit flex flex-row gap-4 justify-end items-center`}>
                        {/*<button*/}
                        {/*    disabled={loading}*/}
                        {/*    onClick={(e) => {*/}
                        {/*        e.preventDefault()*/}
                        {/*        setWorkPlan([])*/}
                        {/*        setKind("sub_department")*/}
                        {/*        setButtonType(ButtonType.WorkPlan)*/}

                        {/*    }}*/}
                        {/*    className={`flex w-fit  justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-orange-500 shadow-sm hover:bg-orange-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>{loading ? "Submitting..." : "Cancel"}*/}
                        {/*</button>*/}
                        <button
                            disabled={loading}
                            onClick={(e) => submitEditedWorkPlan(e)}
                            className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>{loading ? "Submitting..." : "Submit Edits"}
                        </button>
                    </div>
                )
            }

        </div>
    );
};

export default SubDepartmentWorkPlanEdit;