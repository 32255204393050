import axios from "axios";
import {API_URL, X_API_KEY} from "../utils/api";
import {ActivityData} from "./activityService";
import {ObjectiveData} from "./objectiveService";
import {TaskScoreData} from "./taskScoreService";

export type TaskData = {
    id: number;
    name: string;
    unit_of_measure: string;
    resource: string;
    remarks: string;
    status: string;
    activity_id: number;
    objective_id: number;
    work_plan_id: number;
    on_report: boolean;
    cumulative_percentage?: number;
    achieved_percentage?: number;
    is_added_task: boolean;
    dead_line: string;
    created_at: string;
    Activity: ActivityData;
    Objective: ObjectiveData;
    TaskScore: TaskScoreData;
};

export type TaskInput = {
    name: string;
    dead_line: Date;
    is_added_task: boolean;
    unit_of_measure: string;
    cumulative_percentage?: number;
    achieved_percentage?: number;
    remarks: string;
    status: string;
    resource: string;
    activity_id: number;
    objective_id: number;
    work_plan_id?: number;
}

export type TaskReportEditInputs = {
    id: number;
    cumulative_percentage: number;
    achieved_percentage: number;
    remarks: string;
    status: string;
}

export const createTask = async (task: TaskInput) => {
    try {
        return await axios
            .post<number>(API_URL + `task`, task, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {
        console.log(err)
    }
}

export const getTasks = async () => {
    try {
        return await axios
            .get<TaskData[]>(API_URL + "task", {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};


export const getTask = async (param: number) => {
    try {
        return await axios
            .get<TaskData>(API_URL + `task/${param}`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {
        console.log(err)
    }
};


export const deleteTask = async (param: number) => {
    try {
        return await axios
            .delete(API_URL + `task/${param}`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.status);
    } catch (err) {
        console.log(err)
    }
};

export const removeTaskFromReport = async (param: number) => {
    try {
        return await axios
            .patch(API_URL + `task/${param}`, {}, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.status);
    } catch (err) {
        console.log(err);
    }
};


export const updateTaskStatus = async (param: number, status:string) => {
    try {
        return await axios
            .put(API_URL + `task/${param}/status`, status, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.status);
    } catch (err) {
        console.log(err);
    }
};

export const updateTaskRemarks = async (param: number, remarks:string) => {
    try {
        return await axios
            .put(API_URL + `task/${param}/remarks`, {remarks: remarks}, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.status);
    } catch (err) {
        console.log(err);
    }
};

export const updateTaskBasingReportGen = async (param: number, input: TaskReportEditInputs) => {
    try {
        return await axios
            .put(API_URL + `task/${param}/report`, input, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.status);
    } catch (err) {
        console.log(err);
    }
};