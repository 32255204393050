import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "../context/authContext";
import {loginService, signUpService, uploadSignature} from "../services/employee";
import {Department, getDepartments} from "../services/departmentService";
import {Division, getDivisions} from "../services/divisionService";
import {getUnits, Unit} from "../services/unitService";
import {Position, positions, ranks} from "../utils/position";
import {MdCloudDone} from "react-icons/md";
import {getSubDepartments, SubDepartment} from "../services/subDepartment";

const SignUp = () => {
    const [email, setEmail] = useState<string>("");
    const [fname, setFname] = useState<string>("");
    const [mname, setMname] = useState<string>("");
    const [lname, setLname] = useState<string>("");
    const [position, setPosition] = useState<string>("");
    const [department, setDepartment] = useState<number | undefined>(undefined);
    const [subDepartment, setSubDepartment] = useState<number | undefined>(undefined);
    const [division, setDivision] = useState<number | undefined>(undefined);
    const [unit, setUnit] = useState<number | undefined>(undefined);
    const [rank, setRank] = useState<string>("");
    const [role, setRole] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [departments, setDepartments] = useState<Department[]>([]);
    const [subDepartments, setSubDepartments] = useState<SubDepartment[]>([]);
    const [subDepartmentsQF, setSubDepartmentsQF] = useState<SubDepartment[]>([]);
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [divisionsQF, setDivisionsQF] = useState<Division[]>(divisions);
    const [units, setUnits] = useState<Unit[]>([]);
    const [unitsQF, setUnitsQF] = useState<Unit[]>(units);

    const [positionsL, setPositions] = useState<Position[]>([]);
    const [positionsQF, setPositionsQF] = useState<Position[]>(positionsL);


    const { employeeI} = useAuth()
    const navigate = useNavigate();

    useEffect(() => {
        if (employeeI) navigate("/dashboard", { replace: true });
        fetchData().then()
    }, []);

    const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        // if (!file) {
        //     setError("Upload a valid image of your Signature");
        //     setLoading(false);
        //     return;
        // }
        if (fname.length <= 0) {
            setError("Please enter a first Name");
            setLoading(false);
            return;
        }
        if (email.length <= 0) {
            setError("Please enter a valid email");
            setLoading(false);
            return;
        }
        if (lname.length <= 0) {
            setError("Please enter a Last Name");
            setLoading(false);
            return;
        }
        if (position.length <= 0) {
            setError("Please select a valid position");
            setLoading(false);
            return;
        }
        if (rank.length <= 0) {
            setError("Please select a valid rank");
            setLoading(false);
            return;
        }
        if (password.length <= 0) {
            setError("Please enter a password");
            setLoading(false);
            return;
        }
        if (!department) {
            setError("Please select a valid department");
            setLoading(false);
            return;
        }

        // const formData = new FormData();
        // const now = new Date();
        // formData.append("image", file, `${fname.trim().toLowerCase()}${lname.length <= 0 ? "": `_${lname}`}_${now.getTime()}.${file.name.trim().split(".")[file.name.trim().split(".").length - 1]}`);
        // const signature = await uploadSignature(formData);
        // if (!signature) {
        //     setError("Something wrong happened when uploading signature.");
        //     setLoading(false);
        //     return;
        // }
        // console.log(signature);

        await signUpService({
            email: email,
            fname: fname,
            mname: mname,
            lname: lname,
            department: department,
            sub_department: subDepartment,
            division: division,
            unit: unit,
            password: password,
            position: position,
            rank: rank,
            // signature: signature,
        }).then((res) => {
            if (res.status === 201) {
                navigate("/login", { replace: true });
            }
        }).catch((err) => {
            setError(err.response.data.error);
            console.error(err);
        })

        // await signUpService({
        //     email: email,
        //     fname,
        //     mname,
        //     lname,
        //     password: password,
        // }).then((res) => {
        //     if (res) {
        //
        //         navigate("/login", { replace: true });
        //     }
        // }).catch((err) => {
        //     setError(err.error);
        //     console.log(err)
        // })

        setLoading(false);

    }

    const handleDepartmentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault()
        const value = parseInt(e.currentTarget.value);
        if (isNaN(value)) {
            setDepartment(undefined);
            setDivisionsQF([]);
            setSubDepartmentsQF([])
            setUnitsQF([]);
            return;
        }
        setDepartment(value);
        setSubDepartmentsQF(subDepartments.filter(subDepartment => subDepartment.department_id === value));
        setDivisionsQF(divisions.filter(division => division.department_id === value));
    }


    const handleSubDepartmentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault()
        const value = parseInt(e.currentTarget.value);
        if (isNaN(value)) {
            setSubDepartment(undefined);
            setDivisionsQF([]);
            setUnitsQF([]);
            return;
        }
        setSubDepartment(value);
        setDivisionsQF(divisions.filter(division => division.sub_department_id === value));
    }

    const handleDivisionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault()
        const value = parseInt(e.currentTarget.value);
        if (isNaN(value)) {
            setDivision(undefined);
            setUnitsQF([]);
            return;
        }
        setDivision(value);
        setUnitsQF(units.filter(unit => unit.division_id === value));
    }

    const handleUnitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault()
        const value = parseInt(e.currentTarget.value);
        if (isNaN(value)) {
            setUnit(undefined);
            return;
        }
        setUnit(value);
    }

    const handleChangeRank = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault()
        const value = e.currentTarget.value;
        if (value === "rank") {
            setRank("");
            return;
        }
        setRank(value);
        setPositionsQF(positionsL.filter(pos => pos.rank === value));
    }

    const handleChangePosition = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault()
        const value = e.currentTarget.value;
        if (value === "position") {
            setPosition("");
            return;
        }
        setPosition(value);
    }


    const fetchData = async () => {
        await getDepartments().then(res => {
            if (res) {
                setDepartments(res)
            }
        });
        await getDivisions().then(res => {
            if (res) {
                setDivisions(res)
                setDivisionsQF(res)
            }
        });

        await getUnits().then(res => {
            if (res) {
                setUnits(res)
                setUnitsQF(res)
                // console.log(res)
            }
        })
        await getSubDepartments().then(res => {
            if (res) {
                setSubDepartments(res)
                setSubDepartmentsQF(res)
            }
        })
        setPositions(positions)
    }

    return (
        <div className={`w-full h-full`}>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mt-20">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        alt="Your Company"
                        src="https://kmcwebsite.nyc3.cdn.digitaloceanspaces.com/new%20logos/Asset%205.webp"
                        className="mx-auto h-10 w-auto"
                    />
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Create an account
                    </h2>

                    {
                        loading && (
                            <p className={`text-sm font-bold text-orange-500`}>Loading...</p>
                        )
                    }
                    {
                        error.length > 0 && (
                            <div className={`w-full h-fit mt-4 bg-red-500/20 rounded-sm`}>

                                <p className={`w-full text-center px-2 py-1.5  ring-1 ring-red-500 text-sm text-red-500 font-semibold rounded-sm`}>{error}</p>
                            </div>
                        )
                    }
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form onSubmit={submitHandler} className="space-y-6">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => setEmail(e.target.value)}
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={email}
                                    required
                                    autoComplete="email"
                                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="fname" className="block text-sm font-medium leading-6 text-gray-900">
                                First Name
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => setFname(e.target.value)}
                                    id="fname"
                                    name="fname"
                                    type="fname"
                                    value={fname}
                                    required
                                    autoComplete="fname"
                                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>


                        <div>
                            <label htmlFor="mname" className="block text-sm font-medium leading-6 text-gray-900">
                                Middle Name
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => setMname(e.target.value)}
                                    id="mname"
                                    name="mname"
                                    value={mname}
                                    type="mname"
                                    autoComplete="mname"
                                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>


                        <div>
                            <label htmlFor="lname" className="block text-sm font-medium leading-6 text-gray-900">
                                Last Name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="lname"
                                    value={lname}
                                    name="lname"
                                    type="lname"
                                    onChange={(e) => setLname(e.target.value)}
                                    required
                                    autoComplete="lname"
                                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="rank" className="block text-sm font-medium leading-6 text-gray-900">
                                Rank
                            </label>
                            <div className="mt-2">
                                <select
                                    id="rank"
                                    name="rank"
                                    onChange={handleChangeRank}
                                    required={true}
                                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                >
                                    <option value="rank">Select Rank</option>
                                    {
                                        ranks.map((rank, i) => (
                                            <option key={i} value={rank}>{rank}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>


                        <div>
                            <label htmlFor="rank" className="block text-sm font-medium leading-6 text-gray-900">
                                Position
                            </label>
                            <div className="mt-2">
                                <select
                                    id="position"
                                    name="position"
                                    onChange={handleChangePosition}
                                    required={true}
                                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                >
                                    <option value="position">Select Position</option>
                                    {
                                        positionsQF.map((positionQF, i) => (
                                            <option key={i} value={positionQF.position}>{positionQF.position}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                        <div>
                            <label htmlFor="department" className="block text-sm font-medium leading-6 text-gray-900">
                                Department
                            </label>
                            <div className="mt-2">
                                <select
                                    id="department"
                                    name="department"
                                    onChange={handleDepartmentChange}
                                    required={true}
                                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                >
                                    <option value="department">Select Department</option>
                                    {
                                        departments.map((department, i) => (
                                            <option key={i} value={department.id}>{department.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                        <div>
                            <label htmlFor="subDepartment" className="block text-sm font-medium leading-6 text-gray-900">
                                Sub Department
                            </label>
                            <div className="mt-2">
                                <select
                                    id="subDepartment"
                                    name="subDepartment"
                                    onChange={handleSubDepartmentChange}
                                    required={true}
                                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                >
                                    <option value="subDepartment">Select Department</option>
                                    {
                                        subDepartmentsQF.map((subDepartment, i) => (
                                            <option key={i} value={subDepartment.id}>{subDepartment.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>


                        <div>
                            <label htmlFor="division" className="block text-sm font-medium leading-6 text-gray-900">
                                Division
                            </label>
                            <div className="mt-2">
                                <select
                                    id="division"
                                    name="division"
                                    onChange={handleDivisionChange}
                                    required={rank !== "CEO" && rank !== "DIRECTOR" && rank !== "SENIOR MANAGER"}
                                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                >
                                    <option value="division">Select Division</option>
                                    {
                                        divisionsQF.map((division, i) => (
                                            <option key={i} value={division.id}>{division.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>


                        <div>
                            <label htmlFor="unit" className="block text-sm font-medium leading-6 text-gray-900">
                                Unit
                            </label>
                            <div className="mt-2">
                                <select
                                    id="unit"
                                    name="unit"
                                    onChange={handleUnitChange}
                                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                >
                                    <option value="unit">Select Unit</option>
                                    {
                                        unitsQF.map((unit, i) => (
                                            <option key={i} value={unit.id}>{unit.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Password
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => setPassword(e.target.value)}
                                    id="password"
                                    name="password"
                                    type="password"
                                    value={password}
                                    required
                                    autoComplete="current-password"
                                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                disabled={loading}
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                            >
                                Sign Up
                            </button>
                        </div>
                    </form>


                    <Link to="/login">
                        <p className="mt-10 text-center text-sm text-gray-500"> Already have an account </p>
                    </Link>

                </div>
            </div>
        </div>
    );
};

export default SignUp;