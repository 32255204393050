import axios from "axios";
import {API_URL, X_API_KEY} from "../utils/api";
import {Division} from "./divisionService";

export type  Employee = {
    user_id: number,
    fname: string,
    mname?: string,
    lname: string,
    email: string,
    position: string,
    rank: string,
    role: string,
    jd: string,
    signature: string,
    tel: string,
    ip_phone: string,
    live_type: string,
    on_live: boolean,
    sSupervisor?: number,
    supervisor?: number,
    profile_photo?: string,
    department: number
    division?: number
    sub_department?: number
    unit?: number
    password: string,
};

export type  EmployeeDB = {
    id: number,
    fname: string,
    mname: {
        "String": string,
        Valid: boolean,
    },
    lname: string,
    email: string,
    position: string,
    profile_photo: {
        "String": string,
        Valid: boolean,
    },
    jd: string,
    tel: string,
    iphone: string,
    sSupervisor: EmployeeDB,
    supervisor: EmployeeDB,
    rank: string,
    role: string,
    live_type: string,
    on_live: boolean,
    signature: string,
    department_id: number
    division_id: {
        Int64: number,
        Valid: boolean,
    },
    supervisor_l_id: {
        Int64: number,
        Valid: boolean,
    }
    s_supervisor_id: {
        Int64: number,
        Valid: boolean,
    }
    sub_department_id: {
        Int64: number,
        Valid: boolean,
    }
    unit_id: {
        Int64: number,
        Valid: boolean,
    }
    password: string,
};

export type AddEmployee = {
    fname: string,
    mname?: string,
    lname: string,
    email: string,
    position: string,
    signature?: string,
    role?: string,
    rank: string,
    department: number
    division?: number
    sub_department?: number
    unit?: number
    password: string,
};

export type EmployeeLogin = {
    email: string,
    password: string,
}


export const loginService = async (cred: EmployeeLogin) => {
    return await axios
            .post(API_URL + "login", {
                email: cred.email.trim(),
                password: cred.password.trim(),
            }, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            });
};

export const forgotPasswordService = async (email: string) => {
    return await axios
            .post(API_URL + "forgotPassword", {
                email: email.trim(),
            }, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            });
};

export const changePasswordService = async (token: string, password: string) => {
    return await axios
            .post(API_URL + `changePassword/${token}`, {
                password: password.trim()
            }, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            });
};


export const signUpService = async (data_info: AddEmployee) => {
    return await axios
            .post(API_URL + "signUp", data_info, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
};


export const LogoutService = async () => {
    return await axios
            .post(API_URL + "logout", {}, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
};

export const getEmployees = async () => {
    try {
        return await axios
            .get<EmployeeDB[]>(API_URL + "employees", {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};

export const getAllUsersBYDepartmentID = async (param: number) => {
    try {
        return await axios
            .get<EmployeeDB[]>(API_URL + `department/${param.toString().trim()}/employees`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};

export const getAllUsersBYUnitID = async (param: number) => {
    try {
        return await axios
            .get<EmployeeDB[]>(API_URL + `unit/${param.toString().trim()}/employees`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};

export const getAllUsersBYSubDepartmentID = async (param: number) => {
    try {
        return await axios
            .get<EmployeeDB[]>(API_URL + `sub_department/${param.toString().trim()}/employees`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};

export const getAllUsersBYDivisionID = async (param: number) => {
    try {
        return await axios
            .get<EmployeeDB[]>(API_URL + `division/${param.toString().trim()}/employees`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};

export const getEmployeesWithSupervisorID = async (param: number) => {
    try {
        return await axios
            .get<EmployeeDB[]>(API_URL + `supervisor/${param.toString().trim()}/employees`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};

export const getEmployee = async (param: number) => {
    try {
        return await axios
            .get<EmployeeDB>(API_URL + "employees/" + param.toString().trim(), {
                headers: {
                    "X-API-KEY": X_API_KEY,
                }
            })
            .then(res => res.data);
    } catch (err) {}
};

export const uploadSignature = async (file: FormData, param: number) => {
    try {
        return await axios
        .post<string>(API_URL + `upload/signature/${param}`, file, {
            headers: {
                "X-API-KEY": X_API_KEY,
                "Content-Type": "multipart/form-data",
            }
        }).then(res => res.data);
    } catch (err) {}
}